import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import firebase from 'firebase/app';

import ACTIONS from '../actions/userActions.js';
import FB from '../utils/firebase.js';
import { clearLocalState } from '../utils/localStorage.js';

class AuthenticateButton extends Component {
	signIn = mode => {
		if( !this.props.accept ) return;

		const callback = user => {
			this.props.dispatch(ACTIONS.userSignIn(user));
			FB.registerOrUpdateUser(user, this.props.version);
			this.props.history.push('/' + this.props.version)
		};

		if( mode === 'github' )
			FB.authUserGitHub(
				callback,
				message => console.log('AuthenticateButton: GITHUB AUTH FAILED', message)
			);
		else if( mode === 'gmail' )
			FB.authUserGoogle(
				callback,
				message => console.log('AuthenticateButton: GOOGLE AUTH FAILED', message)
			);
		//this.props.dispatch(ACTIONS.userSignIn(userId));
	}
	signOut = () => {
		// The sign out process
		// 1. remove form content, for privacy reasons (localStorage)
		// 2. sign out from Firebase Auth
		// 2. dispatch USER_SIGNOUT action
		// 3. redirect to login page
		clearLocalState();
		firebase.auth().signOut().then(() => {
			this.props.dispatch(ACTIONS.userSignOut());
			this.props.history.push('/');  // return to login page
		});
	}
	toggleAdmin = () => this.props.dispatch(ACTIONS.userSetAdminState(!this.props.isAdmin))

	render() {
		const isLoggedIn = this.props.isLoggedIn;
		//const isAdmin = this.props.isAdmin;
		//<button onClick={() => this.props.dispatch(ACTIONS.userAttemptSignIn())}>Email</button>
		const signOutButton = ( <button className="signOut" onClick={this.signOut}><img src={'img/logout.png'} alt="Logga ut" title="Logga ut" /></button> );
		const disable = !this.props.accept ? 'disable' : '';
		const signInButtons = (
			<Fragment>
				<div className={'authbtn ' + disable}
					disabled={!this.props.accept}
					onClick={() => this.signIn('github')}>
					<img src={'img/github.png'} alt="GitHub" />
				</div>
				<div className={'authbtn ' + disable}
					disabled={!this.props.accept}
					onClick={() => this.signIn('gmail')}>
					<img src={'img/gmail.png'} alt="GMail" />
				</div>
			</Fragment>
		);
		//this.props.onlySignOut
		const authButton = isLoggedIn ? signOutButton
			: (this.props.onlySignOut ? null : signInButtons)
		/*const adminButton = isAdmin
			? <button onClick={this.toggleAdmin}>Bli vanlig</button>
			: <button onClick={this.toggleAdmin} disabled={!isLoggedIn}>Bli admin</button> ;*/
		return (
			<div>
				{authButton}
			</div>
		)
	}
}

const mapStateToProps = ({ user, theme }) => ({
	isLoggedIn: user.isLoggedIn,
	isAdmin: user.isAdmin,
	userId: '',
	version: theme.version
});
export default connect(mapStateToProps)(withRouter(AuthenticateButton));
