import React, { Component, Fragment } from 'react';
import {connect } from 'react-redux';

import LessonPlanCard from './LessonPlanCard.js';
import ViewLessonPlan from './../form/ViewLessonPlan.js';
import ACTIONS from '../../actions/formActions.js';

/*const examplePlan = {
	id: 'f847hf498',
	title: 'Uffe undulat flygsimulator',
	subjects: ['Matematik', 'Teknik'],
	classes: ['F', '1', '2', '5'],
	teacherName: 'Greta Garbo',
	updatedWhen: '2018-07-03'
}*/

class LessonPlanView extends Component {
	state = {
		search: ''
	}

	showError = (intro, text) => (
		<Fragment> {intro} <p> {text} </p> </Fragment>
	)
	findMatchingUser = groupMember => {
		// Find the user that matches this group member, based on email address (because ID cannot be trusted)
		for( let key in this.props.allUsers ) {
			let user = this.props.allUsers[key];
			if( user.email === groupMember.email ) {
				// console.log('findMatchingUser: found! ', groupMember, user);
				return user;
			}
		}
		console.log('findMatchingUser: FAIL, no match found for member=', groupMember.email);
		return null;
	}
	filterPlans = plans => {
		const XMASDEBUG = false;
		if(XMASDEBUG) console.log('filterPlans 1', plans);
		// Keep LP produced by user that is in this users group
		if( !plans || !this.props.userEmail ) return [];

		// Look through all group members until we find ourselves. Only lesson plans submitted by the members of that group (identified by email, not userId) will be displayed.
		if(XMASDEBUG) console.log('filterPlans 2', this.props.groups);
		let myGroup = this.props.groups.find(
			g => {
				for( let key in g.members ) {
					let member = g.members[key];
					if( member.email === this.props.userEmail )
						return true;
				}
				return false;
			}
		);
		if(XMASDEBUG) console.log('filterPlans 3', myGroup);
		if( !myGroup ) {
			console.log('LessonPlanView.filterPlans cannot find group, giving up', this.props.userEmail, this.props.groups);
			return [];
		}
		let members = [];
		for( let key in myGroup.members ) {
			let member = myGroup.members[key];
			let match = this.findMatchingUser(member);
			if( !match ) {
				if(XMASDEBUG) console.log('filterPlans 3.5: no matching user found (discrepancy due to deleted user?)');
			} else {
				members.push(match);
				if(XMASDEBUG) console.log('filterPlans 4', key, member.email);
			}
		}
		// console.log('LessonPlanView.filterPlans', this.props.userEmail, myGroup, members);
		// Find all plans that either have the same userId or userName as someone in my group.
		let returns = plans.filter(
			p => members.some(m => {
				// if(XMASDEBUG) console.log(`filterPlans 5: ${m.id}===${p.userId}, ${m.displayName}===${p.userName}`);
				return ((m.id && m.id === p.userId) || (m.displayName && m.displayName === p.userName))
			})
		);
		if(XMASDEBUG) console.log('filterPlans 5', returns);
		return returns
	}

	onChange = e => this.setState({ [e.target.name]: e.target.value.toLowerCase() });

	onSubmit = e => {
		e.preventDefault();
	}

	render() {
		if( this.props.selectedLp ) {
			return <ViewLessonPlan plan={this.props.selectedLp} />
		}

		const intro = (
			<Fragment>
			<h2>Lektionsplaneringar</h2>
			<p>
				Här visas alla lektionsplaneringar som din studiegrupp har producerat.
				<br />
				<button onClick={() => this.props.dispatch(ACTIONS.newLessonPlan())}>Ny lektionsplanering</button>
				{/*<span style="border: 2px solid lightgray; border-width: 2px 0px;">grupp 2017-08</span>.*/}
			</p>
			<div className="form">
				<label htmlFor="search">Sök lektionsplanering</label>
				<input type="text" name="search" id="search" value={this.state.search} onChange={this.onChange} />
			</div>
			</Fragment>
		);

		if( !this.props.hasFetched && this.props.isFetching ) {
			return this.showError(intro, 'Hämtar lektionsplaneringar...');
		} else if( !this.props.hasFetched ) {
			return this.showError(intro, 'Kunde inte hämta lektionsplaneringar.')
		}

		const fplans = this.filterPlans(this.props.plans);
		const queriedResults = this.state.search ? fplans.filter(plan =>
				(`${plan.firstName} ${plan.lastName}`.toLowerCase().includes(this.state.search))
		) : fplans;
		const plans = queriedResults.map( (p, index) => (
			<LessonPlanCard plan={p} key={p.key + index} />
		))
		return (
			<Fragment>
				{intro}
				<div className="lessonplans">
					{plans}
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = ({ lessonPlans, groups, user, navigation }) => ({
	hasFetched: lessonPlans.hasFetched,
	isFetching: lessonPlans.isFetching,
	plans: lessonPlans.plans,
	groups: groups.groups,
	userId: user.userId,
	userEmail: user.email,
	allUsers: user.allUsers,
	selectedLp: navigation.selectedLp
})
export default connect(mapStateToProps)(LessonPlanView);

/* example lesson plan cards
<div className="card">
	<h2> Rubrik </h2>
	Ämne och årskurs <br />
	Lärarnamn <br />
	Uppdateringsdatum <br/>
	<img className="preview" alt="Visa planering" src="img/icon_oga.png" />
	<img className="settings" alt="Ändra planering" src="img/icon_pen.png" />
</div>
<div className="card">
	<h2> Pippi assemblerar </h2>
	Teknik, åk 3 <br />
	David Andersson <br />
	2017-08 <br/>
	<img className="preview" alt="Visa planering" src="img/icon_oga.png" />
	<img className="settings" alt="Ändra planering" src="img/icon_pen.png" />
</div>
<div className="card">
	<h2> Scratch my back </h2>
	Matte, åk 7-9 <br />
	Niklas Mårdby <br />
	2017-09-A <br/>
	<img className="preview" alt="Visa planering" src="img/icon_oga.png" />
</div>
<div className="card">
	<h2> Programmera en robot med högenergilaser </h2>
	Matte, åk 1, 2, 3, 4, 5, 6, 7, 8, 9 <br />
	Måns Hansson <br />
	2017-10 <br/>
	<img className="preview" alt="Visa planering" src="img/icon_oga.png" />
</div>
<div className="card">
	<h2> Skynet for dummies </h2>
	Matte, åk 6, 7, 8 <br />
	Måns Hansson <br />
	2017-09-B <br/>
	<img className="preview" alt="Visa planering" src="img/icon_oga.png" />
</div>
*/
