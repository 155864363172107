import React from 'react';
import { LinkNewWindow, LinkChangeTab, VideoImage } from '../components/Helpers.js';
import Form1 from '../components/form/Form1.js';
import Form2 from '../components/form/Form2.js';
import Form3 from '../components/form/Form3.js';
import Form4 from '../components/form/Form4.js';
import LessonPlanView from '../components/tabs/LessonPlanView.js';

const page1tab1grund = imageFolder => (
	<section className="tabbody content1">
		<h2>Att läsa på distans</h2>
		<p>
			Hela kursen drivs via vår lärplattform Connect. Föreläsningar kommer direktsändas, alternativt tillgängliggöras i efterhand via nätet. Du kommer behöva en webbkamera och ett headset med mikrofon för att kunna kommunicera och lösa uppgifterna. Du kommer även ha möjlighet att få hjälp under hela utbildningen via Slack (chat-app) där du kan ställa alla typer av frågor och få svar från både kursledaren och dina kurskamrater. I filmen nedan ser du hur man arbetar med Slack.
		</p>
		<VideoImage src={'img/' + imageFolder + 'slack_arbeta_med.png'} alt="Att arbeta med Slack" vid="xQIiCLztuEQ" />
		<h2>Att arbeta i grupp på distans</h2>
		<p>
			Du kommer redan från början av kursen tillhöra en grupp. Tanken är att gruppen ska fungera som ett stöd (arbetslag) där ni kan utbyta erfarenheter och idéer under kursen. Vissa av uppgiterna ska ni också lösa i grupp. Gruppindelning kommer att ske i samband med kursstart. Ni kommer kunna mötas i er grupp i Connect eller via valfri plattform (Skype, 365 osv). I filmen nedan ser du hur man arbetar med Connect.
		</p>
		<VideoImage src={'img/' + imageFolder + 'att_arbeta_pa_distans.png'} alt="Struktur och kursupplägg" vid="SDwsbZhD5SY" />
		<h2>Individuella uppgifter</h2>
		<p>
			Till varje kursmoment kommer också en uppgift som du ska lösa individuellt. Det kan vara en reflektion som du ska skriva eller programmeringsuppgift som ska lösas. De inlämnade uppgifterna sker här på kurssidan under fliken uppgift (ni ska inte maila över några uppgifter till läraren).
		</p>
		<p>
			Vissa av uppgifterna kommer bli synliga för alla på kursen men du bestämmer själv om du sedan vill göra det publik på webben. Detta gör vi för att vi även vill att ni ska kunna opponera på varandras arbete.
		</p>
		<p className="space">
			<img src={'img/distans_ikon.png'} alt="Distans, ikoner" />
		</p>
		<p>
			<LinkChangeTab cname="linknext1" text="Till nästa flik" next="2" />
		</p>
	</section>
);
const page1tab1gym = imageFolder => (
	<section className="tabbody content1">
		<h2>Att läsa på distans</h2>
		<p>
        I den här kursen är det nödvändigt att hitta en miljö att programmera i. Med miljö menas någon molntjänst online eller programvara att använda offline. En rekommendation är att välja en molntjänst för att lättare kunna dela det du programmerat med andra på kursen. Det är också en fördel att välja en miljö som eleverna kan använda. Även i det avseendet är en molntjänst oftast bäst eftersom den är tillgänglig på alla olika enheter som t ex Chromebook, PC etc och inte kräver någon installation. Det finns många olika molntjänster att välja mellan.
		</p>
		<ul>
            <li>
            En av dem är ‘repl.it’. Följ länken <LinkNewWindow href="https://repl.it/languages" id="linkRepl.it" >här</LinkNewWindow> för att komma dit. Välj sedan språket ‘Python’ från listan. Både lärare och elever kan välja att starta ett konto på sidan om man vill ha möjlighet att spara sina program online. Det går också att använda utan registrering av konto.
            </li>

            <li>
            En annan molntjänst är ‘Trinket’ där man också kan programmera i språket Python 3. Du hittar trinket <LinkNewWindow href="https://trinket.io/features/python3" id="linkTrinket">här</LinkNewWindow>.
            </li>

            <li>
            Ytterligare en molntjänst är ‘Code Skulptor’. Även där kan man skriva kod i Python 3. Du hittar code skulptor <LinkNewWindow href="https://py3.codeskulptor.org/" id="linkCodeSkulptor">här</LinkNewWindow>.
            </li>

            <li>
            Det går att ladda ner Python till PC, Mac, Linux och andra operativsystem. I den <LinkNewWindow href="https://www.anaconda.com/" id="linkAnaconda">här</LinkNewWindow>. Om du känner dig ovan vid att hantera nedladdningar och installera programvara så välj hellre någon av molntjänsterna.
            </li>

			<li>
			Google colab är en googletjänst som tex docs som går att använda för att programmera och har funktioner för att skriva matematik.
			Du hittar colabs <LinkNewWindow href="https://www.google.com/url?sa=t&source=web&rct=j&url=https://colab.research.google.com/&ved=2ahUKEwjrsOennc7nAhUw_SoKHWWoCCYQFjAAegQIBxAD&usg=AOvVaw3A5aPK2kLFzKOzb6sOckVw" id="linkColab">här</LinkNewWindow>.

			</li>
        </ul>
		<p>
			<LinkChangeTab cname="linknext1" text="Till nästa flik" next="2" />
		</p>
	</section>
);
const page1tab2grund = imageFolder => (
	<section className="tabbody content2">
		<h2>En introduktion</h2>
		<p>
			För dig som inte arbetat med att programmera tidigare eller vill skapa dig en första bild av fältet innan vi träffas ute i Connect har en av våra lärare spelat in en kort föreläsning (se filmen nedan). Tanken är att vi ska få gemensam utgångspunkt för våra diskussioner. Här behöver man inte förstå allt eller hålla med om det som sägs. Vi vill höra vad just du har för tidigare erfarenheter av programmering och hur du tänker kring ämnet. Under flik C kommer du få lösa en liten uppgift som bygger på att vi som driver kursen får en chans att veta vilka ni är inför första mötet.
		</p>
		<p>
			Syns snart!
		</p>
		<p>1) Skolverket, en översikt om vad programmering är och var det finns.</p>
		<VideoImage src={'img/' + imageFolder + 'skolverket_film.png'} alt="Vad är programmering?" vid='z7g3zhjuFxc' />
		<p>2) Henrik Bygren Lärare i programmerings. Går lite djupare i vad programmering faktiskt gör och hur det fungerar.</p>
		<VideoImage src={'img/' + imageFolder + 'vad_ar_programmering_2.png'} alt="Vad är programmering?" vid='lG3z8gpGdmU' />

		<p>
			<LinkChangeTab cname="linknext2" text="Till nästa flik" next="3" />
		</p>
	</section>
);
const page1tab2gym = imageFolder => (
	<section className="tabbody content2">
		<h2>Introduktion</h2>
		<p>
        I den här kursen ska vi fokusera på hur programmering kan användas till att skapa och använda algoritmer för att lösa matematiska problem. Målsättningen är att eleverna med korta program om max 10 rader kod i programmeringsspråket Python ska kunna lösa matematiska problem. För att få en liten översikt om vad programmering handlar om i stora drag ska vi titta på följande tre klipp. Tanken med de tre klippen är inte att förstå allt på detaljnivå utan att vi tillsammans får en gemensam utgångspunkt för vidare diskussioner.
		</p>
        <ul>
            <li>
            Ett klipp från Skolverket om var i samhället vi stöter på programmering och dess effekter.
            </li>
        </ul>
		<VideoImage src={'img/' + imageFolder + 'skolverket_film.png'} alt="Vad är programmering?" vid='z7g3zhjuFxc' />
		<ul>
            <li>
            Ett klipp om hur en dator fungerar översiktligt med Henrik Bygren, lärare i programmering. Från processor till programmering.
            </li>
        </ul>
		<VideoImage src={'img/' + imageFolder + 'vad_ar_programmering_2.png'} alt="Vad är programmering?" vid='lG3z8gpGdmU' />
        <ul>
            <li>
            Ett klipp om vad vi kan göra med Python i matematik. Vi kommer jobba uteslutande med programmeringsspråket Python av skäl som vi kommer gå in på senare. Återigen, kom ihåg att det inte är detaljerna i klippet som är det viktigaste utan överblicken.
            </li>
        </ul>
        <VideoImage src={'img/' + imageFolder + 'vad_ar_programmering.png'} alt="Vad är programmering?" vid='Fj7KIqlC4sw' />
		<p>
			<LinkChangeTab cname="linknext2" text="Till nästa flik" next="3" />
		</p>
	</section>
);
const page1tab3grund = imageFolder => (
	<section className="tabbody content3">
		<h2>Uppgift</h2>
		<p> Du kommer här få ett första uppdrag att lösa till vår första kursträff. Vi vill veta vem du är och vilket ämne och stadie som du arbetar med. Du kanske har arbetat med att driva och leda utbildningsaktiviteter där programmering ingick? Vilka tekniska resurser har ni på er skola? Tanken är att vi som driver kursen ska kunna skräddarsy utbildningen så den passar just dig. Det blir också lättare att dela in er i era grupper.  </p>
		<p>
			I studier som har gjorts av det brittiska centret Evidence for Policy and Practice Information (EPPI) synliggörs hur viktigt att lärare får identifiera sina egna fortbildningsbehov och att det finns processer som uppmuntrar, utvidgar och strukturerar den professionella dialogen och som möjliggör för lärare att pröva nya lärdomar i undervisningen. EPPI visar också på att kontinuerlig kollegial fortbildning för lärare har en stor potential att spela en avgörande roll vid tolkning och implementering av skolreformer i praktiken.
		</p>

		<Form1 />
	</section>
);
const page1tab3gym = imageFolder => (
	<section className="tabbody content3">
		<h2>Uppgift</h2>
		<p>Du kommer här få ett första uppdrag att lösa till vår första kursträff. Vi vill veta vem du är och vilka matematikkurser du undervisar i för närvarande. Vad har du för erfarenhet av programmering sen tidigare? Vilka tekniska resurser har ni på er skola? Tanken är att vi som driver kursen ska kunna skräddarsy utbildningen så den passar just dig. Dina svar kommer också användas för kommande gruppindelning.</p>
		<p>
			I studier som har gjorts av det brittiska centret Evidence for Policy and Practice Information (EPPI) synliggörs hur viktigt att lärare får identifiera sina egna fortbildningsbehov och att det finns processer som uppmuntrar, utvidgar och strukturerar den professionella dialogen och som möjliggör för lärare att pröva nya lärdomar i undervisningen. EPPI visar också på att kontinuerlig kollegial fortbildning för lärare har en stor potential att spela en avgörande roll vid tolkning och implementering av skolreformer i praktiken.
		</p>

		<Form1 />
	</section>
);
const page2tab1grund = imageFolder => (
	<section className="tabbody content1">
		<h2>Inventering och omvärldskoll</h2>
		<p>I det här moment kommer du få möjlighet att testa och utvärdera program, appar och metoder för programmering. Du har vid första live-mötet fått en överblick kring programmeringsfältet samt de pedagogiska hjälpmedel som finns och deras underliggande programspråk. Nu ska du själv välja ett program, teknik eller app att fördjupa dig i och sedan ge ditt omdöme. Viktigt här blir att ha ett kritiskt förhållningssätt kring de vi prövar. Tanken är att vi vid nästa live-träff ska utbyta de erfarenheter vi fått.</p>
		<p>Du kan antingen välja en av de utvalda programmen med vårt tillhörande resursmaterial, se nedan. Eller så tar du någon av de andra verktygen/programmen/apparna som vi samlat under nästa flik “Fältet” Du får givetvis välja något utanför dessa resurser också.</p>
		<p>Efter att du prövat din valda program/teknik, vill vi att du reflekterar kring detta under den sista fliken “Reflektion”.</p>
		<p>Till varje kursmoment kommer också finns en ren programmeringsdel som vi döpt till “Fördjupning” du hittar länken upp i informationsfliken. Det är inget krav att ni gör dessa övningar utan ska ses som just en fördjupning för er som vill. Om ni behöver hjälp med fördjupning övningarna får ni givetvis handledning via Slack.</p>
		<p>Lycka till!</p>
		<img alt="Omvärld programmering" src={'img/' + imageFolder + 'java.png'} />

		<p>
			<LinkChangeTab cname="linknext1" text="Till nästa flik" next="2" />
		</p>
	</section>
);
const page2tab1gym = imageFolder => (
	<section className="tabbody content1">
		<h2>Programmeringsspråket Python</h2>
		<p>Innan vi kör igång med programmeringstekniker som baseras på upprepning så ska vi komma igång med att räkna med variabler i Python. Python är ett mycket använt programmeringsspråk med en enkel syntax (dvs sättet som koden skrivs på, kan jämföras med grammatik i språk) och tillräckligt bra stöd i grunden för matematik. Alla programmeringstekniker som vi tar upp i den här kursen har motsvarigheter i många andra programmeringsspråk t ex javascript. Syntaxen kan dock variera något men är ofta liknande.</p>

        <h2>Onlinetjänster</h2>
        <p>För att komma igång med programmeringen behövs någon miljö att programmera i. Detta görs enklast online men kan också göras offline om man laddar ner och installerar relevanta program. I den här kursen kommer vi framför allt att arbeta med onlinelösningar. Den viktigaste orsaken till detta är att eleverna ofta har olika hårdvara t ex PC eller Chromebook. Onlinelösningar fungerar åtminstone nästan likadant oavsett hårdvara. Här finns några länkar till lämpliga programmeringsmiljöer online.</p>
        <ul>
            <li>
            <LinkNewWindow href="https://repl.it/" id="linkRepl.it">repl.it</LinkNewWindow>
            </li>
            <li>
            <LinkNewWindow href="https://trinket.io/features/python3" id="trinket">trinket</LinkNewWindow>
            </li>
            <li>
            <LinkNewWindow href="http://pythontutor.com/visualize.html#mode=edit" id="Python tutor">Python tutor</LinkNewWindow>
            </li>
            <li>
            <LinkNewWindow href="https://py3.codeskulptor.org/" id="Code Skulptor">Code Skulptor</LinkNewWindow>
            </li>
			<li>
			<LinkNewWindow href="https://www.google.com/url?sa=t&source=web&rct=j&url=https://colab.research.google.com/&ved=2ahUKEwjrsOennc7nAhUw_SoKHWWoCCYQFjAAegQIBxAD&usg=AOvVaw3A5aPK2kLFzKOzb6sOckVw">Google colab</LinkNewWindow>
			</li>
        </ul>

        <h2>Prova själv</h2>
        <p>Välj någon av onlinetjänsterna som du tycker passar dig och dina elever eller använd något eget alternativ. Börja med att prova dig fram med hjälp av föreläsningen. Till stöd kan du undersöka följande:</p>
        <ul>
            <li>
            Hur sätter man en variabels värde?
            </li>
            <li>
            Hur skriver man ut en variabels värde?
            </li>
            <li>
            Hur ändrar man en variabels värde?
            </li>
            <li>
            Hur kan man skriva formler som använder sig av flera variabler?
            </li>
        </ul>
        <p>Ett tips för att förstå vad som händer med variablerna är att skriva ut deras värde med print efter varje rad.</p>

        <p>Ett annat sätt att bekanta sig med programmering i Python är att använda tillägget turtle. Turtle är framtaget för pedagogiska ändamål där man med mycket enkla instruktioner får en sköldpadda (pil) att röra sig på skärmen. Du kan utgå från följande kod <LinkNewWindow href="https://repl.it/@magistergrip/TurtleKursExempel" id="TurtleKodExempel">TurtleKodExempel</LinkNewWindow>. Försök att modifiera koden så att sköldpaddan/pilen ritar:</p>
        <ul>
            <li>
            En kvadrat
            </li>
            <li>
            En rektangel
            </li>
            <li>
            En liksidig triangel
            </li>
            <li>
            En likbent triangel
            </li>
            <li>
            En regelbunden femhörning
            </li>
        </ul>

		<p>Lycka till!</p>
		<img alt="Omvärld programmering" src={'img/' + imageFolder + 'python-logo.svg'} />

		<p>
			<LinkChangeTab cname="linknext1" text="Till nästa flik" next="2" />
		</p>
	</section>
);
const page2tab2grund = imageFolder => (
		<section className="tabbody content2">
			<h2>Tillgängliga verktyg</h2>

			<p>Nedan har vi samlat några av de vanligaste programmeringsresurserna.</p>
			<h3>Blockly Games</h3>
			<span style={{display: 'inline-block'}}><img style={{width: '40%'}} src={'img/' + imageFolder + 'blockly.png'} alt="Blockly Games" /></span>
			<p>En rolig sida för att lära sig programmering från lätt till svår nivå. Det börjar med lätta banor som bli knepigare och knepigare. Från yngre åldrar till högstadienivå. För att locka de yngre har man skapat kända figurer som Angry birds, Frost, Minecraft för att nämna några. Det som dock känns bra är att man verkligen får utmana sig själv att tänka ut lösningar. Sidan är dock lite plottrig och man får leta ett tag för att kunna orientera sig. Man kan gå efter ålder men måste kolla hur lätt eller svår varje klass är. Det går att skapa elevinloggningar och fortsätta där man förut slutade.</p>
			<p>
				<LinkNewWindow href="https://code.org/" id="linkCodeOrg" >https://code.org/</LinkNewWindow>
			</p>
			<p>
				<LinkNewWindow href="https://www.youtube.com/watch?v=Ybwp0TDNP8Q" id="linkCodeOrgYT"> https://www.youtube.com/watch?v=Ybwp0TDNP8Q </LinkNewWindow>
			</p>

			<span style={{display: 'inline-block'}}><img style={{width: '40%'}} src={'img/' + imageFolder + 'kojo.png'} alt="Kojo" /></span>
			<p>
				Kojo är ett gratisprogram där man på ett enkelt sätt kan lära sig hur det går till att programmera. Kojo använder språket Scala. Programmet Kojo kan du ladda ner från <LinkNewWindow href="http://kogics.net/kojo" id="linkKojo">kogics.net/kojo</LinkNewWindow>
			</p>
			<p>
				<LinkNewWindow href="http://fileadmin.cs.lth.se/cs/Personal/Bjorn_Regnell/padda.pdf" id="linkKojoCheatSheet">Här finns en bra lathund</LinkNewWindow> med svenska kortkommandon i Kojo.
			</p>
			<p>
				Hur man installerar Kojo till sin dator <LinkNewWindow href="https://www.youtube.com/watch?v=sRaoq4lhilk" id="linkKojoYT">https://www.youtube.com/watch?v=sRaoq4lhilk</LinkNewWindow>
			</p>
			<p>
				Grunderna i Kojo <LinkNewWindow href="https://www.youtube.com/watch?v=-Oe4IblE4-g&feature=youtu.be" id="linkKojoYT2">https://www.youtube.com/watch?v=-Oe4IblE4-g&feature=youtu.be</LinkNewWindow>
			</p>

			<span style={{display: 'inline-block'}}><img style={{width: '40%'}} src={'img/' + imageFolder + 'scratch.png'} alt="Scratch" /></span>
			<p>
				Scratch är ett visuellt programmeringsspråk utvecklat på MIT av The Lifelong Kindergarten group. Gruppen arbetar för att barn och unga ska ha ett kreativt förhållningssätt till teknik och IKT. Scratch är en av gruppens större framgångar och programmet används idag över stora delar av den engelskspråkiga världen.
			</p>
			<p>
				<LinkNewWindow href="https://scratch.mit.edu/" id="linkScrath1">https://scratch.mit.edu/</LinkNewWindow>
			</p>
			<p>
				Scratch 1 Grunderna i Scratch <br/>
				<LinkNewWindow href="https://www.youtube.com/watch?v=3x5iryTpqQM&feature=youtu.be">https://www.youtube.com/watch?v=3x5iryTpqQM&feature=youtu.be</LinkNewWindow>
			</p>
			<p>
				Scratch 2 Enklare programmering i Scratch <br/>
				<LinkNewWindow href="https://www.youtube.com/watch?v=r1Jf-o78ayA&feature=youtu.be">https://www.youtube.com/watch?v=r1Jf-o78ayA&feature=youtu.be</LinkNewWindow>
			</p>
			<p>
				Scratch 3 Programmera och skapa interaktioner med Scratch <br/>
				<LinkNewWindow href="https://www.youtube.com/watch?v=ct7Ad45DAUU&feature=youtu.be">https://www.youtube.com/watch?v=3x5iryTpqQM&feature=youtu.be</LinkNewWindow>
			</p>

			<p>Lycka till med programmeringen!</p>
			<p>
				<LinkChangeTab cname="linknext2" text="Till nästa flik" next="3" />
			</p>
		</section>
	);
const page2tab2gym = imageFolder => (
    <section className="tabbody content2">
			<h2>Repetition och villkor</h2>

			<p>I den här delen ska vi sätta igång med att använda repetition och villkor. En repetitionssats kallas ofta “loop” i programmering. Ibland kommer vi använda den benämningen även i den här kursen. En loop är helt synonymt med repetitionssats i det här sammanhanget.</p>

			<p>Hela det här momentet baseras på egen övning med repetitionssatser med villkor. Syftet är alltså att börja använda och förstå vad repetitionssatsen “while” gör. Som stöd kan du utgå från föreläsningen och punkterna nedan.</p>
            <ul>
                <li>
                Beräkna summan 2 + 4 + 6 + 8 + … + 100.
                </li>
                <li>
                Beräkna summan 2 + 4 + 8 + 16 + … + 1024.
                </li>
                <li>
                Beräkna produkten 30 * 29 * 28 *... * 1.
                </li>
                <li>
                Hur många termer ska man lägga ihop i summan 1 + 2 + 3 + 4… för att summan ska bli mer än 500?
                </li>
                <li>
                Vilket nummer har det första tal i talföljden 3, 6, 12, 24, … som överstiger 1000?
                </li>
                <li>
                Mia sätter in 2000 kr den 1:a januari varje år på ett konto med årsräntesatsen 1,5% med start 2019. Räntan betalas ut den 31:a december varje år.
                    <ul>
                        <li>Hur mycket finns på kontot 20 år efter första insättningen, efter att räntan utbetalats men innan den nya insättningen?</li>
                        <li>Vilket år är den första gången som beloppet på kontot överstiger 30000 kr direkt efter att räntan betalats ut?</li>
                    </ul>
                </li>
                <li>
                Hur stor är sannolikheten att 20 personer har olika födelsedag, alltså dag på året då man fyller år?
                </li>
            </ul>

            <h2>Likheter och skillnader</h2>

            <p>Efter att provat lite olika programmeringstekniker är det lämpligt att reflektera över skillnader och likheter mellan programmering och matematik. Utgå gärna från stödpunkterna nedan och fundera över skillnader och likheter mellan programmering och matematik när det gäller följande:</p>
            <ul>
                <li>
                Variabel
                </li>
                <li>
                Likamedtecknet
                </li>
                <li>
                Symbolerna för de fyra räknesätten och “upphöjt till”
                </li>
                <li>
                Symboler för att öka eller minska en variabels värde
                </li>
                <li>
                Decimaltal
                </li>
                <li>
                Tiopotenser
                </li>
                <li>
                Egna reflektioner
                </li>
            </ul>

			<p>Lycka till med programmeringen!</p>
			<p>
				<LinkChangeTab cname="linknext2" text="Till nästa flik" next="3" />
			</p>
		</section>
	);
const page2tab3grund = imageFolder => (
	<section className="tabbody content3">
		<h2>Reflektion</h2>
		<Form2 />
	</section>
);
const page2tab3gym = imageFolder => (
	<section className="tabbody content3">
		<h2>Reflektion</h2>
		<Form2 />
	</section>
);
const page3tab1grund = imageFolder => (
	<section className="tabbody content1">
		<h2>Att skapa undervisning utifrån uppdraget</h2>
		<p>Vi ska nu utforska uppdraget genom att skapa, hålla och utvärdera en lektion. Vi utgår ifrån ett flöde genom följande steg:</p>
		<ol>
			<li> Inventering </li>
			<li> Planering </li>
			<li> Genomförande </li>
			<li> Utvärdering </li>
		</ol>
		<p>
			Kom ihåg att använda slack för att diskutera uppgiften, hur avgränsning kan se ut, hur den kan bedömas osv.
		</p>

		<h3>1. Inventering</h3>
		<p>För att kunna planera och genomföra din undervisning behöver du göra en inventering av de förutsättningar som finns på din skola.</p>
		<p>
			Titta dels på vilken teknik som finns tillgänglig för din klass men även vad eleverna och du redan kan och hur mycket tid planeringen får ta. Kom även ihåg att titta på hur läroplanens formulering passar in på den teknik som finns tillgänglig.
		</p>

		<h3>2. Planering</h3>
		<p>Ta fram din lektionsplanering utifrån de förutsättningar som finns på din skola. Din planering fyller du i på Flik B (länka till den här) så att resten av din grupp kan se din planering för att komma med feedback.</p>

		<h3>3. Genomförande</h3>
		<p>Här handlar det om att bedriva undervisning med elever och att dokumentera resultatet. Ni får gärna låta era elever göra utvärderingen som ni kan skicka ut via kurssidan.</p>

		<h3>4. Utvärdering</h3>
		<p>Reflektera över hur du tycker att din lektionsplanering gick att genomföra. Låt gärna eleverna vara delaktiga i utvärderingen.</p>

		<p>
			<LinkChangeTab cname="linknext1" text="Till nästa flik" next="2" />
		</p>
	</section>
);
const page3tab1gym = imageFolder => (
	<section className="tabbody content1">
		<h2>Att skapa undervisning utifrån uppdraget</h2>
		<p>Din uppgift är att välja ett område i din kurs där programmering passar som strategi för problemlösning. Vi ska nu utforska uppdraget genom att skapa, hålla och utvärdera en eller flera lektioner. Ett råd är att börja med att eleverna får bekanta sig med programmeringsmiljön vid ett lektionstillfälle innan själva problemlösningssituationen genomförs. Bedömningen av elevernas prestation kanske också kan vara lämpligt att lägga in vid ytterligare ett annat tillfälle. Använd gärna följande modell för genomförandet:</p>

		<ol>
			<li> Inventering - För att kunna planera och genomföra din undervisning behöver du göra en inventering av de förutsättningar som finns på din skola. Titta dels på vilken teknik som finns tillgänglig för din klass men även vad eleverna och du redan kan och hur mycket tid planeringen får ta. </li>
			<li> Planering - Ta fram din lektionsplanering utifrån de förutsättningar som finns på din skola. Din planering skriver du i in under nästa flik så att resten av din grupp kan se din planering för att komma med feedback. Tänk också igenom hur du skulle vilja bedöma uppgiften. </li>
            <li> Genomförande - Här handlar det om att bedriva undervisning med elever och att dokumentera resultatet. Låt gärna låta eleverna göra utvärderingen som ni kan skicka ut via kurssidan. </li>
            <li> Utvärdering - Reflektera över hur du tycker att din lektionsplanering gick att genomföra. Låt gärna eleverna vara delaktiga i utvärderingen. </li>
		</ol>

		<p>
			Kom ihåg att använda slack för att diskutera uppgiften, hur avgränsning kan se ut, hur den kan bedömas osv.
		</p>

		<p>
			<LinkChangeTab cname="linknext1" text="Till nästa flik" next="2" />
		</p>
	</section>
);
const page3tab2 = imageFolder => (
	<section className="tabbody content2">
		<h2>Lektionsplanering</h2>
		<p id="f3lpintro">
			Fyll i din lektionsplanering i fälten nedan.
		</p>
		<Form3 />
		<p>
			<LinkChangeTab cname="linknext2" text="Till nästa flik" next="3" />
		</p>
	</section>
);
const page3tab3 = imageFolder => (
	<section className="tabbody content3">
		<LessonPlanView />
	</section>
);
const page4tab1 = imageFolder => (
	<section className="tabbody content1">
		<h2>Examination</h2>
		<p>
			Du ska innan examinationstillfället förbereda en kort presentation över hur ditt utfallet av din utbildningsaktivitet blev. Du får gärna ta fram en presentation som stöd för ditt berättande (presentationer som fungerar bra i Connect är Powerpoint eller PDF). Presentationerna får inte vara längre än 10 min plus 5 min frågestund efteråt. Alla ska ha läst varandras lektionsplaneringar så ni inte behöver lägga så mycket tid att förklara ert upplägg, utan sätt fokus på;
		</p>
		<ul>
			<li>Hur eleverna upplevde utbildningsaktiviteten?</li>
    		<li>Om aktiviteten kändes relevant?</li>
    		<li>Om upplägget, innehåll och tidsaspekt fungerade?</li>
    		<li>Vilka utmaningar och svårigheter som finns?</li>
    		<li>Vad man eventuellt kommer behöver förändra i upplägget?</li>
    		<li>OBS! Viktigt att ni håller tiden, alla i gruppen måste hinna hålla sin del. Ett bra tips är att ni i gruppen har en generalrepetition innan redovisningstillfället. Examinerande lärare kommer hålla koll på tiden.</li>
    		<li>Examinerande lärare avslutar varje redovisning med att ge feedback på era projektarbeten.</li>
		</ul>

		<p>
			<LinkChangeTab cname="linknext1" text="Till nästa flik" next="2" />
		</p>
	</section>
);
const page4tab2 = imageFolder => (
	<section className="tabbody content2">
		<Form4 />
	</section>
);
/*const page4tab3 = (
	<section className="tabbody content3">
		<h2>Dela, diskutera och planera</h2>
		<p>{/ * här är uppgiften att träffas i grupp inför examinationen * /}</p>
	</section>
);*/
const page6tab1 = imageFolder => (
	<section className="tabbody content1">
		<h2> HMTL &amp; CSS </h2>
		<VideoImage src={'img/' + imageFolder + 'html_film.png'} alt="HTML &amp; CSS" vid={null} />
		<ul>
			<li> Resurs 1 </li>
			<li> Resurs 2 </li>
			<li> Resurs 3 </li>
		</ul>
	</section>
)
const page6tab2 = imageFolder => (
	<section className="tabbody content2">
		<h2>JavaScript</h2>
		<VideoImage src={'img/' + imageFolder + 'js_film.png'} alt="JavaScript" vid={null} />
		<ul>
			<li> <LinkNewWindow href="http://javascriptissexy.com/how-to-learn-javascript-properly/">How to Learn JavaScript Properly</LinkNewWindow> </li>
			<li> Resurs 2 </li>
			<li> Resurs 3 </li>
		</ul>
	</section>
)
const page6tab3 = imageFolder => (
	<section className="tabbody content3">
		<h2>Python</h2>
		<VideoImage src={'img/' + imageFolder + 'python_film.png'} alt="Python" vid={null} />
		<ul>
			<li> <LinkNewWindow href="https://www.codecademy.com/learn/learn-python">Learn Python - codecademy</LinkNewWindow> </li>
			<li> <LinkNewWindow href="https://www.learnpython.org/">learnpython.org</LinkNewWindow> </li>
			<li> <LinkNewWindow href="https://wiki.python.org/moin/BeginnersGuide/Programmers">Python Beginners Guide</LinkNewWindow> </li>
		</ul>
	</section>
)

const tabs = {
    page1tab1grund, page1tab2grund, page1tab3grund,
	page2tab1grund, page2tab2grund, page2tab3grund,
	page3tab1grund,
    page1tab1gym, page1tab2gym, page1tab3gym,
    page2tab1gym, page2tab2gym, page2tab3gym,
    page3tab1gym,
    page3tab2,
    page3tab3,
    page4tab1, page4tab2,
    page6tab1, page6tab2, page6tab3
}

export default tabs;
