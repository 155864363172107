import React, { Component } from 'react';
import { connect } from 'react-redux';

import YouTube from 'react-youtube';

import ACTIONS from '../actions/videoActions.js';



// Useful article about YouTube API and React:
// https://time2hack.com/2017/11/easiest-way-to-integrate-youtube-iframe-api-in-angular-and-react/

class Lightbox extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  player: []
		};
		this._onReady = this._onReady.bind(this);
	}

	_onReady(event) {
		const player = this.state.player;
		player.push(event.target);
		this.setState({
		  player: player
		});
	  }

	handleLightboxClick = event => {
		// clicked outside video, pause and close lightbox
		this.state.player[0].pauseVideo();
		this.props.dispatch(ACTIONS.hideVideoOverlay());
	}

	render() {

		const display = this.props.playerVisible ? 'block' : 'none';	

		const opts = {
			height: '480',
			width: '700'
		}
		

		return (
			<div className="lightbox" style={{ display: display }} onClick={this.handleLightboxClick}>
				<div id="player" ref={this.playerRef}> </div>
				<div className="iFrame">
				<YouTube opts={opts} videoId={this.props.videoId} onReady={this._onReady}/>
				</div>
			</div>
		)
	}
}



const mapStateToProps = ({ video }) => ({
	playerVisible: video.playerVisible,
	isPlaying: video.isPlaying,
	videoId: video.videoId
})

export default connect(mapStateToProps)(Lightbox);
