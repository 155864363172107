import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DownloadButton, LinkNewWindow } from './Helpers.js';
import ACTIONS from '../actions/navigationActions.js';
import { VERSION, PAGES } from '../constants.js';


class PageSummary extends Component {
	render() {
		const btnTheme = this.props.themeClass;
		// knappen "fördjupning" leder till en wiki, som vi inte arbetat med
		const furtherReading = null && this.props.version === VERSION.GYMNASIUM
		 	? ( <LinkNewWindow className={'button ' + btnTheme} href="http://wiki.weboni.se/index.php?title=Huvudsida">Fördjupning (på gång)</LinkNewWindow> )
			: null;
		const activityMapButton =
			<button className={btnTheme} onClick={() => this.props.dispatch(ACTIONS.changePage(PAGES.MAP))}>Programmeringskarta</button>
			;

		return (
			<section className="summary">
			<h2 className={btnTheme}> Information </h2>
			<div>
				<img className="profile" src={'img/niklas_grip.png'} alt="Niklas Grip" />
				<strong>Niklas Grip</strong> <br />
				<div className="social">
					<a href="mailto:grip.niklas@gmail.com">
					<img src={'img/mail_icon.png'} alt="E-post" />
					</a>
					{/*<a href="#">
					<img src={'img/' + this.props.imageFolder + 'GitHub_icon.png'} alt="GitHub" />
					</a>*/}
				</div>
			</div>
			<div>
				<strong>Niklas</strong> är gymnasielärare i matematik, fysik och programmering och har arbetat sedan 2008. Sedan 2015 har Niklas haft flera uppdrag som utbildare på flera fortbildningar inom programmering i matematik och har en gedigen erfarenhet på området. Niklas har haft flera uppdrag som förstelärare kopplat till matematik och digitala verktyg. I dagsläget arbetar Niklas på en gymnasieskola i Göteborg och använder sig av programmering i samtliga matematikkurser.
			</div>
			<div>
				<strong>Utbildningsform</strong>: Distans
			</div>

			<div className="center">
			<button className={btnTheme}
			 	onClick={() => this.props.dispatch(ACTIONS.changePage(PAGES.UPPDRAGET, 3))}>Lektionsplaneringar </button>
			{activityMapButton}
			<DownloadButton className={btnTheme} href="resources/Advania_2020_kursplan_gym.pdf"> Kursplan som PDF </DownloadButton>
			{furtherReading}
			</div>
			</section>
		);
	}
}

const mapStateToProps = ({ theme }) => ({
	imageFolder: theme.imageFolder,
	themeClass: (theme.version === VERSION.GYMNASIUM ? 'themehighschool' : ''),
	version: theme.version
})
export default connect(mapStateToProps)(PageSummary);
