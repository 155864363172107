import React, { Component } from 'react';
import {connect } from 'react-redux';
import ACTIONS from '../../actions/formActions.js';
import { VERSION } from '../../constants.js';

function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

class LessonPlanCard extends Component {
	render() {
		let isOwner = false;
		let teacher = '';
		if(this.props.plan.firstName && this.props.plan.lastName){
			teacher = `${capitalize(this.props.plan.firstName)} ${capitalize(this.props.plan.lastName)}`;
		}

		let editable = isOwner ? (
			<img className="settings" alt="Ändra planering" src={'img/icon_pen.png'}
				onClick={() => this.props.dispatch(ACTIONS.updateLessonPlan(this.props.plan))}
			/>
		) : null;
		let course = 'Klass';
		if( this.props.version === VERSION.GYMNASIUM ) {
			course = 'Kurs';
		}
		return (
			<div className="card">
				<h2> {this.props.plan.title} </h2>
				<p>{this.arrayToString(this.props.plan.subjects)}</p>
				<p>{course} {this.arrayToString(this.props.plan.classes)}</p>
				<p><em>{teacher} </em></p>
				{this.props.plan.updatedWhen} <br/>
				<img className="preview" alt="Visa planering" src={'img/icon_oga.png'}
					onClick={() => this.props.dispatch(ACTIONS.viewLessonPlan(this.props.plan))} />
				{editable}
			</div>
		);
	}
	arrayToString = xs => {
		return xs && xs.join && xs.join(',');
		// if( typeof xs === 'object' ) {
		// 	return xs.join(',');
		// }
		// return xs;
	}
}
// update: navigate to form, fill form with existing LP
// view: navigate to form, fill form with existing LP

/*const mapStateToProps = ({ lessonPlans }) => ({
	plan: lessonPlans.find()
})*/
const mapStateToProps = ({ theme }) => ({
	imageFolder: theme.imageFolder,
	version: theme.version
})
export default connect(mapStateToProps)(LessonPlanCard);
