import ACTIONS from '../actions/constants.js';

const initialState = {
	// no groups initially known
	hasFetched: false,
	isFetching: false,
	groups: []
}

const groupsReducer = (state = initialState, action) => {
	switch( action.type ) {
		case ACTIONS.STARTSFETCHINGGROUPS:
			return {
				...state, isFetching: true, groups: []
			}
		case ACTIONS.GOTGROUPS:
			return {
				hasFetched: true,
				isFetching: false,
				groups: action.groups
			}

		default:
			return state;
	}
}


export { groupsReducer };
