import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../actions/formActions.js';

import { LinkChangePage } from '../Helpers.js';
import { VERSION } from '../../constants.js'
import FirebaseMethods from '../../utils/firebase.js';


class Form2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSubmitted: props.isSubmitted,
			isValid: props.isValid,
			error: props.error,

			reflection: props.reflection,
			resourcesUsed: props.resourcesUsed
		}
	}

	validate = values => {
		// Check if all user input is valid; if so, set isValid=true in preparation for submitting
		// console.log('Form2.validate', values);
		const { reflection, resourcesUsed } = values;
		let validReflection = reflection !== '';
		let validResource = resourcesUsed !== '';
		//console.log('Form2 validation: ', validReflection, validResource, this.state)

		let errors = {};
		if( !validReflection ) errors.reflection = 'Skriv något om hur det gick att fördjupa sig i din resurs.';
		if( !validResource ) errors.resourcesUsed = 'Skriv vilken resurs du använde.';
		return errors;
	}
	doValidate = values => {
		let errors = this.validate(values || this.state);
		let hasErrors = Object.keys(errors).length > 0;
		this.setState({ error: errors, isValid: !hasErrors });
	}
	update = (event, prop) => {
		let obj = {};
		obj[prop] = event.target.value;
		this.setState(obj, () => {
			this.props.dispatch(ACTIONS.saveForm2(this.state));
		});
		const values = { ...this.state };
		values[prop] = event.target.value;
		this.doValidate(values);
	}
	submit = event => {
		console.log('Form2.submit: ', this.state, this.props.userId);
		this.props.dispatch(ACTIONS.submitForm2(this.state));

		let { reflection, resourcesUsed } = this.state;

		FirebaseMethods.submitForm2(this.props.userId, { isSubmitted: true, reflection, resourcesUsed }, () => console.log('Form2.submit SUcCESS'));
	}
	// componentDidUpdate() {
	// 	this.validate(this.state);
	// }

	render() {

		let resources = 'Skriv vilken programmeringsresurs du använt.';
		if( this.props.version === VERSION.GYMNASIUM ) {
			resources = 'Skriv ett område i någon matematikkurs där du skulle kunna tänka dig att använda programmering.'
		}

		const whenSubmitted = this.props.isSubmitted ?
			(
				<div className="whensubmitted">
					<p>
						Dina svar är registrerade. Fortsätt med steg 3, uppdraget!
					</p>
					<p>
						<LinkChangePage cname="linkstep" text="Till nästa steg" next="3" />
					</p>
				</div>
			) : null;

		// Validering - visa felmeddelanden
		const error = this.state.error;
		const hasErrors = Object.keys(error).length > 0;

		return (
			<Fragment>
			{whenSubmitted}
			<div className="form">
				<div>
					<p> Hur gick det? Skriv ner dina tankar! </p>
					<textarea rows="12"
					 	placeholder=""
						onChange={e => this.update(e, 'reflection')}></textarea>
					<div className="errors">{error.reflection}</div>
				</div>
				<div>
					<p style={{marginBottom: "-0.5em"}}>
						{resources} <br />
						<input
							type="text"
							placeholder="Exempel: Code, Scratch."
							onChange={e => this.update(e, 'resourcesUsed')} />
					</p>
					<div className="errors">{error.resourcesUsed}</div>
				</div>
				<div>
					<button onClick={this.submit}
						disabled={!this.state.isValid}>Lämna in reflektion</button>
					<div className="errors">{hasErrors ? 'Vi behöver mer information från dig för att genomföra kursen.' : ''}</div>
				</div>
			</div>
			</Fragment>
		);
	}
}


const mapStateToProps = ({ formData, user, theme }) => ({
	userId: user.userId,
	isSubmitted: formData.form2.isSubmitted,
	isValid: formData.form2.isValid,

	reflection: formData.form2.reflection,
	resourcesUsed: formData.form2.resourcesUsed,

	error: formData.form2.error,
	version: theme.version
})

export default connect(mapStateToProps)(Form2);
