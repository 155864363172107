import ACTIONS from './constants.js';


const disableLoginButton = disable => ({
	type: ACTIONS.UI_LOGINDISABLED,
	disable
})
const enableLoginButton = () => ({
	type: ACTIONS.UI_LOGINENABLED
})
const loginInProgress = () => ({
	type: ACTIONS.UI_LOGGINGIN
})
const actions = {
	disableLoginButton,
	enableLoginButton,
	loginInProgress
};

export default actions;
