import ACTIONS from './constants.js';

const mapIsLoaded = () => ({
	type: ACTIONS.MAP_LOADED
})
const mapIsLoading = () => ({
	type: ACTIONS.MAP_LOADING
})
const mapFailedToLoad = () => ({
	type: ACTIONS.MAP_FAILED
})
const mapDoInit = () => ({
	type: ACTIONS.MAP_INIT
})
const mapShowInfoBoxFor = place => ({
	type: ACTIONS.MAP_SHOW_INFOBOX,
	place: place
})
const mapHideInfoBox = () => ({
	type: ACTIONS.MAP_HIDE_INFOBOX
})
const goToLessonPlan = plan => ({
	type: ACTIONS.MAP_GO_TO_LESSON_PLAN,
	plan: plan
})


const actions = {
	mapDoInit,
	mapIsLoaded,
	mapIsLoading,
	mapFailedToLoad,
	mapShowInfoBoxFor,
	mapHideInfoBox,
	goToLessonPlan
}

export default actions;
