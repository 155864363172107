import React from 'react';
import {connect} from 'react-redux';

import { VERSION } from '../../constants.js';
import ACTIONS from '../../actions/formActions.js';
import '../style/ViewLessonPlan.css'

class ViewLessonPlan extends React.Component {
	render() {
		const plan = this.props.plan;
		// console.log('ViewLessonPlan: we got this ', plan);
		const closeLp = () => this.props.dispatch(ACTIONS.closeLessonPlan());

		// Convert to array if tags are in a string
		// console.log('ViewLessonPlan tags:', plan.tags);
		let tagArray = typeof(plan.tags) === 'string'
			? plan.tags.split(',').map(t => t.trim())
			: plan.tags;
		const tags = tagArray.map(tag => (<div key={tag}>{tag}</div>));

		let classOrCourse = this.props.version === VERSION.GYMNASIUM ?
			'kurser' : 'klasses';

		return (
			<div className="lessonPlan">
				<h3>{plan.title}</h3>
				<div className="tags">{tags}</div>

				<p className="content">{plan.content}</p>

				<div className="grid">
					<div>För {classOrCourse} </div>
					<div>{plan.classes}</div>
					<div>Mål</div>
					<div>{plan.goalAndPurpose}</div>
					<div>Tidsåtgång</div>
					<div>{plan.estimatedTime}</div>
					<div>Utrustning</div>
					<div>{plan.equipment}</div>
					<div>Bedömning</div>
					<div>{plan.grading}</div>
					<div>Planerad av</div>
					<div>{plan.teacherName}</div>
				</div>
				<img className="x" alt="Stäng" src={'img/x.png'}
				 	onClick={closeLp} />
			</div>
		) // TODO: ta kryss från map-infobox, för att stänga LP
	}
}
let mapStateToProps = ({ theme }) => ({
	version: theme.version
})
export default connect(mapStateToProps)(ViewLessonPlan);
