import ACTIONS from './constants.js';
import { VERSION } from '../constants.js';

const changeThemeToSchool = () => ({
	type: ACTIONS.CHANGEVERSION,
	version: VERSION.GRUNDSKOLA,
	imageFolder: 'skola/'
})
const changeThemeToHighSchool = () => ({
	type: ACTIONS.CHANGEVERSION,
	version: VERSION.GYMNASIUM,
	imageFolder: 'gymnasium/'
})

const actions = {
	changeThemeToSchool,
	changeThemeToHighSchool
}

export default actions;
