import ACTIONS from './constants.js';

const startsFetchingGroups = () => ({
	type: ACTIONS.STARTSFETCHINGGROUPS
})
const gotGroups = groups => ({
	type: ACTIONS.GOTGROUPS,
	groups
})

const actions = {
	startsFetchingGroups,
	gotGroups
}

export default actions;
