import React, { Component } from 'react';
import { connect } from 'react-redux';

import ACTIONS from '../../actions/mapActions.js';
import FACTIONS from '../../actions/formActions.js';


class InfoBox extends Component {
	constructor(props) {
		super(props);
		this.funRef = React.createRef();
		this.hardRef = React.createRef();
		this.timeRef = React.createRef();
	}
	render() {
		// console.log('InfoBox render, visible='+this.props.visible, this.props.place);
		if( !this.props.visible ) return null;

		const p = this.props.place;
		const formatPercent = x => Math.round(x * 100) + '%'
		return (
			<div id="infobox">
				<h1>{p.school}</h1>
				<img className="x" alt="Stäng" src={'img/x.png'}
			 		onClick={() => this.props.dispatch(ACTIONS.mapHideInfoBox())}/>
				<p>
					<strong>{p.title}</strong> <br />
					<strong>Genomförd:</strong> <span>{p.dateDone}</span> <br />
					<strong>Klass:</strong> <span>{p.target}</span> <br />
					{/*<strong>Gruppstorlek:</strong> <span id="infogroupsize">?</span> <br />
					<strong>Lokal:</strong> <span id="infoplace">?</span> <br />*/}
					<strong>Utrustning:</strong> <span>{p.equipment}</span> <br />
					<strong>Tags:</strong> <span>{p.tags ? p.tags.join(', ') : '-'}</span>
				</p>

				<p>
					{p.description}
				</p>

				<div className="pies">
					<div>
						<div>
							<canvas className="pie" ref={this.funRef} width="64" height="64"></canvas><br />
							Kulhetsfaktor <br /> <span>{formatPercent(p.pies.fun)}</span>
						</div>
						<div>
							<canvas className="pie" ref={this.hardRef} width="64" height="64"></canvas><br />
							Svårighetsgrad <br /> <span>{formatPercent(p.pies.hard)}</span>
						</div>
						<div>
							<canvas className="pie" ref={this.timeRef} width="64" height="64"></canvas><br />
							Tid för planering <br /> <span>{formatPercent(p.pies.time)}</span>
						</div>
					</div>
				</div>
				<button onClick={() => this.showLessonPlan(p.lpId)}>Lektionsplanering</button>
			</div>
		);
	}
	showLessonPlan = lpId => {
		// find plan, then dispatch event
		let plan = this.props.plans.find(p => p.key === lpId);
		if( plan ) {
			this.props.dispatch(FACTIONS.viewLessonPlan(plan));
			// console.log('InfoBox.showLessonPlan', plan);
		} else {
			console.log('InfoBox.showLessonPlan: could not find plan with key='+lpId);
		}
	}
	drawPieChart(pie, percent, fg='#093240', bg='#fff') {
		let ct = pie.getContext('2d');
		let cx = pie.width/2-2, cy = pie.height/2-2;
		let startangle = -Math.PI/2, endangle = Math.PI*3/2,
		endangle2 = startangle + 2*Math.PI*(1-percent);
		ct.beginPath();
		ct.moveTo(cx+1, cy+1);
		ct.fillStyle = bg;
		ct.arc(cx+1, cy+1, Math.min(cx, cy), startangle, endangle, true);
		ct.fill();
		ct.closePath();

		// Contour
		ct.beginPath();
		ct.moveTo(cx+1, cy+1);
		ct.drawStyle = bg;
		ct.arc(cx+1, cy+1, Math.min(cx, cy), startangle, endangle, true);
		ct.stroke();
		ct.closePath();

		// Fill
		ct.beginPath();
		ct.fillStyle = fg;
		ct.arc(cx+1, cy+1, Math.min(cx, cy), startangle, endangle2, true);
		ct.lineTo(cx, cy);
		ct.closePath();
		ct.fill();
	}
	componentDidMount() {
		this.componentDidUpdate();
	}
	componentDidUpdate() {
		// The info box has been rendered, now we are able to draw on the canvases
		// Abort if info box is not rendered
		if( !this.funRef.current ) return;

		const pies = this.props.place.pies;
		//console.log('InfoBox.componentDidUpdate drawing pies', pies);
		this.drawPieChart(this.funRef.current, pies.fun);
		this.drawPieChart(this.hardRef.current, pies.hard);
		this.drawPieChart(this.timeRef.current, pies.time);
		//console.log('InfoBox.componentDidUpdate pies done?');
	}
}

const mapStateToProps = ({ map, theme, lessonPlans }) => ({
	imageFolder: theme.imageFolder,
	visible: map.info.visible,
	place: map.info.place,
	plans: lessonPlans.plans
})
export default connect(mapStateToProps)(InfoBox);
