import React, { Component, Fragment } from 'react';
//import { connect } from 'react-redux';
//import ACTIONS from '../../actions/formActions.js';


// Props: liftSchools, schools
class MySchools extends Component {
	state = {
		newSchoolName: '',
		newSchoolAddress: '',
		isValid: false
	}
	schoolAlreadyInList = ({name, address}) => this.props.schools.find( s => s.name === name && s.address === address )

	canAdd = newSchool => newSchool.name && newSchool.address && !this.schoolAlreadyInList(newSchool)

	addSchool = event => {
		let newSchool = { name: this.state.newSchoolName, address: this.state.newSchoolAddress };
		if( this.canAdd(newSchool) ) {
			//this.props.dispatch(ACTIONS.setSchools([...this.props.schools, newSchool]));
			this.props.liftSchools([...this.props.schools, newSchool]);
			this.setState({ isValid: false });
		}
	}
	removeSchool = ({ name, address }) => {
		//this.props.dispatch(ACTIONS.setSchools(this.props.schools.filter( s => s.name !== name || s.address !== address )));
		this.props.liftSchools(this.props.schools.filter( s => s.name !== name || s.address !== address ));
	}
	componentDidUpdate() {
		this.validate();
	}
	validate = () => {
		const validProp = this.canAdd({ name: this.state.newSchoolName, address: this.state.newSchoolAddress });
		if( this.state.isValid && !validProp )
			this.setState({ isValid: false });
		else if( !this.state.isValid && validProp )
			this.setState({ isValid: true });
	}
	render() {
		const renderedSchools = this.props.schools.map(s => (
			<li key={s.name + s.address}> {s.name} ({s.address})
				<button onClick={() => this.removeSchool(s)}>X</button>
			</li>
		))
		//console.log('Forms.MySchools.render: ', renderedSchools);
		return (
			<Fragment>
				<h3>Skolor du arbetar på</h3>
				<input type="text"
					placeholder="Skola"
					value={this.state.newSchoolName}
				 	onChange={e => this.setState({newSchoolName: e.target.value}, this.validate)} />
				<br />
				<input type="text"
					className="large"
					placeholder="Gatuadress, Postnummer Postort" title="Vi använder adressfältet för att placera ut skolan med google maps."
					onChange={e => this.setState({newSchoolAddress: e.target.value}, this.validate)}
					value={this.state.newSchoolAddress} />
				<button
					onClick={this.addSchool}
					disabled={!this.state.isValid}>Lägg till skola</button>
				<ul> {renderedSchools} </ul>
			</Fragment>
		)
	}
}

/*const mapStateToProps = ({ formData }) => ({
	//schools: formData.workedSchools
})
export default connect(mapStateToProps)(MySchools);*/
export default MySchools;
