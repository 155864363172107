import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import { rootReducer } from './reducers/rootReducer.js';
import { loadLocalState, saveLocalState } from './utils/localStorage'

import AppLogin from './components/AppLogin.js';
import AppRouter from './components/AppRouter.js';

const persistedState = loadLocalState();

const store = createStore(
	rootReducer,
	persistedState,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// Keep track of form changes to save them to localStorage
store.subscribe(() => {
	let state = store.getState();
	if( state.user.isLoggedIn ) {
		// Only save if the user is logged in
		saveLocalState({
			formData: state.formData
		});
	}
});

// Wrap the App component in a functional component, enabling Redux and React Router
// Use the URL /theme/namn=gymnasium to change theme to high school
const Root = ({ store }) => (
	<Provider store={store}>
		<Router>
			<Switch>
				<Route path="/" exact component={AppLogin} />
				<Route path="/*/" component={AppRouter} />
			</Switch>
		</Router>
	</Provider>
);
//<Route path="/:filter?" component={App} />
//<Route path="/invite" component={Invite} />

ReactDOM.render(<Root store={store} />, document.getElementById('root'));
registerServiceWorker();
