import React, { Component, Fragment } from 'react';
//import { connect } from 'react-redux';
//import ACTIONS from '../../actions/formActions.js';

class MySubjects extends Component {
	state = {
		selectedSubject: 'Matematik'
	}
	canAdd = () => !this.props.subjects.find( s => s === this.state.selectedSubject )

	addSubject = value => {
		if( this.canAdd() )
			//this.props.dispatch(ACTIONS.setSubjects([...this.props.subjects, value]));
			this.props.liftSubjects([...this.props.subjects, value]);
	}
	removeSubject = subject => this.props.liftSubjects(this.props.subjects.filter( s => s !== subject ))

	render() {
		const subs = this.props.subjects.map( s => (
			<li key={s}>
				{s}
				<button
					onClick={() => this.removeSubject(s)}>X</button>
			</li>
		));
		return (
			<Fragment>
				<h3>Dina ämnen</h3>
				<select defaultValue="Matematik" onChange={e => this.setState({ selectedSubject: e.target.value })}>
					<option value="Matematik"> Matematik </option>
					<option value="Teknik"> Teknik </option>
					<option value="Övrigt"> Övrigt </option>
				</select>
				<button onClick={e => this.addSubject(this.state.selectedSubject)}
					disabled={!this.canAdd()}>Lägg till ämne</button>
				<ul> {subs} </ul>
			</Fragment>
		)
	}
}
/*const mapStateToProps = ({ formData }) => ({
	subjects: formData.subjects
})

export default connect(mapStateToProps)(MySubjects);*/
export default MySubjects;
