import ACTIONS from '../actions/constants.js';
import { VERSION } from '../constants.js';

const initialState = {
	version: VERSION.GYMNASIUM,
	imageFolder: 'gymnasium/'
}


const themeReducer = (state = initialState, action) => {
	switch( action.type ) {
		case ACTIONS.CHANGEVERSION:
			return {
				version: action.version,
				imageFolder: action.imageFolder
			}

		case ACTIONS.USER_SIGNIN:
			//console.log('themeReducer, user sign in: ', action);
			return {
				version: action.version,
				imageFolder: (action.version === VERSION.GYMNASIUM ? 'gymnasium/' : 'skola/')
			}

		default:
			return state;
	}
}


export { themeReducer };
