import ACTIONS from './constants.js';

const startsFetchingActivities = () => ({
	type: ACTIONS.STARTSFETCHINGACTIVITIES
})
const gotActivities = acts => ({
	type: ACTIONS.GOTACTIVITIES,
	acts
})

const actions = {
	startsFetchingActivities,
	gotActivities
}

export default actions;
