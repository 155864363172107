import ACTIONS from '../actions/constants.js';

const initialState = {
	mapIsLoading: true,
	mapLoadSuccess: false,
	doInit: false,
	info: {
		visible: false,
		place: {  // sample data, all this will be replaced
			lpId: 'lp1',
			position: { lat: 62, lng: 15 },
			dateDone: '2018-07-10',
			school: 'Zocom test',
			title: 'Digital programmering: "Introduktion till Lightbot"',
			time: '45 minuter',
			target: 'F-klass och uppåt',
			groupsize: 'Halvklass med fördel, men går med helklass',
			place: 'Klassrum / datorsal',
			equipment: 'Dator / surfplatta / smartphone',
			description: 'Här kommer du utmana barnen i logiskt tänkande. Programmet vi använder är Lightbot Jr som riktar sig till äldre barn.',
			tags: ['lightbot jr', 'dator', 'surfplatta', 'smartphone'],
			pies: { fun: 0.9, hard: 0.6, time: 0.3 }
		}
	}
}

const mapReducer = (state = initialState, action) => {
	switch( action.type ) {
		case ACTIONS.MAP_FAILED:
			return { ...state, mapIsLoading: false, mapLoadSuccess: false, doInit: false };

		case ACTIONS.MAP_LOADED:
			return { ...state, mapIsLoading: false, mapLoadSuccess: true, doInit: false };

		case ACTIONS.MAP_LOADING:
			return { ...state, mapIsLoading: true, mapLoadSuccess: false, doInit: false };

		case ACTIONS.MAP_INIT:
			return { ...state, mapIsLoading: true, mapLoadSuccess: false, doInit: true };

		case ACTIONS.MAP_SHOW_INFOBOX:
			return {
				...state,
				info: { visible: true, place: action.place }
			}
		case ACTIONS.MAP_HIDE_INFOBOX:
			return {
				...state,
				info: { ...state.info, visible: false }
			}

		default:
			return state;
	}
}

export { mapReducer };
