import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../style/Admin.css';
import MemberDetails from './MemberDetails';


class BasicStats extends Component {
	state = {
		selectedGroupId: null,  // id
		members: null,  // [name, id]
		selectedMember: null,  // obj
		cachedUserPlans: {},  // object ->  userId: LP[]
		selectedMemberPlans: null
	}

	membersToUsers = members => {
		//let members = this.props.groups[this.state.selectedGroupId].members;
		let users = [];
		for( let key in members ) {
			let u = this.props.allUsers.find(
				u => u.email === members[key].email
			);
			if( u ) {
				u.confirmed = true;  // special fix for Vaxholm 2018
			} else {
				// console.log('BasicStats.membersToUsers: user not registered,', members[key].email);
				u = { ...members[key], confirmed: false };
			}
			u.id = key;
			users.push(u);
		}
		return users;
	}
	selectGroup = event => {
		let groupId = event.target.value;
		let group = this.props.groups.find(g => g.id === groupId);

		this.setState({
			selectedGroupId: groupId,
			members: group ? this.membersToUsers(group.members) : null,
			selectedMember: null,
			selectedMemberPlans: null
		});
	}
	clickUserLink = (event, user) => {
		this.setState({
			selectedMember: user,
			selectedMemberPlans: this.state.cachedUserPlans[user.id]
		});
		event.preventDefault();
	}
	render() {
		// console.log('BasicStats render, selected member plans:', this.state.selectedMemberPlans);
		const options = this.props.groups
			? (
				this.props.groups.map(g => (
					<option
						value={g.id}
						key={g.id}> {g.name} </option>
				))
			)
			: (<option disabled>Inga grupper i databasen</option>);

		// console.log('MEMBERS:', this.state.members);
		// console.log('SELECTED MEMBER: ', this.state.selectedMember);
		const yesNoDiv = value => value
			? ( <div className="yes">Ja</div> )
			: ( <div className="no">Nej</div> );
		const valueDiv = amount => amount < 1
			? (<div className="no">Inga</div>)
			: (<div className="yes">{amount} st.</div>);
		let userRows = [];
		let selectedMemberId = this.state.selectedMember ? this.state.selectedMember.id : null;
		let selectedMemberEmail = this.state.selectedMember ? this.state.selectedMember.email : null;
		let stats = { count: 0, registered: 0, form1: 0, form2: 0, lp: 0, act: 0 };

		if( this.state.members ) {
			// // Enable this if there are duplicates in any group. The user id is a firebase key in /groups/ that you can search on. This happened with Järfälla 2019-04.
			// const dupCheck = {};
			// this.state.members.forEach(m => {
			// 	let x = dupCheck[m.id] ? dupCheck[m.id].times : 0;
			// 	dupCheck[m.id] = { member: m, times: x + 1 }
			// })
			// for(let x in dupCheck) {
			// 	if( dupCheck[x].times > 1 )
			// 	console.log('Duplicate id or email for user:', dupCheck[x]);
			// }
			userRows = this.state.members.map(
				m => {
					let key = m.id;
					let isSelected = key === selectedMemberId || m.email === selectedMemberEmail;

					// console.log('BasicStats, letar efter userId='+m.id+' i alla LPs');
					// Fel ID används i LP. Vi försöker matcha teacherName, som bör vara e-post. Fungerar för nyare LP.
					let userLessonPlans = this.props.plans ? this.props.plans.filter( p => p.userId === m.id || p.key === m.id || p.teacherName === m.email ) : [];
					let userActivities = this.props.acts ? this.props.acts.filter( a => a.userId === m.id || a.key === m.id || a.teacherName === m.email ) : [];
					stats.count++;
					if( m.confirmed ) stats.registered++;
					if( m.form1 ) stats.form1++;
					if( m.form2 ) stats.form2++;
					stats.lp += userLessonPlans.length;
					stats.act += userActivities.length;
					// if( !m.id ) {
					// 	console.log('BasicStats.render: user has no ID', m);
					// }
					return (
						<div key={m.id || m.email} className={isSelected ? 'selectedRow' : ''}>
							<div className="clickDiv"
								onClick={e => this.clickUserLink(e, m)}>
								{m.displayName || m.email}
							</div>
							{yesNoDiv(m.confirmed)}
							{yesNoDiv(m.form1)}
							{yesNoDiv(m.form2)}
							{valueDiv(userLessonPlans.length)}
							{valueDiv(userActivities.length)}
						</div>
					);
				}
			);  // map members to userRows
		}  // if members exist
		const userRowStats = (
			<div>
				<div>{stats.count}</div>
				<div>{stats.registered}</div>
				<div>{stats.form1}</div>
				<div>{stats.form2}</div>
				<div>{stats.lp}</div>
				<div>{stats.act}</div>
			</div>
		)

		return (
			<section>
				<h3>Välj grupp</h3>
				<select defaultValue="-" onChange={this.selectGroup}
					selected={this.state.selectedGroupId}>
					<option value="-" disabled>- Välj en grupp -</option>
					{options}
				</select>
				<h3>Medlemmar {this.state.members ? `(${Object.keys(this.state.members).length} st)` : '(inga)'}</h3>
				<div className="userTable">
					<div className="tableHeader">
						<div>Namn</div>
						<div>Registrerad</div>
						<div>Form 1</div>
						<div>Form 2</div>
						<div>Lektionsplaneringar</div>
						<div>Aktiviteter</div>
					</div>
					{userRows}
					{userRowStats}
				</div>

				<MemberDetails user={this.state.selectedMember}
				 	plans={this.state.selectedMemberPlans}
					acts={this.props.acts}
					liftCache={this.saveUserPlans} />

			</section>
		)
	} // render
	saveUserPlans = plans => {
		// console.log('BasicStats saveUserPlans', this, plans);
		let obj = {...this.state.cachedUserPlans };
		obj[this.state.selectedMember.id] = plans;
		this.setState({ cachedUserPlans: obj, selectedMemberPlans: plans });
	}
}


let mapStateToProps = ({ lessonPlans, activity, groups, user }) => ({
	plans: lessonPlans.plans,
	acts: activity.acts,
	groups: groups.groups,
	allUsers: user.allUsers
})
export default connect(mapStateToProps)(BasicStats);
