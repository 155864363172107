import ACTIONS from '../actions/constants.js';

const initialState = {
	// data from authentication
	isAdmin: false,   // determined when logged in
	adminType: '',  // '', 'basic'
	isLoggedIn: false,
	userId: '',
	displayName: 'Namn saknas',
	email: '', //'lejonmanen@gmail.com',
	password: '',
	attemptSignIn: false,
	attemptRegister: false,
	allUsers: null
};
const userReducer = (state = initialState, action) => {
	switch( action.type ) {
		/*case ACTIONS.USER_ATTEMPTREGISTER:
			return {
				...state,
				attemptRegister: true,
				email: action.email,
				password: action.password
			}*/

		case ACTIONS.USER_ATTEMPTSIGNIN:
			return { ...state, attemptSignIn: true }

		case ACTIONS.USER_SIGNIN:
			// console.log('userReducer: ACTIONS.USER_SIGNIN action=', action);
			return {
				...state,
				isLoggedIn: true,
				userId: action.userId,
				displayName: action.displayName,
				email: action.email,
				attemptSignIn: false,
				adminType: action.adminType,
				isAdmin: action.isAdmin
			}

		case ACTIONS.USER_SIGNOUT:
			return { ...state, isLoggedIn: false, isAdmin: false }

		case ACTIONS.USER_SETADMIN:
			return { ...state, isAdmin: action.isAdmin, adminType: action.adminType }

		case ACTIONS.GOTUSERS:
			return { ...state, allUsers: action.users }

		// case ACTIONS.USER_LOCALSTORAGE:
		// 	console.log('userReducer USER_LOCALSTORAGE action=', action);
		// 	return {
		// 		...state,
		// 		userId: action.userId,
		// 		displayName: action.displayName,
		// 		email: action.email
		// 	 }

		case ACTIONS.USER_AUTHORIZED:
			 return {
				 ...state,
				 isLoggedIn: action.isLoggedIn
			 }

		default:
			return state;
	}
}



export { userReducer };
