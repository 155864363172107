import ACTIONS from '../actions/constants.js';

const initialState = {
	disableLoginButton: false,
	isLoggingIn: false
};

const uiReducer = (state = initialState, action) => {
	switch ( action.type ) {
		case ACTIONS.UI_LOGINDISABLED:
			return { ...state, disableLoginButton: true, isLoggingIn: false };
		case ACTIONS.UI_LOGINENABLED:
			return { ...state, disableLoginButton: false, isLoggingIn: false };
		case ACTIONS.UI_LOGGINGIN:
			return { ...state, disableLoginButton: true, isLoggingIn: true };

		default:
			return state;
	}
}

export { uiReducer };
