import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './style/App.css';

import PageSummary from './PageSummary.js';
import PageIntroText from './PageIntroText.js';
import { HeaderImg, HeaderMenu, DesktopMenu, Footer } from './Helpers.js';
import Lightbox from './Lightbox.js';
import TabView from './tabs/TabView.js';
import AdminView from './admin/AdminView.js';

import FirebaseMethods from '../utils/firebase.js';
import LPACTIONS from '../actions/lessonPlanActions.js';
import MAPACTIONS from '../actions/mapActions.js';
import FORMACTIONS from '../actions/formActions.js';
import NAVACTIONS from '../actions/navigationActions.js';
import THEMEACTIONS from '../actions/themeActions.js';
import GROUPACTIONS from '../actions/groupsActions.js';
import ACTACTIONS from '../actions/activityActions.js';
import UACTIONS from '../actions/userActions.js';

import { VERSION } from '../constants.js';



class App extends Component {
	keepLoadingMap = true
	componentDidMount() {
		this.initializeFirebase();
		// Sverigekartan är avstängd tills vidare
		this.initializeGoogleMaps();
	}
	initializeFirebase() {
		let fb = FirebaseMethods;
		fb.initialize();
		this.props.dispatch(LPACTIONS.startsFetchingLessonPlans());
		this.props.dispatch(GROUPACTIONS.startsFetchingGroups());
		this.props.dispatch(ACTACTIONS.startsFetchingActivities());
		// this.props.dispatch(UACTIONS.fetchUsersFromDb());



		// Dev mode, should always be logged in if App is being rendered
		if( !this.props.isLoggedIn ) return;
		let uid = this.props.userId;

		fb.getAllUsers(
			users => this.props.dispatch(UACTIONS.gotUsersFromDb(users))
		);
		// fb.getAllForm4(data => {
		// 	this.props.dispatch(FORMACTIONS.allform4(data))
		// });
		fb.getActivities(data => {
			this.props.dispatch(FORMACTIONS.allform4(data));
		})
		fb.getForm1(uid, data => {
			// console.log('App.initializeFirebase getForm1 SUCCESS', data);
			this.props.dispatch(FORMACTIONS.gotForm1(data))
		});
		fb.getForm2(uid, data => {
			// console.log('App.initializeFirebase getForm2 SUCCESS', data);
			this.props.dispatch(FORMACTIONS.gotForm2(data))
		});
		fb.getLessonPlansFor(null, ps => this.props.dispatch(LPACTIONS.gotLessonPlans(ps)));
		fb.getActivities(acts => this.props.dispatch(ACTACTIONS.gotActivities(acts)));

		fb.loadGroups( groups => this.props.dispatch(GROUPACTIONS.gotGroups(groups)) );
	}
	initializeGoogleMaps() {
		// console.log('Google Maps enabled');
		let checkWhenMapDoneLoading = count => {
			if( !this.keepLoadingMap ) return;
			if( window.mapLoaded ) {
				this.props.dispatch(MAPACTIONS.mapDoInit());
			} else if( count < 10 ){
				setTimeout(() => checkWhenMapDoneLoading(count + 1), 1000);
			} else {
				this.props.dispatch(MAPACTIONS.mapFailedToLoad());
			}
		}
		checkWhenMapDoneLoading(0);
	}
	componentWillUnmount() {
		this.keepLoadingMap = false;
	}

	renderAdminApp = () => {
		const leaveAdmin = () => this.props.dispatch( NAVACTIONS.leaveAdmin() );
		return (
			<div className="App">
				<button className="adminButton" onClick={leaveAdmin}>Lämna admin</button>
				<HeaderImg/>
				<HeaderMenu/>

				<div className="content">
					<DesktopMenu/>

					<main> <AdminView /> </main>
				</div>

				<Footer />
			</div>
		)
	}
	render() {
		if( this.props.showAdmin ) {
			return this.renderAdminApp();
		}
		const toAdmin = () => this.props.dispatch( NAVACTIONS.toAdmin() );
		const adminButton = this.props.isAdmin
			? ( <button className="adminButton" onClick={toAdmin}>Admin</button> )
			: null;
		const toggleTheme = () => this.props.version === VERSION.GRUNDSKOLA
			? this.props.dispatch(THEMEACTIONS.changeThemeToHighSchool())
			: this.props.dispatch(THEMEACTIONS.changeThemeToSchool());
		const themeToggleButton = this.props.isAdmin
			? ( <button className="themeToggleButton" onClick={toggleTheme}>Byt version</button> )
			: null;

		return (
			<div className="App">
				{adminButton}  {themeToggleButton}
				<Lightbox/>
				<HeaderImg/>
				<HeaderMenu/>

				<div className="content">
					<DesktopMenu/>

					<main>
						{/* some green buttons scroll here */}
						<div className="scrolltop">
							{/* Left area has changing content, right area has a more or less static sidebar */}
							<div className="columns">
								<PageIntroText />

								<PageSummary />
							</div>

							<TabView />
						</div>
					</main>
				</div>

				<Footer />
			</div>
		);
	}
}


const mapStateToProps = ({ navigation, theme, video, user, formData }) => ({
	selectedPage: navigation.selectedPage,
	selectedTab: navigation.selectedTab,
	theme: theme,
	showVideo: video.playerVisible,
	isAdmin: user.isAdmin,
	attemptToLogin: user.attemptSignIn,
	isLoggedIn: user.isLoggedIn,
	userId: user.userId,
	showAdmin: navigation.showAdmin,
	version: theme.version,
	forms: formData.allForms
})
export default connect(mapStateToProps)(withRouter(App));
