import React, { Component } from 'react';
import { connect } from 'react-redux';

import ACTIONS from '../actions/userActions.js';
import UIACTIONS from '../actions/uiActions.js';
import FirebaseMethods from '../utils/firebase.js';
// import AuthenticateButton from './AuthenticateButton.js';
import { VERSION } from '../constants.js';
import { assignKey } from '../utils/localStorage';
import firebase from 'firebase/app';
import 'firebase/auth';


class AppLogin extends Component {

	state = {
		email: '',
		password: '',
		accept: false,
		passwordResetSent: false,
		error: ['Fyll i din e-postadress och ett lösenord.', 'Du måste samtycka till att vi behandlar dina personuppgifter.']
	}

	sendPasswordReset = () => {
		FirebaseMethods.sendPasswordReset(this.state.email)
			.then(() => {
				this.setState({ passwordResetSent: true })
			})
	}

	render() {
		const errors = this.state.error.map(x => ( <div key={x}>{x}</div> ));
		// Google and GitHub authentication is disabled
		const thirdPartyAuth = null;
		/* (
			<Fragment>
			<p>
				Om du har ett Google- eller GitHub-konto så kan du logga in med det i stället för att fylla i e-post och lösenord.
			</p>
			<div className="indent">
				<AuthenticateButton accept={this.state.accept} />
			</div>
			</Fragment>
		); */
		const forgetPasswordButton = (
			<button className="reset-password" disabled={this.state.email ? false: true } onClick={this.sendPasswordReset}> Återställ lösenord</button>
		)
		const disableLoginButton = this.state.error.length > 0 || this.props.disableLoginButton;
		let buttonText = 'Logga in';
		if( this.props.isLoggingIn ) buttonText = 'Loggar in, ett ögonblick...';
		else if( this.props.disableLoginButton ) buttonText = 'Fyll i alla fält';
		return (
			<div>
			<div className="loginform form">
				<div>
					<img className="logo" src={'img/advania_logo.png'} alt="Advania logo" />
				</div>

				<div>
					<h2>Inloggning till</h2>
					<h1>UTBILDNINGEN</h1>
					<div>
						<input className="large" type="text" placeholder="E-post" name="email"
							value={this.state.email}
							onChange={e => this.update('email', e.target.value)} />
						<input className="large" type="password" placeholder="Lösenord" name="password"
							value={this.state.password}
							onChange={e => this.update('password', e.target.value)} />

						<p> Obs: första gången du loggar in ska du välja ett lösenord. </p>
						<p>
							<label>
							<input type="checkbox" value={this.state.accept}
								onChange={e => this.update('accept', e.target.checked)} />
							Jag samtycker till att Advania behandlar uppgifter om mig.
							</label>
						</p>
						{thirdPartyAuth}

						<h3 className="indent">Vad används denna data till?</h3>
						<p className="disclaimer">
						När du blir en del av Advanias utbildningsportal kommer du, om du vill, att vara med och dela din på kursen framtagna lektionsplanering med andra. Den personliga data som finns i registreringsformuläret är information för att vi ska kunna skräddarsy utbildningen. Registreringsformulärets uppgifter om dig tas automatiskt bort ett år efter du slutfört utbildningen. För mer information kontakta <a href="mailto:johan.lindstrom@advania.se">johan.lindstrom@advania.se</a>
						</p>
						<div className="error">{errors}</div>
						<button disabled={disableLoginButton} onClick={this.submit}>{buttonText}</button>
						{ this.state.passwordResetSent
							? <p><strong>Ett mail med information om återställning av lösenord har skickats till den email som du angav.</strong></p>
							: <p><em>Om du har glömt bort ditt lösenord så fyll i lösenordsfältet ovan och klicka sedan på 'Återställ Lösenord'</em></p>
						}
						{forgetPasswordButton}
					</div>
				</div>

			</div>
			</div>
		)
	}

	fetchUser(user) {
		return firebase.database().ref(`users/${user.uid}`).once('value');
	  }

	componentDidMount() {
		this.validate();
		FirebaseMethods.initialize();

		// Checks if the user is authenticated with Firebase Auth
		// this will dispatch a USER_SIGNIN action
		firebase.auth().onAuthStateChanged((user) => {
			const fb = FirebaseMethods;
			if (user) {
				this.fetchUser(user)
				.then(fetchedUser => {
					const userWithKey = assignKey(fetchedUser);
					this.authEmailSuccess(
						userWithKey.key, userWithKey,
						userWithKey.email, fb
					);
				});
			}
		});
	}
	update = (prop, value) => {
		let obj = {};
		obj[prop] = value;
		this.setState(obj, this.validate);
	}
	validate = () => {
		// email password checkbox
		let errors = [];
		let setLogin = () => this.state.error.length > 0
			? this.props.dispatch(UIACTIONS.disableLoginButton())
			: this.props.dispatch(UIACTIONS.enableLoginButton());

		if( !this.state.email || this.state.email.indexOf('@') < 0 )
			errors.push('Ange en giltig e-postadress.');
		if( this.state.password.length < 5 )
			errors.push('Du behöver fler tecken i ditt lösenord.');
		if( !this.state.accept )
			errors.push('Du måste samtycka till att vi behandlar dina personuppgifter.');
		if( this.state.error.length !== errors.length )
			this.setState({ error: errors }, setLogin);

	}

	submit = e => {
		// console.log('AppLogin.submit 1');
		this.props.dispatch(UIACTIONS.loginInProgress());
		// Reversing this is done in the success and fail methods respectively

		const email = this.state.email.toLowerCase();
		const fb = FirebaseMethods;
		fb.authUserEmail(email, this.state.password,
			(userId, userData) => {
				this.authEmailSuccess(userId, userData, email, fb);
			},
			this.authEmailFail
		);
	}
	setAddressBarUrl = () => {
		this.props.history.push(this.props.url);
	}
	authEmailFail = ({code, message}) => {
		if( code === 'auth/email-already-in-use' ) {
			this.setState({ error: ['E-postadressen används redan! Har du loggat in med Google eller GitHub?' ] });
		}
		else if( code === 'auth/wrong-password' ) {
			this.setState({ error: ['Fel lösenord! Har du skrivit rätt?' ] });
		}
		else if( code === 'xavier/no-email-found' ) {
			this.setState({ error: ['E-postadressen finns inte registrerad på någon kurs. Kontrollera att du använder den epost som din kursledare registrerat.' ] });
		}
		else if( code === 'auth/weak-password' ) {
			this.setState({ error: ['Första gången du loggar in ska du ange ett starkt lösenord. Ditt lösenord måste innehålla minst 8 tecken. Blanda gärna stora och små bokstäver, siffror och specialtecken.' ] });
		}
		else if( code === 'auth/invalid-email' ) {
			this.setState({ error: ['Det är något fel på din e-postadress! Kontrollera stavningen.' ] });
		}
		else {
			this.setState({ error: ['Ett okänt fel har inträffat. Kontakta din kursledare.' ] });
			console.log('AppLogin.submit FAIL', message);
		}
		this.props.dispatch(UIACTIONS.disableLoginButton());
	}
	authEmailSuccess = (userId, userData, email, fb) => {
		// console.log('AppLogin.authEmailSuccess uid==', userId, userData, email);
		// userData: { displayName, email, isAdmin, version, }
		if( userData ) {
			if( !userData.hasOwnProperty('version') ) {
				userData.version = VERSION.GYMNASIUM;
				console.error('AppLogin.authEmailSuccess user has no version, set to "gymnasium", email:', email);
				console.log('There is likely an error with your user in the database. You can still use Xavier! But please forward this message to your course leader.');
			}
			// console.log('AppLogin.authEmailSuccess ALL user data, id=', userId, userData);
			this.props.dispatch(ACTIONS.userSignIn({
				userId, email,
				displayName: userData.displayName || email,
				isAdmin: userData.isAdmin || false,
				adminType: userData.adminType || '',
				version: userData.version
			}));
			this.afterAuthSuccess();
		} else {
			// console.log('AppLogin.authEmailSuccess saving new user, id=', userId);
			fb.getUserGroup(email,
				group => {
					console.log('AppLogin.authEmailSuccess group == ', group);
					fb.saveUser(userId, email, group.version, group.id);
					this.props.dispatch(ACTIONS.userSignIn({
						userId, email,
						displayName: email,
						isAdmin: false,
						version: group.version
					}));
					this.afterAuthSuccess();
				},
				() => {
					console.log('AppLogin.authEmailSuccess: no group found for user ', email, userId);
				}
			);
		}
	} // authEmailSuccess
	afterAuthSuccess = () => {
		this.setAddressBarUrl();
		this.props.dispatch(UIACTIONS.enableLoginButton());
	}
}
const mapStateToProps = ({ theme, ui }) => ({
	url: '/' + theme.version,
	disableLoginButton: ui.disableLoginButton,
	isLoggingIn: ui.isLoggingIn
})
export default connect(mapStateToProps)(AppLogin);
