import { Component } from 'react';
import { connect } from 'react-redux';
import { VERSION } from '../constants.js';

import pages from '../versions/pages.js';

class PageIntroText extends Component {

	render() {
	let page = [];
	if( this.props.version === VERSION.GRUNDSKOLA ) {
		page = [pages.page1grund, pages.page2grund, pages.page3grund, pages.page4grund, pages.page5, pages.page6]
	} else if (this.props.version === VERSION.GYMNASIUM) {
		page = [pages.page1gym, pages.page2gym, pages.page3gym, pages.page4gym, pages.page5, pages.page6]
	}

	return page[this.props.selectedPage](this.props.imageFolder)
	}
}

const mapStateToProps = ({ navigation, theme }) => ({
	selectedPage: navigation.selectedPage,
	imageFolder: theme.imageFolder,
	version: theme.version
})

export default connect(mapStateToProps)(PageIntroText);
