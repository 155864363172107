import ACTIONS from '../actions/constants.js';

const initialState = {
	playerVisible: false,
	isPlaying: false,
	videoId: null
};
const videoReducer = (state = initialState, action) => {
	switch( action.type ) {
		case ACTIONS.SHOW_VIDEO:
			return { videoId: action.videoId, playerVisible: true, isPlaying: false };

		case ACTIONS.HIDE_VIDEO:
			return { ...state, playerVisible: false, isPlaying: false };

		case ACTIONS.PLAY_VIDEO:
			return { ...state, playerVisible: true, isPlaying: true };

		// case ACTIONS.PAUSE_VIDEO:
		// 	return { ...state, playerVisible: true, isPlaying: false };

		default:
			return state;
	}
}



export { videoReducer };
