const getTodayDateAsString = () => {
	let d = new Date();
	d = d.toISOString();
	return d.substring(0, 10);
}


const orderedArrayEqual = (as, bs) => {
	if( as.length !== bs.length ) return false;
	for( let i=0; i<as.length; i++ )
		if( as[i] !== bs[i] ) return false;
	return true;
}

// Used by forms 1,2,3 to simplify error validation. Probably possible to refactor further.
const validateErrors = (errors, errorsState, self) => {
	if( errors.length > 0 ) {
		errors = [
			'Du har inte fyllt i alla fält! Vi behöver mer information från dig för att andra ska kunna använda din planering.',
			...errors
		];
		// Note: calling setState in componentDidUpdate can cause infinite recursion, we avoid this by only updating if something has changed.
		if( !orderedArrayEqual(errors, errorsState) )
			self.setState({ error: errors, isValid: false });
	} else if( errorsState.length > 0 ) {
		self.setState({ error: [], isValid: true })
	}
}

export { getTodayDateAsString, orderedArrayEqual, validateErrors };
