import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { LinkNewWindow, LinkChangeTab } from './Helpers.js';
//import { Form1 } from './form/Form1.js';
import TabContent from './TabContent.js';
import TabButton from './TabButton.js';
//import ACTIONS from '../actions/navigationActions.js';
import '../style/TabView.css'


class TabView extends Component {
	render() {
		//console.log('TabView: render, tab=' + this.props.selectedTab)
		return (
			/* 1. Kursstart  */
			/* 2. Programmering */
			/* 3. Uppdraget */
			/* 4. Aktiviteten */
			/* (6). Fördjupningsresurser */
			<div className="tabview">
				{this.props.selectedPage < 5 ? <TabButtons index={this.props.selectedTab} /> : null}
				<TabContent />
			</div>
		);
	}
}
const mapStateToPropsTV = ({ navigation }) => ({
	selectedPage: navigation.selectedPage
})
TabView = connect(mapStateToPropsTV)(TabView);



class TabButtons extends Component {
	render() {
		//console.log(`TabView: TabButtons`, this.props.captions);
		return this.props.captions.map( (content, index) => (
			<TabButton key={index + content}
				index={index}
				active={index === this.props.selectedTab}
				content={content} />
		) )
	}
}
const mapStateToPropsTB = ({ navigation }) => ({
	captions: navigation.tabTitles,
	selectedTab: navigation.selectedTab
})
TabButtons = connect(mapStateToPropsTB)(TabButtons);





const mapStateToProps = state => ({
	//selectedPage: navigation.selectedPage,
	selectedTab: state.navigation.selectedTab,
})

export default connect(mapStateToProps)(TabView);
