import ACTIONS from './constants.js';

const startsFetchingLessonPlans = () => ({
	type: ACTIONS.STARTSFETCHINGLESSONPLANS
})
const gotLessonPlans = plans => ({
	type: ACTIONS.GOTLESSONPLANS,
	plans
})

const actions = {
	startsFetchingLessonPlans,
	gotLessonPlans
}

export default actions;
