import ACTIONS from './constants.js';
import { VERSION } from '../constants.js';

/*const userAttemptRegister = (email, password) => ({
	type: ACTIONS.USER_ATTEMPTREGISTER,
	email,
	password
})*/
const userAttemptSignIn = () => ({
	type: ACTIONS.USER_ATTEMPTSIGNIN
})
const userSignIn = ({ userId, displayName, email, isAdmin = false, version = VERSION.GYMNASIUM, adminType = '' }) => ({
	type: ACTIONS.USER_SIGNIN,
	userId,
	displayName,
	email,
	isAdmin,
	version,
	adminType
})
const userSignOut = () => ({
	type: ACTIONS.USER_SIGNOUT
})
const userSetAdminState = (isAdmin, adminType='') => ({
	type: ACTIONS.USER_SETADMIN,
	isAdmin,
	adminType
})
const fetchUsersFromDb = () => ({
	type: ACTIONS.STARTSFETCHINGUSERS
});
const gotUsersFromDb = users => ({
	type: ACTIONS.GOTUSERS,
	users
});
// const userFetchedFromLocalStorage = user => ({
// 	type: ACTIONS.USER_LOCALSTORAGE,
// 	user
// });

const actions = {
	//userAttemptRegister,
	userAttemptSignIn,
	userSignIn,
	userSignOut,
	userSetAdminState,
	fetchUsersFromDb,
	gotUsersFromDb
	// , userFetchedFromLocalStorage
}

export default actions;
