import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import FirebaseMethods from '../../utils/firebase.js';
import { getTodayDateAsString } from '../../utils/functions.js';
import { VERSION } from '../../constants.js';


class ManageGroup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			//createdDate: '',
			members: [], // email, registreringsstatus
			selected: null,
			selectedId: null,
			selectedVersion: VERSION.GYMNASIUM,
			allGroups: this.props.allGroups,  // TODO: actions to modify group list, instead of only reading groups here in constructor
			inputEmails: ''
		}
		FirebaseMethods.initialize();
		//FirebaseMethods.loadGroups(groups => this.setState({ allGroups: groups }));
	}

	createGroup = () => {
		const obj = {
			created: getTodayDateAsString(),
			members: {},
			name: this.state.name,
			version: this.state.selectedVersion
		};
		FirebaseMethods.createGroup(obj, id => {
			obj.id = id;
			this.setState({
				selected: obj,
				selectedId: id,
				allGroups: [...this.state.allGroups, obj]
			});
		})
	}
	addMembers = (group, members) => {
		FirebaseMethods.updateGroup(group.id, members, newMembers => {
			let newAllGroups = [...this.state.allGroups];
			const oldGroup = this.state.allGroups.find( g => g.id === group.id );

			let newGroup = {...oldGroup};
			if( oldGroup.members )
				newGroup.members = {...oldGroup.members, ...newMembers};
			else
				newGroup.members = { ...newMembers };
			newAllGroups[group.id] = newGroup;
			this.setState({
				allGroups: newAllGroups,
				selected: newGroup
			});
		})
	}
	addEmails = found => {
		this.setState({ inputEmails: '' });
		this.addMembers(this.state.selected, found.map(
			x => ({
				confirmed: false,
				email: x
			})
		));
	}
	renderSelectedGroup = group => {
		const members = this.objectToList(group.members).map( m => (
			<li key={m.email}>
				{m.email} <button onClick={() => this.removeMember(group, m)}>Ta bort</button>
			</li>
		) );
		const parsed = this.parseEmails(this.state.inputEmails);
		const fieldHasEmails = parsed.length > 0;
		const preview = fieldHasEmails ? (<p>Följande adresser kommer att läggas till: {parsed.join(', ')}</p>) : null;

		return (
			<Fragment>
				<h3>{group.name}</h3>
				<div>
					{group.version} (id: {group.id}) <br/>
					Skriv in e-postadresser här för att lägga till dem.
				</div>
				<textarea rows="4" cols="80"
					value={this.state.inputEmails}
					onChange={e => this.setState({ inputEmails: e.target.value })}></textarea>
				<button disabled={!fieldHasEmails}
					onClick={() => this.addEmails(parsed)}>Lägg till</button>
				{preview}
				<ul className="memberlist"> {members} </ul>
			</Fragment>
		)
	}
	removeMember = (group, member) => {
		const newMembers = {...group.members};
		delete newMembers[member.id];
		let newGroup = {...group, members: newMembers};
		FirebaseMethods.replaceGroup(newGroup.id, newMembers, () => {
			let newAllGroups = [...this.state.allGroups];
			newAllGroups[newGroup.id] = newGroup;
			this.setState({
				selected: newGroup, selectedId: newGroup.selectedId,
				allGroups: newAllGroups
			})
		});
	}
	objectToList = obj => {
		let list = [];
		for( let x in obj ) {
			if( x ) {
				obj[x].id = x;
				list.push(obj[x]);
			}
		}
		return list;
	}
	renderGroup = group => {
		let memberList = this.objectToList(group.members);
		let memberCount = memberList.length;
		let activeMembers = memberList.reduce(
			(acc, x) => acc + (x.confirmed ? 1 : 0), 0
		);
		return (
			<div key={group.id} className="grouplist">
				<label>
					<input type="radio"
						onChange={e => { if( e.target.checked ) this.setState({selected: group, selectedId: group.id}); }}
					 	checked={this.state.selectedId === group.id}/>
					{group.name}, {group.version}, {group.created}, {activeMembers} av {memberCount} medlemmar registrerade
				</label>
			</div>
		)
	}

	render() {
		const selected = this.state.selected === null
			? ( <h3>Ingen grupp vald</h3> )
			: this.renderSelectedGroup(this.state.selected) ;
		const groups = this.state.allGroups === null
			? <h3>Hämtar grupper...</h3>
			: (
				<Fragment>
					<h3>Alla grupper</h3>
					{this.state.allGroups.map( g => this.renderGroup(g) )}
				</Fragment>
			);
		const GRUND = VERSION.GRUNDSKOLA, GYM = VERSION.GYMNASIUM;
		const updateVersionCheckbox = (e, version) => {
			if( e.target.checked )
				this.setState({ selectedVersion: version });
		}
		const renderCheckbox = version => (
			<label>
				<input type="radio" checked={this.state.selectedVersion === version}
					onChange={e => updateVersionCheckbox(e, version) } />
				{version}
			</label>
		)
		return (
			<div className="admin">
			<section>
				<input type="text" value={this.state.name} placeholder="Namn för gruppen"
				 	onChange={e => this.setState({ name: e.target.value })} /> <br/>
				{renderCheckbox(GRUND)} {renderCheckbox(GYM)}
				<button onClick={this.createGroup}>Skapa ny grupp</button>
			</section>

			<section>
				{selected}
			</section>

			<section>
				{groups}
			</section>
			</div>
		)
	}

	parseEmails = text => {
		// Example string to parse:
		// anna@antonsson.se  antonio@bander.as,,,,;,;,;hello@world.nu  fake @ fake.no
		let emails = text.replace(',', ' ').replace(';', ' ')
		.split(/([\w!#$%&'*+\-/=?^_`{|}~.]+@[\w\-.]+)(?:(?:[,\s]+)|$)/ig);
		let found = [];
		for( let i=1; i<emails.length; i+=2 )
			found.push(emails[i].toLowerCase());
		return found;
	}
}

let mapStateToProps = ({ groups }) => ({
	allGroups: groups.groups
})
export default connect(mapStateToProps)(ManageGroup);
