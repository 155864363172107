import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import { VERSION } from '../constants.js';

// Contains methods for dealing with
// Database: forms 1-4, lesson plans and activities
// Database: users, groups
// Authentication using email, Google, GitHub
const FirebaseMethods = {
	initialize: () => {
		if (!!firebase.apps.length)
			return;  // do not initialize twice

		/*var admin = require("firebase-admin");

		var serviceAccount = require("./xavier-1c421-firebase-adminsdk-8b4qf-c2ff53fa34.json");

		admin.initializeApp({
			credential: admin.credential.cert(serviceAccount),
			databaseURL: "https://xavier-1c421.firebaseio.com"
		});
		*/

		var config = {
			apiKey: "AIzaSyAOJJBf6VvD_CLOnoeayyRDZ1DPi05bl6U",// Auth / General Use
			authDomain: "xavier-1c421.firebaseapp.com",// Auth with popup/redirect
			databaseURL: "https://xavier-1c421.firebaseio.com",
			projectId: "xavier-1c421",
			storageBucket: "xavier-1c421.appspot.com",
			messagingSenderId: "785863078402"// Cloud Messaging
		};
		firebase.initializeApp(config);
		//const auth = firebase.auth();

		/*
		// As an admin, the app has access to read and write all data, regardless of Security Rules
		const db = firebase.database();
		let ref = db.ref("/");
		console.log('Firebase.js, initialize database')
		ref.once("value").then(function(snapshot) {
			console.log('Firebase.js, stuff returned', snapshot.val());
		});
		console.log('Firebase.js, subscribed?')
		*/
	},


	// Forms
	submitForm1: (userId, data, onComplete) => FirebaseMethods.submitForm(userId, 1, data, onComplete),
	submitForm2: (userId, data, onComplete) => FirebaseMethods.submitForm(userId, 2, data, onComplete),
	submitForm3: (userId, data, onComplete) => FirebaseMethods.submitForm(userId, 3, data, onComplete),
	submitForm4: (userId, data, onComplete) => FirebaseMethods.submitForm(userId, 4, data, onComplete),
	submitForm: (userId, form, formData, onComplete) => {
		console.log(`FIREBASE submitForm${form} userId=`, userId);
		if (!userId) return;
		firebase.database().ref(`/users/${userId}/form${form}/`)
			.set(formData, onComplete)
			.then(() => {
				//console.log(`FIREBASE submitForm${form} success`)
			})
			.catch(error => console.log(`FIREBASE submitForm${form} error:`, error));
		if (form === 1) {
			firebase
				.database()
				.ref(`/users/${userId}/displayName`)
				.set(`${formData.firstName} ${formData.lastName}`)
		}
	},
	getForm1: (userId, onSuccess) => FirebaseMethods.getForm(userId, 1, onSuccess),
	getForm2: (userId, onSuccess) => FirebaseMethods.getForm(userId, 2, onSuccess),
	getForm3: (userId, onSuccess) => FirebaseMethods.getForm(userId, 3, onSuccess),
	getForm4: (userId, onSuccess) => FirebaseMethods.getForm(userId, 4, onSuccess),
	getForm: (userId, form, onSuccess) => {
		if (!userId) return;
		firebase.database().ref(`/users/${userId}/form${form}/`).once('value',
			() => {/*console.log(`FIREBASE getForm${form} onComplete`)*/ },
			error => console.log(`FIREBASE getForm${form} error:`, error)
		)
			.then(snapshot => {
				let data = snapshot.val();
				if (data) {
					// console.log(`FIREBASE getForm${form} success`, data);
					onSuccess(data);
				} else {
					// console.log(`FIREBASE getForm${form} - ingen data i formulär`);
				}
			})
			.catch(error => console.log(`FIREBASE getForm${form} error:`, error));
	},


	// Users and groups
	saveUser: (userId, email, version, groupId) => {
		// Should only be called when a new user is being registered
		// console.log('firebase saveUser ', userId, email, version, groupId);
		if (!version)
			console.error('firebase.js saveUser: version not set, uses "grundskola"', email);
		firebase.database().ref('users/' + userId).set({
			displayName: email,
			email,
			isAdmin: false,
			version: version || VERSION.GRUNDSKOLA,
			groupId
		});
		FirebaseMethods.confirmUser(email, groupId);
	},
	confirmUser: (email, groupId) => {
		// Should only be called by method saveUser. This method should not fail.
		// console.log('firebase confirmUser ', email, groupId);
		const membersPath = '/groups/' + groupId + '/members/';
		firebase.database().ref(membersPath).once('value').then(
			members => {
				members.forEach(m => {
					let data = m.val();
					if (data.email === email) {
						// console.log('firebase confirmUser -- found!', email, data, m.key);
						firebase.database().ref(membersPath + m.key).set({
							...data,
							id: m.key,
							confirmed: true
						})
						return true;  // end iteration
					}
				})
			}
		)
	},
	getUser: (userId, callback) => {
		//console.log('GETUSER ',userId);
		firebase.database().ref('/users/' + userId).once('value').then(
			snapshot => {
				//console.log('GETUSER found', snapshot.val());
				callback(snapshot.val());
			}
		)
	},
	getUserIdByEmail: (email, onSuccess, onFailure) => {
		// onSuccess: (userId, userData)
		firebase.database().ref('/users/').orderByChild('email').equalTo(email).limitToFirst(1).once('value')
			.then(snapshot => {
				let data = snapshot.val();
				// If there is no user with that email address, snapshot.val() returns null
				if (data) {
					let userId = Object.keys(data)[0];
					data[userId].id = userId;
					// console.log('firebase getUserIdByEmail found:', userId, data[userId]);
					onSuccess(userId, data[userId]);
				} else {
					// console.log('getUserIdByEmail - no user in /users/, look in /groups/');
					FirebaseMethods.getUserByEmailFromGroup(email,
						(key, userData) => onSuccess(key, userData),
						() => onFailure({ code: 'xavier/no-email-found', message: '' })
					);
				}
			})
			.catch(onFailure);
	},
	getUserIdByDisplayName: (name, onSuccess, onFailure) => {
		// Only works on returning users
		// onSuccess: (userId, userData)
		firebase.database().ref('/users/').orderByChild('displayName').equalTo(name).limitToFirst(1).once('value')
			.then(snapshot => {
				let data = snapshot.val();
				let userId = Object.keys(data)[0];
				data[userId].id = userId;
				// console.log('firebase getUserIdByEmail found:', userId, data[userId]);
				onSuccess(userId, data[userId]);
			})
			.catch(onFailure);
	},
	getUserByEmailFromGroup: (email, onSuccess, onFailure) => {
		// console.log('getUserByEmailFromGroup 1 looking for ', email);
		email = email.toLowerCase();
		const db = firebase.database();
		db.ref('/groups/').once('value')
			.then(
				allGroups => {
					// console.log('getUserByEmailFromGroup 2', allGroups.val(), allGroups.key);
					allGroups.forEach(groupSnapshot => {
						// console.log('getUserByEmailFromGroup 3', groupSnapshot.val().name);
						groupSnapshot.child('members').forEach(member => {
							let user = member.val();
							// console.log('getUserByEmailFromGroup 3b', user);
							if (user.email.toLowerCase() === email) {
								// console.log('getUserByEmailFromGroup 3c', member.key, user);
								user.version = groupSnapshot.val.version;
								user.groupId = groupSnapshot.key;
								user.isAdmin = false;
								onSuccess(member.key, user);
								return true;
							}
						})
					}); // for each group
				}
			)
			.catch(onFailure);
		onFailure();
	},
	getUserGroup: (userEmail, onSuccess, onFailure) => {
		// console.log('firebase getUserGroup, searching for group for email:', userEmail);
		firebase.database().ref('/groups/').once('value').then(
			snapshot => {
				let match = null;
				snapshot.forEach(group => {
					// console.log('firebase getUserGroup, ', group.val().name);
					let groupVal = group.val();
					for (let x in groupVal.members) {
						let member = groupVal.members[x];
						// if( groupVal.name === 'Demogrupp' )
						// 	console.log('firebase getUserGroup, member:', member);
						if (member.email.toLowerCase() === userEmail.toLowerCase()) {
							// console.log('firebase getUserGroup, found matching email for group ', group.key, groupVal);
							match = { ...groupVal, id: group.key };
							return true;  // cancels the iteration
						}
					}
				});
				if (match) {
					// console.log('firebase getUserGroup match:', match);
					onSuccess(match);
				} else {
					console.log('firebase getUserGroup could not find a group for email:', userEmail);
					onFailure();
				}
			}
		);
	},
	getUsersFromGroup: (groupId, callback) => {
		const memberPath = '/groups/' + groupId + '/members/';
		firebase.database().ref(memberPath).once('value').then(
			snapshot => {
				// console.log('firebase getUsersFromGroup 1 path=', memberPath);
				let userDataPromises = [];
				snapshot.forEach(member => {
					// console.log('firebase getUsersFromGroup 2');
					let email = member.val().email;
					userDataPromises.push(new Promise((resolve, reject) => {
						// console.log('firebase getUsersFromGroup 3');
						FirebaseMethods.getUserIdByEmail(email,
							(id, data) => resolve(data), reject)
					}));
				});
				Promise.all(userDataPromises).then(callback);
				// console.log('firebase getUsersFromGroup 4');
			}
		)
	},
	getAllUsers: callback => {
		firebase.database().ref('/users/').once('value').then(
			snapshot => {
				let users = [];
				snapshot.forEach(u => {
					let user = u.val();
					user.id = u.key;
					users.push(user);
				});
				callback(users);
			}
		)
	},
	getAllForm4: callback => {
		firebase.database().ref('/users/').once('value').then(
			snapshot => {
				let forms = [];
				snapshot.forEach(u => {
					let user = u.val();
					if(user.form4){
					forms.push(user.form4);
					}
				});
				callback(forms);
				// console.log(forms)
			}
		)
	},
	loadGroups: callback => {
		firebase.database().ref('/groups/').orderByChild('created').once('value').then(
			snapshot => {
				let groups = [];
				snapshot.forEach(g => {
					let gx = g.val();
					gx.id = g.key;  // each group includes its firebase key
					groups.push(gx);
				});
				callback(groups);
			}
		)
			.catch(error => console.log('firebase.getGroups failed:', error))
	},
	createGroup: (group, callback) => {
		let id = firebase.database().ref('/groups').push(group, () => { }).key;
		callback(id);
	},
	updateGroup: (groupId, members, callback) => {
		const membersRef = firebase.database().ref(`/groups/${groupId}/members`);
		const newMembers = [];
		for (let key in members) {
			let m = members[key];
			let id = membersRef.push(m, () => {
				newMembers.push({ ...m, id: id });
				if (newMembers.length === members.length)
					callback(newMembers);
			}).key;
		}
	},
	replaceGroup: (groupId, members, callback) => {
		const membersRef = firebase.database().ref(`/groups/${groupId}/members`);
		membersRef.set(members)
			.then(callback)
			.catch(error => console.log('firebase.replaceGroup failed:', error))
	},




	// Lesson plans
	submitLessonPlan: (formData, onComplete) => {
		console.log('FIREBASE submitLessonPlan classes,subjects ', formData.classes, formData.subjects);
		const db = firebase.database();
		let classes = typeof formData.classes === 'string' ? formData.classes : formData.classes.join(',');
		let subjects = typeof formData.subjects === 'string' ? formData.subjects : formData.subjects.join(',');
		const data = { ...formData, classes, subjects }

		// Attempt to find the id of user who made this
		FirebaseMethods.getUserIdByDisplayName(formData.userName,
			(userId, userData) => {
				data.userId = userId;
				let id = db.ref('/lessonPlans').push(data, onComplete);
				console.log('submitLessonPlan  ref.key=', id.key);
				return id.key;
			},
			error => console.log('firebase submitLessonPlan failed', error)
		)

	},

	getLessonPlansByEmail: (userEmail, callback) => {

		// dispatchData is a callback function that expects all plans for user in an array
		const db = firebase.database();
		// console.log(`FB.getLessonPlansByEmail(${userEmail}, func)`);
		db.ref('/lessonPlans/').once('value').then(snapshot => {
			//console.log('FIREBASE got lesson plans')
			const plans = [];
			snapshot.forEach(ss => {
				const data = ss.val();
				if (userEmail === data.userName)
					plans.push({
						classes: data.classes.split(','),
						content: data.content,
						dateSaved: data.dateSaved,
						equipment: data.equipment,
						estimatedTime: data.estimatedTime,
						goalAndPurpose: data.goalAndPurpose,
						grading: data.grading,
						subjects: data.subjects.split(','),
						tags: data.tags.split(','),
						title: data.title,
						userId: data.userId,
						firstName: data.firstName ? data.firstName : '',
						lastName: data.lastName ? data.lastName : '',
						teacherName: data.userName,
						key: ss.key
					});
			})
			if( plans.length < 1 ) {
				console.log('FIREBASE getLessonPlansByEmail did not get any LPs');
			}
			callback(plans);
		})
	},
	getLessonPlansFor: (uid, callback) => {
		// Set uid=null if you want all lesson plans
		// dispatchData is a callback function that expects all plans for user in an array
		const db = firebase.database();
		// console.log(`FB.getLessonPlansFor(${uid}, func)`);
		db.ref('/lessonPlans/').once('value').then(snapshot => {
			//console.log('FIREBASE got lesson plans')
			const plans = [];
			snapshot.forEach(ss => {
				const data = ss.val();
				//! This fucks up everything
				//let userId = uid ? uid : '';
				// TODO: uid is a Firebase key, data.userId seems to be a Firebase User UID (used for authentication). To fix this you need to sanitize the database. A card has been added to backlog.
				// TODO: to fix this, we need to sanitize the database.
				// console.log('FB.getLessonPlansFor', uid, data);
				// if (!uid || uid === data.userId)
				if (true)
					plans.push({
						classes: data.classes.split(','),
						content: data.content,
						dateSaved: data.dateSaved,
						equipment: data.equipment,
						estimatedTime: data.estimatedTime,
						goalAndPurpose: data.goalAndPurpose,
						grading: data.grading,
						subjects: data.subjects.split(','),
						tags: data.tags.split(','),
						title: data.title,
						userId: data.userId,
						firstName: data.firstName ? data.firstName : '',
						lastName: data.lastName ? data.lastName : '',
						teacherName: data.userName,
						key: ss.key
					});
			})
			// console.log('FIREBASE got lesson plans: ', plans);
			if( plans.length < 1 ) {
				// console.log('FIREBASE getLessonPlansFor did not get any LPs');
			}
			// console.log('FIREBASE getLessonPlansFor', uid, plans);
			callback(plans);
		})
	},


	// Activities
	submitActivity: (formData, onComplete) => {
		console.log('FIREBASE submitActivity', formData);
		const db = firebase.database();
		db.ref('/activities/').push(formData, onComplete);
	},
	getActivities: callback => {
		firebase.database().ref('/activities/').once('value').then(
			snapshot => {
				let acts = [];
				snapshot.forEach(a => {
					let data = a.val();
					data.id = a.key;
					acts.push(data);
				});
				callback(acts);
			}
		)
	},




	// Authentication
	authUserEmail: (email, password, onSuccess, onFailure) => {
		// First tries to sign in an existing user.
		// If no user exists with that email address, creates it
		// onSuccess expects two parameters: userId and userData
		const signIn = (email, password) => {
			firebase.auth().signInWithEmailAndPassword(email, password)
				.then(() => {
					// console.log('signInWithEmailAndPassword SUCCESS ')
					FirebaseMethods.getUserIdByEmail(email,
						(userId, userData) => {
							// console.log('FirebaseMethods.getUserIdByEmail id=', userId, userData);
							onSuccess(userId, userData)
						},
						error => {
							// called when the email does not exist in the database
							// console.log('FirebaseMethods.getUserIdByEmail error', error);
							onFailure(error);
						}
					);
				})
				.catch(error => {
					// Fail here means that there is no user, or wrong password
					console.log('firebase.js authUserEmail error when sign in: ', error);
					if (error.code === 'auth/wrong-password') {
						onFailure(error);
					} else if (error.code === 'auth/user-not-found') {
						createUser(email, password);
					} else {
						console.error('firebase.js authUserEmail unknown error', error);
						onFailure(error);
					}
				});
		}

		const createUser = (email, password) => {
			firebase.auth().createUserWithEmailAndPassword(email, password)
				.then(x => {
					// console.log('createUserWithEmailAndPassword SUCCESS', x);
					onSuccess(x.user.uid, null);
				})
				.catch(({ code, message }) => {
					console.log('authUserEmail error when creating user', message)
					onFailure({ code, message });
				});
		}

		signIn(email, password);
	},
	authUserUsingProvider: (onSuccess, onFailure, providerName) => {
		let provider = providerName === 'github'
			? new firebase.auth.GithubAuthProvider()
			: new firebase.auth.GoogleAuthProvider();
		firebase.auth().signInWithPopup(provider)
			.then(result => {
				//console.log(providerName + ' login. user, credential -> ', result);
				FirebaseMethods.getUserIdByEmail(result.user.email,
					userId => {
						onSuccess({
							displayName: result.user.displayName,
							email: result.user.email,
							userId: userId
						});
					},
					error => console.log('FIREBASE authUserUsingProvider ' + providerName + ' - failed to get user id')
				);
			})
			.catch(onFailure)
	},
	authUserGoogle: (onSuccess, onFailure) => {
		FirebaseMethods.authUserUsingProvider(onSuccess, onFailure, 'google');
	},
	authUserGitHub: (onSuccess, onFailure) => {
		FirebaseMethods.authUserUsingProvider(onSuccess, onFailure, 'github');
	},
	signOutUser: (onSuccess, onFailure) => {
		firebase.auth().signOut()
			.then(onSuccess)
			.catch(onFailure);
	},

	registerOrUpdateUser: ({ displayName, email }, version) => {
		// check if user exists in database
		// if user id exists, update displayname and email
		// otherwise, insert user into database
		console.log('FIREBASE registerOrUpdateUser ', displayName, email, version);
		if (!displayName || !email || !version) {
			console.log('registerOrUpdateUser ERROR: parameter is undefined', displayName, email, version);
			return;
		}

		const db = firebase.database();
		const path = `/users/`;
		db.ref(path).orderByChild('email').equalTo(email).limitToFirst(1).once('value')
			.then(snapshot => {
				let data = snapshot.val();
				if (data === null) {
					// no previous user
					db.ref(path).push({
						displayName, email, isAdmin: false, version: version
					})
						.then(
							() => console.log('SET NEW USER SUCCESS'),
							msg => console.log('SET NEW USER FAIL 1 ', msg)
						)
						.catch(msg => console.log('SET NEW USER FAIL 2', msg));
				} else {
					// update existing user
					let userId = Object.keys(data)[0];
					if (data[userId].displayName !== displayName) {
						data[userId].displayName = displayName;
						db.ref(`/users/${userId}`).set(data[userId])
							.then(() => console.log('UPDATE USER SUCCESS', userId))
						//.catch(() => console.log('UPDATE USER FAIL'));
					} else {
						console.log('No need to update user, everything is up to date.');
					}
				}
			})
		//.catch(message => {
		//	console.log('ERROR ERROR ERROR ERROR ERROR ERROR !', message);
		//})
	},
	sendPasswordReset(email) {
		return firebase.auth().sendPasswordResetEmail(email);
	}

}
export default FirebaseMethods;
