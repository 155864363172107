import { combineReducers } from 'redux';
import ACTIONS from '../actions/constants.js';

// every form has its own branch in the state tree

const getTodayDateAsString = () => {
	let d = new Date();
	d = d.toISOString();
	return d.substring(0, 10);
}

const initialForm1 = {
	isSubmitted: false,
	isValid: false,
	error: [],

	reflektion: '',
	firstName: '',
	lastName: '',
	email: '',
	workedYears: 0,
	workedSchools: [],
	classes: '',
	subjects: [],
	availableResources: '',
	previousExperience: '',
	positiveAboutProgramming: '',
	expectations: '',
	extraComment: ''
}
const initialForm2 = {
	isSubmitted: false,
	isValid: false,
	error: [],

	reflection: '',
	resourcesUsed: ''
}
const emptyLessonPlan = () => ({
	isSubmitted: false,
	isValid: false,
	error: [],

	title: '',
	subjects: [],
	classes: [],
	goalAndPurpose: '',
	estimatedTime: '',
	equipment: '',
	content: '',
	grading: '',
	tags: []
})
const initialForm3 = emptyLessonPlan();
const initialForm4 = {
	isSubmitted: false,
	isValid: false,
	selectedLPId: '-',
	dateSaved: getTodayDateAsString(),
	schoolNameAndAddress: '',
	classes: [],
	funFactor: '3',
	difficultyFactor: '3',
	preparationFactor: '3',
	comment: '',
	tags: [],
	error: []
}
const initialAllForm4 = {
	allForm4: []
}

const formReducer1 = (state = initialForm1, action) => {
	switch( action.type ) {

		case ACTIONS.SAVEFORM1:
			return { ...state, ...action.data };

		case ACTIONS.SUBMITFORM1:
		//console.log('formDataReducer form1 isSubmitted', action);
			return { ...state, ...action.data, isSubmitted: true };

		case ACTIONS.GOTFORM1:
			return { ...state, ...action.data, isSubmitted: true };

		default:
			return state;
	}
}


const formReducer2 = (state = initialForm2, action) => {
	switch( action.type ) {

		case ACTIONS.SAVEFORM2:
			return { ...state, ...action.data };

		case ACTIONS.SUBMITFORM2:
			console.log('formDataReducer form2 isSubmitted', action);
			return { ...state, ...action.data, isSubmitted: true };

		case ACTIONS.GOTFORM2:
			return { ...state, ...action.data, isSubmitted: true };

		default:
			return state;
	}
}

const formReducer3 = (state = initialForm3, action) => {
	switch( action.type ) {
		case ACTIONS.SAVEFORM3:
			return { ...state, ...action.data };

		case ACTIONS.SUBMITFORM3:
			return { ...state, ...action.data, isSubmitted: true };

		case ACTIONS.NEW_LP:
			return emptyLessonPlan();

		case ACTIONS.UPDATE_LP:
		case ACTIONS.VIEW_LP:
		case ACTIONS.MAP_GO_TO_LESSON_PLAN:
			// console.log('view or update LP', action.plan)
			return {
				...state,
				...action.plan
			};
		/*case ACTIONS.SETSCHOOLS:
			return { ...state, workedSchools: action.schools };

		case ACTIONS.SETCLASSES:
			return { ...state, classes: action.classes };

		case ACTIONS.SETSUBJECTS:
			return { ...state, subjects: action.subjects };

		case ACTIONS.SETTAGS:
			return { ...state, tags: action.tags };*/

		default:
			return state;
	}
}

const formReducer4 = (state = initialForm4, action) => {
	switch( action.type ) {
		case ACTIONS.SAVEFORM4:
			return { ...state, ...action.data };

		case ACTIONS.SUBMITFORM4:
			console.log('formDataReducer: submitted form 4,', state, action);
			return { ...state, ...action.data, isSubmitted: true };

		default:
			return state;
	}
}

const allForm4 = (state = initialAllForm4.allForm4, action) => {
	switch( action.type ) {
		case ACTIONS.ALLFORM4:
			// console.log('formDataReducer: received all submitted activities', action.data);
			return [ ...action.data ]

		case ACTIONS.SUBMITFORM4:
			console.log('formDataReducer.allForm4: added activity bc form4 submitted', state, action.data);
			return [ ...state, action.data]

		default:
			return state;
	}
}

const formDataReducer = combineReducers({
	form1: formReducer1,
	form2: formReducer2,
	form3: formReducer3,
	form4: formReducer4,
	allForms: allForm4
});


export { formDataReducer };
