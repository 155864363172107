import React, { Component, Fragment } from 'react';
//import { connect } from 'react-redux';
//import ACTIONS from '../../actions/formActions.js';


class MyTags extends Component {
	state = {
		newTagName: '',
		isValid: false,
		tags: this.props.tags
	}
	tagAlreadyInList = name => this.state.tags.find( tag => tag === name )

	addTag = event => {
		let newTag = this.state.newTagName;
		if( !this.tagAlreadyInList(newTag) ) {
			this.setState(
				{ tags: [...this.state.tags, newTag], isValid: false },
				() => this.props.liftTags(this.state.tags)
			);
		}
	}
	removeTag = tag => {
		this.setState(
			{ tags: this.state.tags.filter( t => t !== tag )},
			() => {
				this.props.liftTags(this.state.tags);
				this.validate();
			}
		);
	}
	validate = () => {
		let newTagName = this.state.newTagName;
		const validProp = newTagName
			&& !this.tagAlreadyInList(newTagName);
		if( this.state.isValid && !validProp )
			this.setState({ isValid: false });
		else if( !this.state.isValid && validProp )
			this.setState({ isValid: true });
	}
	render() {
		const renderedTags = this.state.tags.map(t => (
			<li key={t}> {t}
				<button onClick={() => this.removeTag(t)}>X</button>
			</li>
		))
		return (
			<Fragment>
			<h3>Nyckelord</h3>
			Ord som beskriver din planering. Används för att söka på lektionsplaneringar. Lägg till ett ord i taget.<br />
			<input type="text"
				placeholder="tag"
				value={this.state.newTagName}
				onChange={e => this.setState({newTagName: e.target.value}, this.validate)}/>
			<button onClick={this.addTag}
				disabled={!this.state.isValid}>Lägg till</button>
			<ul> {renderedTags} </ul>
			</Fragment>
		)
	}
}

/*const mapStateToProps = ({ formData }) => ({

})
export default connect(mapStateToProps)(MyTags);*/
export default MyTags;
