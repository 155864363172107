import { activityReducer } from './activityReducer.js';
import { combineReducers } from 'redux';
import { formDataReducer } from './formDataReducer.js';
import { groupsReducer } from './groupsReducer.js';
import { lessonPlanReducer } from './lessonPlanReducer.js';
import { mapReducer } from './mapReducer.js';
import { navigationReducer } from './navigationReducer.js';
import { themeReducer } from './themeReducer.js';
import { uiReducer } from './uiReducer.js';
import { userReducer } from './userReducer.js';
import { videoReducer } from './videoReducer.js';


const rootReducer = combineReducers({
	activity: activityReducer,
	formData: formDataReducer,
	groups: groupsReducer,
	lessonPlans: lessonPlanReducer,
	map: mapReducer,
	navigation: navigationReducer,
	theme: themeReducer,
	ui: uiReducer,
	user: userReducer,
	video: videoReducer
})


export { rootReducer };
