import React, { useState } from 'react';
import FirebaseMethods from '../../utils/firebase.js';
import LessonPlanCard from '../tabs/LessonPlanCard';

function MemberDetails({ user, plans, acts, liftCache }) {
	if( !user ) {
		return (
			<h3>Ingen medlem vald</h3>
		);
	}
	// console.log('MemberDetails: ', user, plans, acts);
	if( !plans ) {
		// console.log('MemberDetails: no plans, fetching from DB', user);
		FirebaseMethods.getLessonPlansByEmail(user.email, liftCache)
	}

	// let userLessonPlans = plans ? plans : 'Hämtar LP från databas...';
	return (
		<div className="admin">
		<section>
			<h3>Vald gruppmedlem: {user.displayName || user.name || user.email || 'Namn inte registrerat'}</h3>
			<div>
				<strong>Form 1:</strong> <RenderForm1 form={user.form1} />
			</div>
			<div>
				<strong>Form 2:</strong> <RenderForm2 form={user.form2} />
			</div>
			<div>
				<strong>Lektionsplaneringar:</strong>
				{plans ? <RenderLps plans={plans} /> : 'Hämtar LP från databas...'}
			</div>
			<div>
				<strong>Aktiviteter:</strong> <RenderActivities acts={acts ? acts.length : 0} /> (totalt)
			</div>
			</section>
		</div>
	)
}

// Fredag:
// 2. hämta antal lektionsplaneringar
// 3. ... och aktiviteter från db
function RenderForm1({ form }) {
	const [show, setShow] = useState(false);

	if( !form ) return (<p>Inte inskickad.</p>)

	if( !show ) return (
		<div className="displayForm">
			<button onClick={() => setShow(true)}> Visa </button>
		</div>
	)

	// classes and subjects can be: array, comma-separated string or undefined
	let classes = typeof form.classes === 'object' ? form.classes.join(',') : form.classes || '-';
	let subjects = typeof form.subjects === 'object' ? form.subjects.join(',') : form.subjects || '-';
	return (
		<div className="displayForm">
			<button onClick={() => setShow(false)}> Dölj </button>
			<p><strong>Reflektion:</strong> {form.reflektion} </p>
			<p><strong>Namn:</strong> {form.firstName} {form.lastName} </p>
			<p><strong>Email:</strong> {form.email} </p>
			<p>Har arbetat {form.workedYears} år i skolan</p>
			<p>Arbetar på följande skolor: {form.workedSchools.reduce((acc, s) => acc + s.name + '; ' + s.address + '. ', '')} </p>
			<p>Jag har klasserna: {classes} </p>
			<p>Jag har ämnena: {subjects} </p>
			<p>Skolan har tillgång till följande resurser: {form.availableResources} </p>
			<p>Tidigare erfarenhet av programmering: {form.previousExperience} </p>
			<p>Jag är positiv till att ta in ämnet programmering i grundskolan, 1-5: {form.positiveAboutProgramming} </p>
			<p>Vad vill jag få ut av utbildningen: {form.expectations} </p>
			<p>Övrig kommentar: {form.extraComment} </p>
		</div>
	)
}

function RenderForm2({ form }) {
	const [show, setShow] = useState(false);

	if( !form ) return (<p>Inte inskickad.</p>)

	if( !show ) return (
		<div className="displayForm">
			<button onClick={() => setShow(true)}> Visa </button>
		</div>
	)
	return (
		<div className="displayForm">
			<button onClick={() => setShow(false)}> Dölj </button>
			<p><strong>Reflektion:</strong> {form.reflection}</p>
			<p>Har använt följande programmeringsresurser: {form.resourcesUsed}</p>
		</div>
	)
}

function RenderLps({ plans }) {
	// console.log('RenderLps plans=', plans);
	const [selected, setSelected] = useState(0);

	if( !plans || plans.length === 0 ) {
		return <div> Kursdeltagaren har inte lagt in någon LP än. </div>
	}
	else if( plans.title && plans.key ) {
		return <LessonPlanCard plan={plans} />
	}
	else if( (typeof plans.length) !== 'number' ) {
		console.log('MemberDetails.RenderLps wrong type: ', plans);
		return <div> Okänt fel! Kunde inte ladda LP. </div>;
	}

	const p = plans[selected];
	return (
		<div className="displayForm">
			<div className="choose-lp">
				{plans.map((plan, index) => (
					<button onClick={() => setSelected(index)} key={index}>
						{plan.title}
					</button>
				))}
			</div>
			<LessonPlanCard plan={p} />
		</div>
	)
}
function RenderActivities(acts) {
	return (
		<div>
			activities - todo
		</div>
	)
}


export default MemberDetails;
