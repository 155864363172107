import ACTIONS from './constants.js';


const changePage = (newPage, newTab = 0) => ({
	type: ACTIONS.CHANGEPAGE,
	newPage,
	newTab
})
let changeTab = newTab => ({
	type: ACTIONS.CHANGETAB,
	newTab
})
let toAdmin = () => ({ type: ACTIONS.ADMIN_SHOW })
let leaveAdmin = () => ({ type: ACTIONS.ADMIN_HIDE })

const actions = {
	changePage,
	changeTab,
	toAdmin,
	leaveAdmin
};

export default actions;
