import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MarkerClusterer } from '../../utils/markerclusterer.js';

import InfoBox from './InfoBox.js';
import ACTIONS from '../../actions/mapActions.js';



class ActivityMap extends Component {
	constructor(props) {
		super(props);
		this.mapRef = React.createRef();
	}

	componentDidMount() {
		if( this.props.doInit ) {
			this.doInit();
		} else {
			this.props.dispatch(ACTIONS.mapDoInit())
		}
	}
	componentDidUpdate() {
		if( this.props.doInit )
			this.doInit();
	}
	doInit = () => {
		if( !window.google.maps ) {
			console.log('ActivityMap: No window.google.maps! Trying again...');
			setTimeout(() => this.doInit(), 750);
			return;
		}
		const google = window.google;

		// Map centered on Sweden
		const mapCenter = new google.maps.LatLng(62.855028, 14.950326);
		const myMapDOM = this.mapRef.current;
		if( !myMapDOM ) {
			console.log('ActivityMap no DOM element for map', this.mapRef);
			return;
		}
		const map = new google.maps.Map(myMapDOM, {
			zoom: 5,
			center: mapCenter  // does not work properly, use listener instead
		});
		const markerclusterer = new MarkerClusterer(map, [], {
			imagePath: 'img/map/m',
			averageCenter: true
		});

		let firstTime = true;
		map.addListener('tilesloaded', () => {
			// console.log("map tiles loaded");
			if(firstTime) {  // fix wrong center on first load
				map.setCenter(mapCenter);
				firstTime = false;
			}
		});

		this.geocodePositions(
			google, gcPosition => this.markerPosition(gcPosition, map, google, markerclusterer)
		);

		// sets doInit==false, avoiding loop
		this.props.dispatch(ACTIONS.mapIsLoaded());
	}

	markerPosition(p, map, google, clusterer) {
		if( !p ) return null;

		// Create marker with click listener and add to map
		p.marker = new google.maps.Marker({
			position: p.position,
			title: p.title,
			map: map
		});
		p.marker.setMap(map);
		p.marker.addListener('click', e => {
			// console.log('CLICK!', p);
			this.props.dispatch(ACTIONS.mapShowInfoBoxFor(p))
		})
		clusterer.addMarker(p.marker);
		return p;
	}

	// Users enter a value between 1 and 5, convert that to 0-100%
	roundTwo = x => Math.floor(100 * x + 0.5) / 100;
	toPieFactor = x => this.roundTwo((x - 1) / 4);

	geocodePositions(google, callback) {
		// Try to convert school addresses to geocodes - not guaranteed to work
		let geocoder = new google.maps.Geocoder();
		// console.log(`ActivityMap.geocodePositions, allforms.length=`+this.props.activities.length, this.props.activities.map(data => data.schoolNameAndAddress), this.props.activities);
		this.props.activities.forEach(form => {
			// Find the selected LP
			let plan = this.props.plans.find(plan => plan.key === form.selectedLPId);

			// Add Google Maps Geocode for real position
			if( plan && form.schoolNameAndAddress ) {
				geocoder.geocode(
					{ 'address':  form.schoolNameAndAddress},
					(results, status) => this.handleGeocodeResult(results, status, form, plan, callback)
				);
			}
		});
	}  // geocodePositions

	handleGeocodeResult(results, status, form, plan, callback) {
		let lpInformation = null;
		if (status === 'OK') {

			let lat = results[0].geometry.location.lat();
			let lng = results[0].geometry.location.lng();

			// console.log('ActivityMap.handleGeocodeResult, LP=', plan);
			lpInformation = {
				lpId: plan.key,
				position: { lat: lat, lng: lng },
				school: form.schoolNameAndAddress,
				dateDone: plan.dateSaved,
				title: plan.title,
				time: plan.estimatedTime,
				target: plan.classes.join(', '),
				tags: form.tags,
				equipment: plan.equipment,
				description: form.comment,
				pies: { fun: this.toPieFactor(form.funFactor), hard: this.toPieFactor(form.difficultyFactor) , time: this.toPieFactor(form.preparationFactor) }
			};
			// console.log('ActivityMap.geocodePositions found: '+plan.title);
		} else {
			console.log(`Kunde inte placera skola på kartan, på grund av felaktig adress: "${form.schoolNameAndAddress}"`);
			// console.log('ActivityMap.geocodePositions status not ok:', status)
		}
		callback(lpInformation)
	}  // handleGeocodeResult

	render() {
		const loadFailed = !this.props.mapIsLoading && !this.props.mapLoadSuccess;
		const loadingStill = this.props.mapIsLoading || !this.props.hasFetchedPlans;
		let content = 'Programmeringskartan';
		if( loadingStill ) {
			//return ( <div className="map" ref={this.mapRef}> Programmeringskartan laddas... </div> );
			content = 'Programmeringskartan laddas...';
		} else if( loadFailed ) {
			//return ( <div className="map" ref={this.mapRef}> Kunde inte ladda programmeringskartan. Kontakta din kursledare. </div> );
			content = 'Kunde inte ladda programmeringskartan. Kontakta din kursledare.';
		}

		// Render this.props.plans
		return (
			<Fragment>
			<div className="map" ref={this.mapRef}> {content} </div>

			<InfoBox />
			</Fragment>
		)
	}
}

const mapStateToProps = ({ map, lessonPlans, formData }) => ({
	doInit: map.doInit,
	mapIsLoading: map.mapIsLoading,
	mapLoadSuccess: map.mapLoadSuccess,
	plans: lessonPlans.plans,
	hasFetchedPlans: lessonPlans.hasFetched,
	formData: formData.form4,
	activities: formData.allForms
})

export default connect(mapStateToProps)(ActivityMap);


// const examplePlaces = [
// 	{
// 		position: { lat: 62, lng: 15 },
// 		school: 'Zocom test',
// 		title: 'Digital programmering: "Introduktion till Lightbot"',
// 		time: '45 minuter',
// 		target: 'F-klass och uppåt',
// 		groupsize: 'Halvklass med fördel, men går med helklass',
// 		place: 'Klassrum / datorsal',
// 		equipment: 'Dator / surfplatta / smartphone',
// 		description: 'Här kommer du utmana barnen i logiskt tänkande. Programmet vi använder är Lightbot Jr som riktar sig till äldre barn. Li',
// 		pies: { fun: 0.9, hard: 0.6, time: 0.3 }
// 	},
// 	{
// 		position: { lat: 57.7458133, lng: 11.9763798 },
// 		school: 'Nuclear apocalypse',
// 		title: 'Skynet i tre enkla steg',
// 		time: '90 minuter',
// 		target: '7-9',
// 		groupsize: 'Individuellt',
// 		place: 'Ett rum',
// 		equipment: 'Dator',
// 		description: "The future is here.\n\nResistance is futile.",
// 		pies: { fun: 0.2, hard: 0.9, time: 0.1 }
// 	},
// 	{
// 		position: { lat: 57.4833989, lng: 14.6465163 },
// 		school: 'Bullerbyskolan',
// 		title: 'Pippi lär sig assembler',
// 		time: '15 minuter',
// 		target: '1-2',
// 		groupsize: 'Helklass',
// 		place: 'Klassrum',
// 		equipment: 'Dator',
// 		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus luctus elit non nibh lacinia laoreet. Aliquam maximus dolor vitae finibus sagittis. Etiam quis ultricies nibh. Nullam in lorem porttitor, aliquet lorem vel, euismod magna.\n\nSuspendisse potenti. Aliquam sagittis nibh nec mi elementum vulputate. Nunc vehicula laoreet est, ut porta leo porta eu.\n\nUt tincidunt ligula id lectus dapibus, eu volutpat ante sodales. Nunc iaculis vitae diam non pellentesque. Praesent sagittis, nisl ac semper porta, massa elit volutpat sapien, vel vestibulum libero mauris id ipsum. Aenean cursus pretium metus sit amet dignissim. Nunc eget porta odio. In sodales rhoncus leo, ut semper dolor auctor in.\n\nDonec sit amet lacinia leo. Fusce arcu lacus, dapibus sit amet sollicitudin quis, euismod luctus sem. Aliquam leo elit, ultricies nec lobortis nec, sagittis quis neque. Aenean aliquam eleifend congue. Nulla at velit non ipsum ornare tristique. Proin feugiat orci eu nunc dapibus, eget blandit diam dapibus.",
// 		pies: { fun: 0.75, hard: 0.5, time: 0.4 }
// 	}
// ];
