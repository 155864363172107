import ACTIONS from '../actions/constants.js';
import { VERSION } from '../constants.js';

const pageTitles = [ '1. Kursstart', '2. Programmering', '3. Uppdraget', '4. Aktiviteten' ];
const tabHeaders = [
	{}, // index 0
	{nr: 1, tabs: ['Att läsa på distans', 'En introduktion', 'Uppgift']},
	{nr: 2, tabs: ['Uppgift', 'Fältet', 'Reflektion']},
	{nr: 3, tabs: ['Uppgift', 'Min planering', 'Lektionsplaneringar']},
	{nr: 4, tabs: ['Examinationsuppgift', 'Aktivitet']},
	{nr: 5, tabs: []}, // index 5
	{nr: 6, tabs: ['HTML &amp; CSS', 'JavaScript', 'Python']}
];
const tabHeadersGym = [
	{},
	{nr: 1, tabs: ['Att läsa på distans', 'Introduktion', 'Uppgift']},
	{nr: 2, tabs: ['Räkna med variabler', 'Tekniker', 'Reflektion']},
	{nr: 3, tabs: ['Uppgift', 'Min lektionsplanering', 'Lektionsplaneringar']},
	tabHeaders[4],
	tabHeaders[5],
	tabHeaders[6]
];


// Initial version is actually set by AppRouter using methods in themeActions
const initialState = {
	xavierVersion: VERSION.GRUNDSKOLA,
	selectedPage: 0,
	selectedTab: 0,
	pageTitle: '',
	tabTitles: tabHeaders[1].tabs,
	showAdmin: false
};

const navigationReducer = (state = initialState, action) => {
	//console.log('navigationReducer, action=', action)
	switch( action.type ) {
		case ACTIONS.ADMIN_SHOW:
			return { ...state, showAdmin: true };
		case ACTIONS.ADMIN_HIDE:
			return { ...state, showAdmin: false };

		case ACTIONS.CHANGEVERSION:
			// console.log('navigationReducer: change version, action=', action);
			return {
				...state,
				xavierVersion: action.version,
				selectedPage: 0,
				selectedTab: 0,
				pageTitle: pageTitles[0],
				tabTitles: action.version === VERSION.GRUNDSKOLA
					? tabHeaders.find(h => h.nr === 1).tabs
					: tabHeadersGym.find(h => h.nr === 1).tabs
			}
		case ACTIONS.CHANGEPAGE:
			// console.log('navigationReducer: change page, action=', action);
			return {
				...state,
				selectedPage: action.newPage,
				pageTitle: pageTitles[action.newPage - 1],
				selectedTab: (action.newTab ? action.newTab - 1 : 0),
				tabTitles: state.xavierVersion === VERSION.GRUNDSKOLA
					? tabHeaders.find(h => h.nr === action.newPage + 1).tabs
					: tabHeadersGym.find(h => h.nr === action.newPage + 1).tabs
			}
		case ACTIONS.CHANGETAB:
			return {
				...state,
				selectedTab: action.newTab
			}

		case ACTIONS.UPDATE_LP:
		case ACTIONS.MAP_GO_TO_LESSON_PLAN:
		case ACTIONS.NEW_LP:
			return {
				...state,
				selectedTab: 2-1,  // LP form
				selectedPage: 3-1
			}

		case ACTIONS.VIEW_LP:
			return {
				...state,
				selectedTab: 3-1,  // view LP
				selectedPage: 3-1,
				selectedLp: action.plan }
		case ACTIONS.CLOSE_LP:
			return { ...state, selectedLp: null }

		case ACTIONS.USER_SIGNIN:
			return {
				...state,
				selectedTab: 0,
				selectedPage: 0
			}

		default:
			return state;
	}
}




export { navigationReducer };
