import ACTIONS from './constants.js';


const submitForm1 = data => ({ type: ACTIONS.SUBMITFORM1, data });
const submitForm2 = data => ({ type: ACTIONS.SUBMITFORM2, data });
const submitForm3 = data => ({ type: ACTIONS.SUBMITFORM3, data });
const submitForm4 = data => ({ type: ACTIONS.SUBMITFORM4, data });

const saveForm1 = data => ({ type: ACTIONS.SAVEFORM1, data });
const saveForm2 = data => ({ type: ACTIONS.SAVEFORM2, data });
const saveForm3 = data => ({ type: ACTIONS.SAVEFORM3, data });
const saveForm4 = data => ({ type: ACTIONS.SAVEFORM4, data });
/*const setSchools = schools => ({
	type: ACTIONS.SETSCHOOLS,
	schools
})
const setClasses = classes => ({
	type: ACTIONS.SETCLASSES,
	classes
})
const setSubjects = subjects => ({
	type: ACTIONS.SETSUBJECTS,
	subjects
})
const setTags = tags => ({
	type: ACTIONS.SETTAGS,
	tags
})*/


const addLessonPlan = plan => ({
	type: ACTIONS.ADD_LP,
	plan
})
const updateLessonPlan = plan => ({
	type: ACTIONS.UPDATE_LP,
	plan
})
const viewLessonPlan = plan => ({
	type: ACTIONS.VIEW_LP,
	plan
})
const closeLessonPlan = () => ({
	type: ACTIONS.CLOSE_LP
})
const newLessonPlan = () => ({
	type: ACTIONS.NEW_LP
})

const gotForm1 = data => ({ type: ACTIONS.GOTFORM1, data })
const gotForm2 = data => ({ type: ACTIONS.GOTFORM2, data })
const gotForm4 = data => ({ type: ACTIONS.GOTFORM4, data })

const allform4 = data => ({ type: ACTIONS.ALLFORM4, data })


const actions = {
	submitForm1, submitForm2, submitForm3, submitForm4,
	//setSchools, setClasses, setSubjects, setTags,
	saveForm1, saveForm2,
	saveForm3, saveForm4,
	gotForm1, gotForm2, gotForm4,
	allform4,
	addLessonPlan, updateLessonPlan, viewLessonPlan, closeLessonPlan, newLessonPlan
};

export default actions;
