import React, { Component } from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../actions/navigationActions.js';

class TabButton extends Component {
	mapNumberToLetter = nr => ['A', 'B', 'C', 'D'][nr]
	 /*{
		if( nr === 1 ) return 'A';
		else if( nr === 2 ) return 'B';
		else if( nr === 3 ) return 'C';
		return 'D';
	}*/
	render() {
		let letter = this.mapNumberToLetter(this.props.index);
		let imgActiveClass = 'img' + letter
		if( this.props.active )
			imgActiveClass += '_active';
		const cname = 'tabbtn' + (this.props.active ? ' checked' : '');

		//console.log('TabButton: props=', this.props)
		let content = this.props.headers[this.props.index];
		//this.props.headers.find(x => x.nr === this.props.selectedPage).tabs[this.props.selectedTab];

		return (
			<div className={cname}
				onClick={() => this.props.dispatch(ACTIONS.changeTab(this.props.index))}>
				<img className={imgActiveClass} alt={letter}/>
				{content}
			</div>
		);
	}
}

const mapStateToProps = ({ navigation }) => ({
	selectedPage: navigation.selectedPage,
	selectedTab: navigation.selectedTab,
	headers: navigation.tabTitles
})

export default connect(mapStateToProps)(TabButton);
