import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasicStats from './BasicStats.js';
import ManageGroup from './ManageGroup.js';
import ACTIONS from '../../actions/navigationActions.js';

const MODE_BASIC = 'basic', MODE_GROUP = 'group';

const AdminView = () => {
	const dispatch = useDispatch();
	const [mode, setMode] = useState(MODE_BASIC);
	const isAdmin = useSelector(state => state.user.isAdmin)
	const adminType = useSelector(state => state.user.adminType)

	if( !isAdmin )
		dispatch(ACTIONS.leaveAdmin());

	const isBasicAdmin = adminType === 'basic';
	const canSeeAdvanced = !isBasicAdmin;
	const basic = ( <> <BasicStats /> </> );
	const advanced = canSeeAdvanced ? (
		<>
		<h3>Skapa och administrera grupper</h3>
		<ManageGroup />
		</>
	) : null;
	let content = null;
	if( mode === MODE_BASIC )
		content = basic;
	else if( mode === MODE_GROUP )
		content = advanced;

	return (
		<div className="adminView">
		<h2>Administrera</h2>
		<div className="admin-mode">
			<button onClick={() => setMode(MODE_BASIC)}
				className={mode === MODE_BASIC ? 'selected' : null}>
				Se grupp, inskickat material
			</button>
			<button onClick={() => setMode(MODE_GROUP)}
				className={mode === MODE_GROUP ? 'selected' : null}>
				Adminstrera grupper, lägga till medlemmar
			</button>
		</div>
		{content}
		</div>
	)
}

export default AdminView;
