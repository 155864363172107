// Nya versioner läggs till här. Påverkar många filer, men särskilt PageIntroText.
const VERSION = {
	GRUNDSKOLA: 'grundskola',
	GYMNASIUM: 'gymnasium'
};
const PAGES = {
	START: 0,
	PROGRAMMERING: 1,
	UPPDRAGET: 2,
	AKTIVITETEN: 3,
	MAP: 4
}

export { VERSION, PAGES };
