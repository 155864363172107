import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import ACTIONS from '../../actions/formActions.js';
import MyTags from './MyTags.js';
import { validateErrors } from '../../utils/functions.js';
import NAVACTIONS from '../../actions/navigationActions.js';
import Firebase from '../../utils/firebase.js';
import { PAGES, VERSION } from '../../constants.js';


class Form4 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSubmitted: props.isSubmitted,
			isValid: props.isValid,

			selectedLPId: props.selectedLPId,
			dateSaved: props.dateSaved,
			schoolNameAndAddress: props.schoolNameAndAddress,
			classes: props.classes,
			funFactor: props.funFactor,
			difficultyFactor: props.difficultyFactor,
			preparationFactor: props.preparationFactor,
			comment: props.comment,
			tags: props.tags,
			error: props.error,

			cachedAddresses: {},
			geocodeWaiting: false,
			geocodeTimeout: 0,
			geocodeError: '',
			geocodeStatus: ''
		}
	}
	geocoder = null;


	componentDidMount() {
		this.validate();
		// console.log(this.props.forms, this.props.lessonPlans);
		// console.log('Form4 @1: about to create a Geocoder object (should happen ONCE)');
		this.geocoder = new window.google.maps.Geocoder();
	}
	componentDidUpdate = () => this.validate()

	findAddress = (typedAddress, whenResults) => {
		const delay = 200*10;  // how long to wait for next key typed
		const self = this;

		// Reset timer (clear the old)
		if( this.state.geocodeTimeout ) {
			clearTimeout(this.state.geocodeTimeout);
		}

		// Do not query if the field is blank
		if( typedAddress === '' ) {
			if( this.state.geocodeError || this.state.geocodeStatus ) {
				this.setState({ geocodeError: '', geocodeStatus: '' });
				return;
			}
		}

		if( !this.geocoder ) {
			// console.error('Form4 @2: about to create a Geocoder object (should happen ONCE)');
			this.geocoder = new window.google.maps.Geocoder();
		}

		// Check if address is cached
		let cache = this.state.cachedAddresses[typedAddress];
		if( cache ) { whenResults(cache); return; }



		// Callback for when results return
		const gotGeocodeResult = data => {
			let obj = { ...this.state.cachedAddresses };
			obj[typedAddress] = data;
			self.setState({
				geocodeWaiting: false,
				cachedAddresses: obj
			});
			whenResults(data);
		};
		// Send request to geocode API
		const timeoutId = setTimeout( () => this.sendGeocodeRequest(typedAddress, gotGeocodeResult), delay );
		this.setState({
			geocodeWaiting: true,
			geocodeTimeout: timeoutId,
			geocodeStatus: 'Kontrollerar adressen...',
			geocodeError: ''
		});
	}
	sendGeocodeRequest = (address, callback) => {
		// console.log('Sending geocode request *****' + address+ '******');
		this.geocoder.geocode(
			{ address: address },
			(results, status) => {
				// console.log('Form4 geocoding returned: ', results, status);
				if( status === 'INVALID_REQUEST' || status === 'ZERO_RESULTS' ) {
					callback({ error: status, message: `Kunde inte hitta adressen: ` + address });
				} else if( results.length > 1 ) {
					callback({ message: `Hittade adressen: ${results[0].formatted_address} (men det finns ${results.length} andra sökträffar)`, result: results[0] });
				} else if( status === 'OK' ) {
					callback({ message: 'Hittade adressen: ' + results[0].formatted_address, result: results[0] });
					// console.log('Address added to cache:', this.state.cachedAddresses);
				}
			}
		)
	}
	validate() {
		// Check if all user input is valid; if so, set isValid=true in preparation for submitting
		let validLP = this.state.selectedLPId !== '-';
		let validDate = this.isValidDate(this.state.dateSaved);
		let validSchool = this.state.schoolNameAndAddress !== '' && this.state.geocodeError === '';
		let validClasses = this.state.classes.length > 0;
		let validComment = this.state.comment !== '';
		let validTags = this.state.tags.length > 0;

		let errors = [];
		if( !validLP ) errors.push('Du måste välja en lektionsplanering som du har skickat in i föregående steg.');
		if( !validDate ) errors.push('Skriv in datumet du genomförde aktiviteten, på ett giltigt datumformat. Exempel: 2018-03-11.');
		//Maybe geocode here too, to check if the address exists
		if( !validSchool ) errors.push('Skriv vilken skola du genomförde aktiviteten på.');
		if( !validClasses ) errors.push('Skriv vilka klasser eller kurser du genomfört aktiviteten med.');
		if( !validComment ) errors.push('Vi behöver en beskrivande kommentar som kan visas när man klickar på aktiviteten på kartan.');
		if( !validTags ) errors.push('Tagga aktiviteten, så att den blir lättare att söka på.');
		validateErrors(errors, this.state.error, this);
    }

    isValidDate = d => {
		// yyyy-mm-dd recommended
		return typeof (d) === 'string' && Date.parse(d) > 0;
	}

	updateAddress = e => {
		this.findAddress(e.target.value, result => {
			// console.log('Form4.findAddress: received geocode response', result);
			if( result.error ) {
				// console.error(`Error occurred with Geocode API. Status=${result.error}, message=${result.message}.`);
				this.setState({ geocodeError: 'Kunde inte hitta skolan. Kontrollera att du skrivit rätt adress.', geocodeStatus: '' })
			} else {
				this.setState({ geocodeError: '', geocodeStatus: result.message })
			}
		})
		this.update(e, 'schoolNameAndAddress');
	}
	update = (event, prop) => this.update2(event.target.value, prop)
	update2 = (value, prop) => {
		let obj = {};
		obj[prop] = value;
		this.setState(obj, () => {
			//this.validate(); componentDidUpdate
			//console.log('Form3 update2 SAVEFORM classes=', this.state.classes)
			this.props.dispatch(ACTIONS.saveForm4(this.state));
		});
	}

    submit = event => {
		const slice = this.saveSlice(this.state);
		console.log('Form4.submit: ', slice);
		this.props.dispatch(ACTIONS.submitForm4(slice));
		Firebase.submitForm4(this.props.userId, slice, () => console.log('Form4.submit FORM SUCCESS'));
		Firebase.submitActivity(slice, () => console.log('Form4.submit ACTIVITY SUCCESS'));
	}
	saveSlice = ({isSubmitted, isValid, selectedLPId, dateSaved, schoolNameAndAddress, classes, funFactor, difficultyFactor, preparationFactor, comment, tags, error}) => ({isSubmitted, isValid, selectedLPId, dateSaved, schoolNameAndAddress, classes, funFactor, difficultyFactor, preparationFactor, comment, tags, error})

    goToActivityMap = e => {
		e.preventDefault();
		this.props.dispatch(NAVACTIONS.changePage(PAGES.MAP));
	}

	render() {
		const whenSubmitted = this.props.isSubmitted ? (
            <div className="whensubmitted">
                <p>
                    Gratulerar till examen!
            </p>
                <p>
                    Nu är din aktivitet registrerad. Gå till <a href="aktivitetskarta" onClick={this.goToActivityMap}>aktivitetskartan</a> för att se den och andra aktiviteter. Du kan också skicka in fler aktiviteter.
            </p>
            </div>
        ) : null;

		let errors = this.state.error.map( e => (
			<li key={e}> {e} </li>
		));
		if (errors.length > 0) {
			errors = (<ul className="errors"> {errors} </ul>);
		}

		// console.log('Form4.render, LP: ', this.props.userId, this.props.lessonPlans.filter(lesson => lesson.userId === this.props.userId));
		const lessonPlanOptions = [
			(<option key="--" value="-"> - Välj en av dina lektionsplaneringar - </option>),
			...this.props.lessonPlans.filter(lesson => lesson.userId === this.props.userId).map(lp => (
				<option key={lp.key} value={lp.key}>{lp.title}</option>
			))
		];
		// <option value="-"> - Välj en av dina lektionsplaneringar - </option>
		// <option value="1"> Skuttande scratch-ekorre </option>
		// <option value="2"> Pippi Långstump lär sig assembler </option>
		let classLabel = 'Med vilken klass?';
		let classPlaceholder = 'F, 1, 2 osv.';
		if( this.props.version === VERSION.GYMNASIUM ) {
			classLabel = 'I vilka kurser?';
			classPlaceholder = '1a, 2a, 2b osv.';
		}

		let geocodeMessage = (<span id="f4schoolgeocode">&nbsp;</span>);
		if( this.state.geocodeError ) {
			geocodeMessage = (<span id="f4schoolgeocode" className="gcError">{this.state.geocodeError}</span>);
		} else if( this.state.geocodeStatus ) {
			geocodeMessage = (<span id="f4schoolgeocode" className="gcStatus">{this.state.geocodeStatus}</span>);
		}

		return (
			<Fragment>
			{whenSubmitted}
			<div className="form">
				<h2>Upplevelsen av utbildningsaktiviteten med eleverna</h2>
				<p>
					För att aktivera din lektionsplanering på Programmeringskartan måste du tillsammans med dina elever <em>(eller den grupp som du genomförde utbildningsaktiviteten med)</em> svara på två frågor. Ett bra sätt är att projicera upp frågorna i klassrummet och diskutera/resonera kring den aktivitet som ni gemensamt genomfört. När du sparat/skickat informationen kommer din lektionsplanering dyka upp på programmeringskartan. Detta ska vara gjort innan examinationstillfället.
				</p>
				<p>
					Vi syns på examinationen.<br />
					Lycka till!
				</p>

				<span style={{ display: 'inline-block' }}> <img alt="Sverigekarta med lektionsplan" src={'img/' + this.props.imageFolder + 'sverige_karta.png'} /> </span>

				<div>
					<h3>Fyll i aktivitet</h3>
					<p>
						Här ska du skriva in hur det gick när du genomförde din lektionsplanering, så att den kan delas med andra på aktivitetskartan.
					</p>
				</div>

                <div>
					<h3>Vilken lektionsplanering använde du?</h3>
					<select value={this.state.selectedLPId}
						onChange={e => this.update(e, 'selectedLPId')}>
						{lessonPlanOptions}
					</select>

					<h3>När gjordes aktiviteten?</h3>
					<input type="text" placeholder="2018-08-20"
						onChange={e => this.update(e, 'dateSaved')}
						value={this.state.dateSaved} />

					<h3>På vilken skola?</h3>
					<input type="text" placeholder="Namn och adress"
						className="large"
						onChange={this.updateAddress}
						value={this.state.schoolNameAndAddress} /> <br />
					{geocodeMessage}

					<h3>{classLabel}</h3>
					<input type="text"
						value={this.state.classes}
						placeholder={classPlaceholder}
						onChange={e => this.update(e, 'classes')} />

					<h3>Hur relevant tyckte klassen att aktiviteten var?</h3>
					<div className="range">
						<input type="range" min="1" max="5" step="0.25" list="tickmarks4"
							value={this.state.funFactor}
							onChange={e => this.update(e, 'funFactor')} />
						<span>Ovidkommande</span>
						<span>Relevant</span>
					</div>

					<h3>Hur svår tyckte klassen att aktiviteten var?</h3>
					<div className="range">
						<input type="range" min="1" max="5" step="0.25"
							value={this.state.difficultyFactor}
							onChange={e => this.update(e, 'difficultyFactor')}
							list="tickmarks4" />
						<span>Lätt</span>
						<span>Svår</span>
					</div>

					<h3>Hur lång tid uppskattar du att det tog att förbereda aktiviteten?</h3>
					<div className="range">
						<input id="f4preptime" type="range" min="1" max="5" step="0.25"
							value={this.state.preparationFactor}
							onChange={e => this.update(e, 'preparationFactor')}
							list="tickmarks4" />
						<span>15 minuter</span>
						<span>4 timmar</span>
					</div>

					{/* Labels currently (2018-07) not supported in browsers */}
					<datalist id="tickmarks4">
						<option value="1" />
						<option value="2" />
						<option value="3" />
						<option value="4" />
						<option value="5" />
					</datalist>
				</div>

                <div>
					<h3>Kort kommentar</h3>
					<p>
						Här kan du skriva en kort kommentar, som kommer visas när dina kurskamrater letar bland uppladdade kursplaneringar.
					</p>
					<textarea rows="3"
						value={this.state.comment}
						onChange={e => this.update(e, 'comment')}
						placeholder="Till exempel ett citat från någon av eleverna, eller din egen sammanfattning av hur det gick."></textarea>
				</div>
				<div>
					<h3>Ange några nyckelord som man kan använda för att söka på din lektionsplan</h3>
					<p> Exempel: Python, repl.it, trinket, Code skulptor osv. </p>
					<MyTags tags={this.state.tags}
						liftTags={tags => this.update2(tags, 'tags')} />

					{errors}
					<button onClick={this.submit}
						disabled={!this.state.isValid}>Nåla fast aktivitet på kartan</button>
				</div>
            </div>
			</Fragment>
            )
	}
}

const mapStateToProps = ({ formData, theme, lessonPlans, user }) => ({
	isSubmitted: formData.form4.isSubmitted,
	isValid: formData.form4.isValid,
	userId: user.userId,
	selectedLPId: formData.form4.selectedLPId,
	dateSaved: formData.form4.dateSaved,
	schoolNameAndAddress: formData.form4.schoolNameAndAddress,
	classes: formData.form4.classes,
	funFactor: formData.form4.funFactor,
	difficultyFactor: formData.form4.difficultyFactor,
	preparationFactor: formData.form4.preparationFactor,
	comment: formData.form4.comment,
	tags: formData.form4.tags,
	error: formData.form4.error,
	forms: formData.allForms,

	imageFolder: theme.imageFolder,
	version: theme.version,
	lessonPlans: lessonPlans.plans
})

export default connect(mapStateToProps)(Form4);
