import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import ACTIONS from '../actions/navigationActions.js';
import VIDACTIONS from '../actions/videoActions.js';
import AuthenticateButton from './AuthenticateButton.js';
//import Lightbox from './Lightbox.js';
import { VERSION, PAGES } from '../constants.js';


const VideoImage = connect()(props => (
	<img className="vid" src={props.src} alt={props.alt}
	 	onClick={() => props.dispatch(VIDACTIONS.showVideoOverlay(props.vid))} />
))


const LinkNewWindow = ({ href, children, id, className='' }) => (
	<a className={className} href={href} target="_blank" rel="noopener noreferrer" id={id}>{ children }</a>
)

const changePage = (e, newPage, props) => {
	e.preventDefault();
	props.dispatch(ACTIONS.changePage(newPage))
}
const changeTab = (e, newTab, props) => {
	e.preventDefault()
	props.dispatch(ACTIONS.changeTab(newTab))
}


class LinkChangeTab extends Component {
	render() {
		return (
			<a className={this.props.cname}
				href="next-tab"
				onClick={ e => changeTab(e, this.props.next - 1, this.props) }>
				{this.props.text} <img src={'img/forward_icon.png'} alt=">>" />
			</a>
		)
	}
}
const mapStateToPropsLCT = ({ theme }) => ({
	imageFolder: theme.imageFolder
})
LinkChangeTab = connect(mapStateToPropsLCT)(LinkChangeTab);


class LinkChangePage extends Component {
	render() {
		return (
			<a className={this.props.cname}
				href="change-page"
				onClick={ e => changePage(e, this.props.next - 1, this.props) }>
				{this.props.text} <img src={'img/forward_icon.png'} alt=">>" />
			</a>
		)
	}
}
const mapStateToPropsLCP = ({ theme }) => ({
	imageFolder: theme.imageFolder
})
LinkChangePage = connect(mapStateToPropsLCP)(LinkChangePage);




const mapStateToPropsHI = ({ theme }) => ({
	imageFolder: theme.imageFolder,
	imageClass: theme.version === VERSION.GYMNASIUM ? 'topplist_highschool' : ''
})
const HeaderImg =  connect(mapStateToPropsHI)(props => (
	<div className="bigpicture">
		<img className="bg" src={'img/' + props.imageFolder + 'topplist_2184.jpg'} alt="Bakgrund" />
		<div className="bg2"> </div>
		<img src={'img/' + props.imageFolder + 'topplist_logo2.jpg'}
			className={props.imageClass}
			alt="Programmering för grundskolan, matematik och teknik" />
	</div>
));


class HeaderMenu extends Component {
	render() {
		return (
			<Fragment>
				<div className="smenu">
					<label htmlFor="smenucb">
					<span className="fa fa-bars"></span></label>
					<input type="checkbox" id="smenucb" />
					<div className="menu" id="menu1"
						onClick={e => changePage(e, PAGES.START, this.props)}
					> 1. Kursstart </div>
					<div className="menu" id="menu2"
						onClick={e => changePage(e, PAGES.PROGRAMMERING, this.props)}> 2. Programmering </div>
					<div className="menu" id="menu3"
						onClick={e => changePage(e, PAGES.UPPDRAGET, this.props)}> 3. Uppdraget </div>
					<div className="menu" id="menu4"
						onClick={e => changePage(e, PAGES.AKTIVITETEN, this.props)}> 4. Aktiviteten </div>
				</div>
				<div className="mobicon">
					<img alt="Toggle smartphone view" src={'img/' + this.props.imageFolder + 'mob_icon_red.png'} />
				</div>
			</Fragment>
		)
	}
}
const mapStateToPropsHM = ({ navigation, theme }) => ({
	selectedPage: navigation.selectedPage,
	imageFolder: theme.imageFolder
})
HeaderMenu = connect(mapStateToPropsHM)(HeaderMenu);


class DesktopMenu extends Component {
	render() {
		let page = this.props.selectedPage;
		const links = ['Kursstart', 'Programmering', 'Uppdraget', 'Aktiviteten'].map(
			(x, index) => (
				<li className={'page'+(index+1) + (index === page ? ' selected' : '')}
					onClick={e => changePage(e, index, this.props)}
					key={x + index}>
					{(index+1) + '. ' + x}
				</li>
			)
		)
		return (
			/*<li className="selected page1"> 1. Kursstart </li>
			<li className="page2"> 2. Programmering </li>
			<li className="page3"> 3. Uppdraget </li>
			<li className="page4"> 4. Aktiviteten </li>*/
			<nav className="header">
				<div>
					<div>
						<ul className="menu"> {links} </ul>
						<AuthenticateButton onlySignOut={true} />
						<div className="icons">
							<LinkNewWindow href="https://skolpartner.adobeconnect.com/arena/">CONNECT <img src={'img/connect_icon.png'} alt="icon" /></LinkNewWindow>
							<LinkNewWindow href="https://app.slack.com/client/TUUU74D6Y/CUHTTEMU3">SLACK <img src={'img/slack_icon.png'} alt="icon" /></LinkNewWindow>
						</div>
					</div>
				</div>
			</nav>
		);
	}
}//= props => (
const mapStateToPropsDM = ({ navigation }) => ({
	selectedPage: navigation.selectedPage
})
DesktopMenu = connect(mapStateToPropsDM)(DesktopMenu);




const DownloadButton = ({ href, children, className }) => (
	<a className={'button ' + className} href={href} download>{children}</a>
)


const Footer = props => (
	<footer>
		<p>
			© 2019 Advania
		</p>
	</footer>
)

export { VideoImage, LinkNewWindow, LinkChangeTab, LinkChangePage, HeaderImg, HeaderMenu, DesktopMenu, DownloadButton, Footer };
