import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../actions/formActions.js';
import { VERSION } from '../../constants.js'

import { LinkChangePage } from '../Helpers.js';
import MySchools from './MySchools.js';
import MySubjects from './MySubjects.js';
import FirebaseMethods from '../../utils/firebase.js';


class Form1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSubmitted: props.isSubmitted,
			isValid: props.isValid,
			error: props.error,

			reflection: props.reflektion,  // obs! "C" i stället för "K"
			firstName: props.firstName, lastName: props.lastName, email: props.email,
			workedYears: props.workedYears,
			workedSchools: props.workedSchools,
			classes: props.classes,
			subjects: props.subjects,
			availableResources: props.availableResources,
			previousExperience: props.previousExperience,
			positiveAboutProgramming: props.positiveAboutProgramming,
			expectations: props.expectations,
			extraComment: props.extraComment
		}
	}

	validate = values => {
		// Check if all user input is valid; if so, set isValid=true in preparation for submitting
		const { reflection, firstName, lastName, email, workedYears, workedSchools, classes, subjects, availableResources, previousExperience, expectations } = values;
		let validReflection = reflection !== '';
		let validName = firstName !== '' && lastName !== '';
		let validEmail = email !== '';
		let validWorkedYears = workedYears !== '' && Number(workedYears) >= 0;
		let validWorkedSchools = workedSchools.length > 0;
		let validClasses = classes.length > 0;
		let validSubjects = subjects.length > 0;
		let validResources = availableResources !== '';
		let validPreviousExp = previousExperience !== '';
		let validExpectations = expectations !== '';
		//let validPositive = // valfritt fält
		//let validExtra = // valfritt fält

		let errors = {};
		if( !validReflection ) errors.reflection = 'Skriv en kort reflektion.';
		if( !validName || !validEmail ) errors.nameEmail = 'Vi vill ha kontaktuppgifter till dig.';
		if( !validWorkedYears ) errors.workedYears = 'Fyll i hur många år du arbetat i grundskolan som ett tal, 0 eller större.';
		if( !validWorkedSchools ) errors.workedSchools = 'Lägg till minst en skola.';
		if( this.props.version === VERSION.GYMNASIUM ) {
			if( !validClasses ) errors.classes = 'Skriv vilka ämnen du undervisar i.';
		} else {
			if( !validClasses ) errors.classes = 'Lägg till minst en årskurs som du undervisar.';
			if( !validSubjects ) errors.subjects = 'Lägg till minst ett ämne som du undervisar eller att kommer att undervisa i.';
		}
		if( !validResources ) errors.resources = 'Vi vill veta vad du har tillgång till för resurser på skolan när det gäller programmeringsundervisning.';
		if( !validPreviousExp ) errors.previousExperience = 'Vi vill veta vad du har för tidigare erfarenhet av att programmera själv eller att undervisa om programmering.';
		if( !validExpectations ) errors.expectations = 'Vi vill veta något om vad du hoppas få lära dig av utbildningen.';
		// övriga fält är valfria
		return errors;
	}
	doValidate = values => {
		let errors = this.validate(values || this.state);
		let hasErrors = Object.keys(errors).length > 0;
		this.setState({ error: errors, isValid: !hasErrors });
	}
	update = (event, prop) => {
		let obj = {};
		obj[prop] = event.target.value;
		this.setState(obj, () => {
			this.props.dispatch(ACTIONS.saveForm1(this.state));
		});
		const values = { ...this.state };
		values[prop] = event.target.value;
		this.doValidate(values);
	}
	submit = event => {
		console.log('Form1.submit: ', this.state, this.props.userId);
		this.props.dispatch(ACTIONS.submitForm1(this.state));

		let { isSubmitted, reflection, firstName, lastName, email, workedYears, workedSchools, classes, subjects, availableResources, previousExperience, positiveAboutProgramming, expectations, extraComment } = this.state;

		if( !subjects ) subjects = [];

		console.log('Submit form 1. ', this.state);
		FirebaseMethods.submitForm1(this.props.userId, { isSubmitted, reflection, firstName, lastName, email, workedYears, workedSchools, classes, subjects, availableResources, previousExperience, positiveAboutProgramming, expectations, extraComment }, () => console.log('Form1.submit SUcCESS'));
	}
	lift = (value, prop) => {
		let obj = {};
		obj[prop] = value;
		this.setState(obj, this.doValidate)
	}


	render() {
		// Visas om man redan har skickat in formuläret
		const whenSubmitted = this.props.isSubmitted ?
			(
				<div className="whensubmitted">
					<p>
						Dina svar är registrerade. Fortsätt med steg 2, programmering!
					</p>
					<p>
						<LinkChangePage cname="linkstep linknext3" text="Till nästa steg" next="2" />
					</p>
				</div>
			) : null;

		// Visa olika texter beroende på version (grundskola/gymnasium)
		let classLabel = 'Dina klasser';
		let sliderCaption = 'Instämmer du med följande påstående: Jag är positiv till att ta in ämnet programmering i grundskolan?';
		let classPlaceholder = 'F, 1, 2, 3 osv.'
		let mySubjects = null;
		if( this.props.version === VERSION.GYMNASIUM ) {
			classLabel = 'Dina kurser';
			sliderCaption = 'Instämmer du med följande påstående: Jag är positiv till att ta in programmering i ämnet matematik på gymnasiet?';
			classPlaceholder = '1a, 2a, 3b osv.';
		} else {
			mySubjects = (
				<div>
					<MySubjects subjects={this.state.subjects} liftSubjects={subjects => this.lift(subjects, 'subjects')} />
				</div>
			);
		}

		// Validering - visa felmeddelanden
		const error = this.state.error;
		const hasErrors = Object.keys(error).length > 0;

		return (
			<Fragment>
				{whenSubmitted}
				<p>
					Svara på frågorna nedan så syns vi till första kurstillfället ute i Connect.
				</p>
				<div className="form">
					<div>
						<h3>Din reflektion</h3>
						<textarea rows="4" placeholder="Kort reflektion kring det material du tittade på i föregående flik"
							onChange={e => this.update(e, 'reflection')}
							value={this.state.reflection}></textarea>
						<div className="errors">{error.reflection}</div>
					</div>
					<div>
						<h3>Kontaktuppgifter</h3>
						<input type="text" placeholder="Förnamn" value={this.state.firstName} onChange={e => this.update(e, 'firstName')} />
						<input type="text" placeholder="Efternamn" value={this.state.lastName} onChange={e => this.update(e, 'lastName')} />
						<input type="text" placeholder="E-postadress" value={this.state.email} onChange={e => this.update(e, 'email')} />
						<div className="errors">{error.nameEmail}</div>
					</div>
					<div>
						<h3>Hur många år har du arbetat i grundskolan eller gymnasiet?</h3>
						<input type="text" placeholder="Antal år" value={this.state.workedYears} onChange={e => this.update(e, 'workedYears')} />
						<div className="errors">{error.workedYears}</div>
					</div>
					<div>
						<MySchools schools={this.state.workedSchools} liftSchools={schools => this.lift(schools, 'workedSchools')} />
						<div className="errors">{error.workedSchools}</div>
					</div>
					<div>
						<h3>{classLabel}</h3>
						<input type="text"
							value={this.state.classes}
							placeholder={classPlaceholder}
							onChange={e => this.update(e, 'classes')} />
						<div className="errors">{error.classes}</div>
					</div>
					{mySubjects}
					<div>
						<h3>Vilka resurser har du tillgång till i din undervisning?</h3>
						<textarea rows="4"
							placeholder="Har skolan till exempel tillgång till surfplattor för varje elev, datorer, robotar eller något annat som är relevant för undervisning i programmering."
							value={this.state.availableResources}
							onChange={e => this.update(e, 'availableResources')}></textarea>
						<div className="errors">{error.resources}</div>
					</div>
					<div>
						<h3>Vad har du för tidigare erfarenhet av programmering?</h3>
						<textarea rows="4"
							placeholder="Berätta om dina eventuella erfarenheter av programmering"
							value={this.state.previousExperience}
							onChange={e => this.update(e, 'previousExperience')}></textarea><br />
						<div className="errors" style={{position: 'static'}}>{error.previousExperience} &nbsp;</div>

						<h3>{sliderCaption}</h3>
						<div className="range">
							<input id="f1positive" type="range" min="1" max="5"
							 	step="0.25" list="tickmarks"
								value={this.state.positiveAboutProgramming}
								onChange={e => this.update(e, 'positiveAboutProgramming')}/>
							<span>Instämmer inte alls</span>
							<span>Instämmer helt</span>
						</div>

						<datalist id="tickmarks"> {/* Labels currently (2018-06) not supported in browsers */}
							<option value="1"/>
							<option value="2"/>
							<option value="3"/>
							<option value="4"/>
							<option value="5"/>
						</datalist>
					</div>
					<div>
						<h3>Vad skulle du vilja få ut av utbildningen? Vad är du intresserad av? </h3>
						<textarea rows="4"
							placeholder="Jag vill lära mig..."
							value={this.state.expectations}
							onChange={e => this.update(e, 'expectations')}></textarea>
						<div className="errors" style={{position: 'static'}}>{error.expectations} &nbsp;</div>

						<h3>Har du någon övrig kommentar?</h3>
						<textarea rows="4"
							placeholder=""
							value={this.state.extraComment}
							onChange={e => this.update(e, 'extraComment')}></textarea>

						<button onClick={this.submit}
							disabled={!this.state.isValid}>Skicka informationen</button>
						<div className="errors">{hasErrors ? 'Vi behöver mer information om dig för att genomföra kursen.' : ''}</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ formData, user, theme }) => ({
	userId: user.userId,
	version: theme.version,
	isSubmitted: formData.form1.isSubmitted,
	isValid: formData.form1.isValid,
	error: formData.form1.error,
	reflektion: formData.form1.reflektion,
	firstName: formData.form1.firstName,
	lastName: formData.form1.lastName,
	email: formData.form1.email,
	workedYears: formData.form1.workedYears,
	workedSchools: formData.form1.workedSchools,
	classes: formData.form1.classes,
	subjects: formData.form1.subjects,
	availableResources: formData.form1.availableResources,
	previousExperience: formData.form1.previousExperience,
	positiveAboutProgramming: formData.form1.positiveAboutProgramming,
	expectations: formData.form1.expectations,
	extraComment: formData.form1.extraComment
})

export default connect(mapStateToProps)(Form1);
