const saveState = 'xavier_auth';

export function assignKey(snapshot) {
	return Object.assign({}, snapshot.val(), { key: snapshot.key });
}

export const loadLocalState = () => {
	try {
		const serializedState = localStorage.getItem(saveState);
		if(serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		console.log(err);
		return undefined;
	}
}

export const saveLocalState = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem(saveState, serializedState);
	} catch (err) {
		console.log(err);
	}
}

export const clearLocalState = () => {
	localStorage.removeItem(saveState);
}
