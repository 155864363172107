import React from 'react';
import { LinkNewWindow } from '../components/Helpers.js';
import ActivityMap from '../components/map/ActivityMap.js';
import { VideoImage } from '../components/Helpers.js';

const page1grund = imageFolder => (
	<section className="left page1">
	<h1>Välkommen till kursen</h1>
	<p>
		<button type="button" id="togglemobile">Toggla mobil (dev)</button>
	</p>
	<p>
	Samhällets alla delar och strukturer digitaliseras och skolan måste givetvis följa denna utveckling. Regeringens beslut om att förändra i styrdokumenten för grundskolan handlade dels om att förstärka och tydliggöra digital kompetens men också om att väva in programmering i de nya kursplanerna för teknik och matematik.
	</p>
	<p>
	Effekterna av detta ska bidra till att barn och elever utvecklar förståelse för hur digitaliseringen påverkar individen och samhället. De ska stärka elevernas förmåga att använda och förstå digitala system och tjänster, att förhålla sig till medier och information på ett kritiskt och ansvarsfullt sätt. Det handlar också om stärka förmågan att lösa problem och omsätta idéer i handling på ett kreativt sätt med hjälp av digitala verktyg. Förändringarna träder i kraft 1 juli 2018 men det är möjligt för skolorna att börja arbeta utifrån de reviderade styrdokumenten redan från 1 juli 2017.
	<q>Text hämtad från Skolverket</q>
	</p>
	<p>
	I denna kurs kommer vi sätta fokus på er som är matematik- och tekniklärare och hur ni tillsammans kan hitta fram till metoder och idéer för att kunna sjösätta programmering i er pedagogiska verksamhet. Vi kommer också utmana er i att förstå och utveckla egna kunskaper och färdigheter i att programmera. Det spelar ingen roll om ni har tidigare kunskaper eller om området är helt nytt.
	</p>
	<p>
	Se introduktionsfilmen nedan med upplägg och struktur. Varmt välkomna!
	</p>
	<VideoImage src={'img/' + imageFolder + 'struktur_kursupplagg.png'} alt="Struktur och kursupplägg" vid="biax3EeOrFQ" />
	<VideoImage src={'img/' + imageFolder + 'kurssidan_1.png'} alt="Struktur och kursupplägg" vid="e_iImAsOkB0" />
	</section>
);
const page1gym = imageFolder => (
	<section className="left page1">
	<h1>Välkommen till kursen</h1>
	<p>
		<button type="button" id="togglemobile">Toggla mobil (dev)</button>
	</p>
	<p>
	Samhällets alla delar och strukturer digitaliseras och skolan följer även den utvecklingen. Regeringens beslut om att förändra styrdokumenten för gymnasieskolan handlar dels om att förstärka och tydliggöra digital kompetens men också om att väva in programmering i några av de nya kursplanerna för matematik.
	</p>
	<p>
	Effekterna av detta ska bidra till att barn och elever utvecklar förståelse för hur digitaliseringen påverkar individen och samhället. De ska stärka elevernas förmåga att använda och förstå digitala system och tjänster, att förhålla sig till medier och information på ett kritiskt och ansvarsfullt sätt. Det handlar också om stärka förmågan att lösa problem och omsätta idéer i handling på ett kreativt sätt med hjälp av digitala verktyg.
	</p>
	<p>
	Denna kurs kommer fokusera på hur programmeringen kan användas för att skapa matematiska algoritmer som är svåra att utföra annars. Vi kommer skaffa oss en gemensam bas för olika programmeringstekniker i programmeringsspråket Python 3. Allt eftersom kursen fortlöper kommer du som är deltagare att konkretisera teknikerna till något du kan använda i klassrummet. Under vägen kommer ni som deltar att arbeta tillsammans i ett distansarbetslag för att ge varandra feedback och stöd under processen.
	</p>
	<p>
	Se introduktionsfilmen nedan med upplägg och struktur. Varmt välkomna!
	</p>
	<VideoImage src={'img/' + imageFolder + 'struktur_kursupplagg.png'} alt="Struktur och kursupplägg" vid="R4l5l716pHU" />
	<VideoImage src={'img/' + imageFolder + 'kurssidan_1.png'} alt="Kurssidans struktur" vid="UFnYHsSxmjA" />
	</section>
);
const page2grund = imageFolder => (
	<section className="left page2">
	<h1>Programmering och fältet</h1>

	<p>Utbudet av läromedel, program och appar som har till uppgift att förbättra förståelsen för programmering i skolan har ökat lavinartat de senaste åren. Hur ska man veta vad som är bra? När ska man ha tiden att själv kvalitetsgranska alla läromedel och tjänster som finns? Varför ska jag exempelvis använda Scratch med mina elever? Vilka kompetenser och färdigheter kommer vi åt? hur ska man bedöma eleverna?</p>

	<p>Det är länge sedan skolmyndigheterna hade som en av sina uppgifter att kvalitetsgranska läromedel. Idag ligger ansvaret helt på den enskilde läraren att orientera sig i tekniklandskapet. Det finns idag många bra krafter som driver och utvecklar tekniker och utbildningar som är gratis för oss lärare. Sedan är det som alltid marknadskrafternas som till stora delar styr vad som tillsist kommuner och skolorna köper in. I en undersökning som gjordes på uppdrag av Skolvärlden uppgav var fjärde lärare inte alls hade tid för att kunna kvalitetssäkra läromedel och 54 procent hinner inte med i tillräcklig utsträckning. Allra värst är det för lärare i grundskolans tidiga år.</p>

	<p>Nu är det er tur att fördjupa er i fältet programmering!</p>
	<img src={'img/' + imageFolder + 'alla_program.png'} alt="Alla programmeringsresurser" />
	</section>
);
const page2gym = imageFolder => (
	<section className="left page2">
	<h1>Programmering i matematik</h1>

	<p>Programmering kan betyda mycket. Programmering i matematik handlar mycket om att konstruera algoritmer så att de löser ett problem. Enligt det centrala innehållet i kurserna Ma1c-Ma5 samt Ma3b står det under punkten problemlösning:
	   “Strategier för matematisk problemlösning inklusive modellering av olika situationer, såväl med som utan digitala verktyg och programmering.”
	   Detta är enda gången som programmering nämns specifikt. Huvudsyftet med programmeringen i matematiken är alltså att använda den som problemlösningsverktyg. Detta skulle kunna innebära att man skulle kunna lösa samma problem som tidigare fast med programmering.
	</p>

	<p>En annan tolkning är att eleverna skulle kunna lösa närliggande problem där en analytisk eller algebraisk lösning är för svår eller tidskrävande. Vi tittar på följande två exempel: </p>
	<ul>
	   <li>Hur stor är sannolikheten att du inte får något ess om du drar 2 kort ur en vanlig kortlek?</li>
	   <li>Hur stor är sannolikheten att du inte får något ess om du drar hälften av korten i en vanlig kortlek?</li>
	</ul>
    <p>
	   Den första frågan känner många igen som ett problem av standardkaraktär från t ex Ma1c. Den andra frågan baseras på precis samma teori och tankesätt fast lösningen underlättas mycket av att kunna upprepa beräkningar många gånger om.
	</p>

	<h2>Avgränsning för kursen</h2>

	<p>I den här kursen kommer vi jobba vidare med den här typen av problem som kan lösas genom att upprepa eller repetera något många gånger men inte baseras på något annat än det centrala innehållet. Till skillnad från kalkylarken där vi har samma möjlighet till upprepning ett givet antal gånger kan vi i programmeringen även ha ett villkor som säger när vi ska sluta repetera. Ett problem där vi kan använda detta är:</p>
	<ul>
		<li>Hur många kort ska du dra från en vanlig kortlek för att sannolikheten för att få minst ett ess ska vara större än att inte få något ess alls?</li>
	</ul>

	<p>Möjligheten att repetera en enklare beräkning många gånger är något som hjälper oss lösa problem i flera olika matematikkurser. Några exempel är räntor och lån, numerisk gränsvärdesberäkning, numerisk integrering, summor av talföljder eller numerisk beräkning av lösningar till differentialekvationer.</p>

	<h2>Andra syften</h2>

	<p>Det finns också andra syften med att undervisa matematik med programmering, t ex att man skulle kunna åskådliggöra en redan existerande algoritm. Ett exempel från Ma2c är att skriva ett program som tar fram extrempunktens koordinater för ett andragradspolynom. Algoritmen för att beräkna detta lär sig eleverna ofta “för hand” men att implementera den i ett program skulle kunna göra det extra tydligt hur den fungerar. I den här kursen kommer vi inte primärt fokusera på den sortens syften även om detta är intressant i förlängningen också.</p>

	<p>Nu är det er tur att fördjupa er i fältet programmering!</p>
	<img src={'img/' + imageFolder + 'python_image.png'} alt="Alla programmeringsresurser" />
	</section>
);
const page3grund = imageFolder => (
	<section className="left page3">
	<h1>Uppdraget</h1>
	<h2>Att designa, leda och utvärdera undervisning</h2>
	<p>Att designa och planera undervisning utgår ifrån läroplanens uppdragsbeskrivning och formas av hur vi översätter den till det aktuella området vi vill belysa och träna på. Nu ska du titta på läroplanen utifrån vår diskussion om vad programmering är. </p>
	<img className="introimg" alt="Illustration designa, leda, utvärdera" src={'img/' + imageFolder + 'illustration_uppdraget.png'} />
	<p>Att leda undervisning i programmering handlar om att stötta elevernas problemlösningsprocess, precis som i matematik- och teknikundervisningen. Eleverna behöver uppmuntran och vägledning för att gå från en grundläggande förståelse av hur problem kan delas upp i mindre delar, översättas till instruktioner, samt algoritmer och vidare användas i programmering.</p>
	<p>Att utvärdera undervisning i programmering liknar det som görs i matematik och teknik. Ett angreppssätt är att ta förmågorna i dessa ämnen och byta ut ordet matematik/teknik mot programmering.  Detta förtydligar hur ämnet går att angripa ur ett planerings-, bedömnings- och utvärderingsperspektiv.</p>
	<p>Det kollegiala lärandet är ett annat perspektiv på utvärdering. Ni ska tillsammans i gruppen diskutera och utvärderar varandras lektionsplaneringar. I framtiden kan ni fortsätta använda denna sida för att dela lektionsplaneringar och slacken för att diskutera dem vidare. Ta också gärna hem diskussionen till din skola så att du och dina lokala kollegor kan vidareutveckla er undervisning och öka elevernas lärande.</p>
	</section>
);
const page3gym = imageFolder => (
	<section className="left page3">
	<h1>Uppdraget</h1>
	<p>Att undervisa om och med programmering i matematik innebär många nya utmaningar. Den första utmaningen är att över huvud taget implementera någon problemlösningssituation där programmering kan användas med fördel. I det här momentet kommer vi jobba vidare med detta samt hur elevernas lösningar bedöms och till slut hur läraren själv kan utvärdera undervisningssituationen.</p>

	<p>En annan utmaning är att bedöma elevens förmåga att använda programmeringen i problemlösning. Programmering är endast explicit nämnt i det centrala innehållet och inte i kunskapskraven. Däremot nämns digitala verktyg i flera gånger i det centrala innehållet och även i kunskapskraven. Ett av kunskapskraven för betyget C lyder:</p>
	<p>“Eleven kan <b>utförligt</b> beskriva innebörden av centrala begrepp med hjälp av <b>några</b> representationer samt <b>utförligt</b> beskriva sambanden mellan begreppen. Dessutom växlar eleven <b>med viss säkerhet</b> mellan olika representationer. Eleven kan <b>med viss säkerhet använda</b> begrepp och samband mellan begrepp för att lösa matematiska problem och problemsituationer i karaktärsämnena. I arbetet hanterar eleven <b>flera</b> procedurer och löser uppgifter av standardkaraktär <b>med säkerhet</b>, både utan och med digitala verktyg.”
		<br/>-ur ämnesplan matematik, Skolverket
	</p>

	<p>Hur ska lärare konkretisera detta för eleverna när det gäller programmering? Finns det skillnader i kvaliteten på elevers programmeringslösningar? Avgör själva problemformuleringen på vilken nivå en elev kan visa t ex problemlösningsförmåga som innefattar programmering? Frågorna är många och i det här momentet ska vi försöka komma närmre en lösning med ämnesplanen i matematik som underlag.</p>
	</section>
);
const page4grund = imageFolder => (
	<section className="left page4">
	<h1>Dela, diskutera och planera</h1>
	<p>
	Du har nu skapat och genomfört din utbildningsaktivitet. Fokus fram till examination kommer nu ligga på att för varandra beskriva och diskutera era erfarenheter tillsammans. Hur kan kollegialt lärande bidra till elevers kunskapsutveckling? Styrdokument såsom skollag, läroplaner, kursplaner, kunskapskrav kopplade till betyg liksom stödjande kommentarmaterial är till för att styra skolan mot demokratiskt bestämda mål. Alla dessa har dock det gemensamt att de ska tolkas av lärare och skolledare. Tolkningar som lärare gör är mer eller mindre personliga och grundade på individuella erfarenheter, kunskaper och värderingar.
	</p>
	<img className="introimg" alt="Illustration dela, diskutera" src={'img/' + imageFolder + 'illustrationer_aktivitet.png'} />
	<p>
	Vi avslutar kursen med att du redogör/redovisar (kort) vad du kommit fram till. Vi vill att examinationen blir till ett dynamiskt lärtillfälle där vi får lära oss nya saker, inspireras samt delta i diskussioner och ger feedback kring era projekt.
	</p>
	<p>
	Om ni vill läsa mer kring kollegialt lärande finns följande länkar. OBS! inte obligatoriskt.
	</p>
	<p>
	Läs <em>Skolverkets:</em> <br />
	<LinkNewWindow href="http://www.skolverket.se/skolutveckling/forskning/amnen-omraden/no-amnen/tema-naturvetenskap/kollegialt-larande-1.168797" title="Artikel" >Hur kan kollegialt lärande bidra till elevers kunskapsutveckling?</LinkNewWindow>
	</p>
	<p>
	Läs <em>Kvutis:</em> <br />
	<LinkNewWindow href="http://kvutis.se/fran-larare-till-skolor-om-att-infora-formativ-bedomning-storre-skala/" title="Artikel med PDF">Från lärare till skolor – om att införa formativ bedömning i större skala</LinkNewWindow> av Dylan Wiliam, Siobhan Leahy
	</p>

	Läs <em>Pedagog Malmös</em> Helen Timperley: lärares professionella utveckling eller
	<LinkNewWindow href="http://kvutis.se/wp-content/uploads/2014/07/Fr%C3%A5n-l%C3%A4rare-till-skolor-Om-att-inf%C3%B6ra-formativ-bed%C3%B6mning-i-st%C3%B6rre-skala-G%C3%B6teborgs-Stad-2014.pdf">Från lärare till skolor - om att införa formativ bedömning i större skala</LinkNewWindow> (PDF)
	</section>
);
const page4gym = imageFolder => (
	<section className="left page4">
	<h1>Dela, diskutera och planera</h1>
	<p>
	Du har nu skapat och genomfört din utbildningsaktivitet. Fokus fram till examination kommer nu ligga på att för varandra beskriva och diskutera era erfarenheter tillsammans. Hur kan kollegialt lärande bidra till elevers kunskapsutveckling? Styrdokument såsom skollag, läroplaner, kursplaner, kunskapskrav kopplade till betyg liksom stödjande kommentarmaterial är till för att styra skolan mot demokratiskt bestämda mål. Alla dessa har dock det gemensamt att de ska tolkas av lärare och skolledare. Tolkningar som lärare gör är mer eller mindre personliga och grundade på individuella erfarenheter, kunskaper och värderingar. Här vill vi att ni i gruppen hjälper, utmanar och förbättrar varandras lektionsplaneringar så vi skapar så bra förutsättningarna för implementera programmering i praktiken.
	</p>
	<img className="introimg" alt="Illustration dela, diskutera" src={'img/' + imageFolder + 'illustrationer_aktivitet.png'} />
	<p>
	Vi avslutar kursen med att varje grupp redogör/redovisar vad ni kommit fram till. Vi är nu alla intresserade av att ta del av de resultat och de erfarenheter som ni fått fram i er grupp. Vi vill att examinationen blir till ett dynamiskt lärtillfälle där vi får lära oss nya saker, inspireras samt delta i diskussioner kring era projekt.
	</p>
	</section>
);
const page5 = imageFolder => (
	<section className="left page5">
	<ActivityMap />
	</section>
);
const page6 = imageFolder => (
	<section className="left page6">
	<h1>Fördjupning i programmering</h1>
	<p>
	Du vill lära dig mer programmering. För din egna skull. Här samlar vi lite resurser till dig för ditt vidare lärande och personliga utveckling. För tänk vad som händer när vi inte bara har ett Sverige där alla barn lär sig programmera i grundskolan utan där alla lärare kan koda. Spännande tanke tycker vi.
	</p>
	<p>
	Prata gärna i våra språkspecifika kanaler i Slack!
	</p>
	<p>
	<LinkNewWindow href="http://advaniaskolpartner.slack.com/"> <img className="slackinline" alt="Slack" src={'img/slack_icon.png'} /> Öppna Slack</LinkNewWindow>
	</p>
	<img className="vid" src="img/fordjupnin_prog.png" alt="Fördjupning programmering" />
	<p>
	Din utmaning är nu att lära dig något område inom programmering så som det ser ut i branschen. Vi har valt ut några intressanta områden och resurser.
	</p>
	</section>
);

const pages = {
	page1grund,
    page1gym,
    page2grund,
    page2gym,
    page3grund,
    page3gym,
    page4grund,
    page4gym,
    page5,
    page6
}

export default pages;
