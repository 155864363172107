
const ACTIONS = {
	CHANGEVERSION: 'change Xavier version',

	CHANGEPAGE: 'change main page',
	CHANGETAB: 'change tab within page',
	ADMIN_SHOW: 'show admin interface',
	ADMIN_HIDE: 'leave admin interface',

	SUBMITFORM1: 'submit form 1, background',
	SUBMITFORM2: 'submit form 2, evaluate resources',
	SUBMITFORM3: 'submit form 3, lesson plan',
	SUBMITFORM4: 'submit form 4, evaluate LP',

	SAVEFORM1: 'save the contents of form1',
	SAVEFORM2: 'save the contents of form2',
	SAVEFORM3: 'save changes to form3',
	SAVEFORM4: 'save changes to form4',
	/*SETSCHOOLS: 'set schools',
	SETCLASSES: 'set classes',
	SETSUBJECTS: 'set subjects',
	SETTAGS: 'set tags',*/

	ADD_LP: 'add new lesson plan',
	UPDATE_LP: 'save changes to lesson plan',
	VIEW_LP: 'view lesson plan read only',
	CLOSE_LP: 'close lesson plan return to cards',
	NEW_LP: 'create new lesson plan',

	GOTFORM1: 'received form1 from db (background)',
	GOTFORM2: 'received form2 from db (evalutate resources)',
	GOTFORM4: 'received form4 from db (activities)',
	ALLFORM4: 'Receiced all form4 from db',
	STARTSFETCHINGLESSONPLANS: 'starts fetching lesson plans',
	GOTLESSONPLANS: 'received lesson plans from db',
	STARTSFETCHINGGROUPS: 'starts fetching groups',
	GOTGROUPS: 'received groups from db',
	STARTSFETCHINGACTIVITIES: 'starts fetching activities',
	GOTACTIVITIES: 'received activities from db',
	STARTSFETCHINGUSERS: 'starts fetching users',
	GOTUSERS: 'received users from db',

	MAP_FAILED: 'map failed to load',
	MAP_LOADING: 'map is loading',
	MAP_INIT: 'map should initialize',
	MAP_LOADED: 'map finished loading',
	MAP_SHOW_INFOBOX: 'show map info box for place',
	MAP_HIDE_INFOBOX: 'hide map info box',
	MAP_GO_TO_LESSON_PLAN: 'navigate to and show lesson plan',

	SHOW_VIDEO: 'show video overlay (lightbox)',
	HIDE_VIDEO: 'hide video overlay',
	PLAY_VIDEO: 'start playing video',
	PAUSE_VIDEO: 'stop playing video',

	USER_SIGNIN: 'user log in',
	USER_SIGNOUT: 'user log out',
	USER_SETADMIN: 'user set admin state',
	USER_ATTEMPTSIGNIN: 'user wants to attempt sign in / register',

	//USER_ATTEMPTREGISTER: 'user tries to sign in or register'

	//UI_LOGINBUTTONSTATE: 'enable/disable login button',
	UI_LOGINDISABLED: 'login button disabled',
	UI_LOGINENABLED: 'login button enabled',
	UI_LOGGINGIN: 'login button temporarily disabled',

	USER_LOCALSTORAGE: 'Localstorage for user login'
}

export default ACTIONS;
