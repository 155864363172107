import React, { Component } from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../actions/formActions.js';

import MySubjects from './MySubjects.js';
import MyTags from './MyTags.js';
import { VERSION } from '../../constants.js'

import Firebase from '../../utils/firebase.js';
import { getTodayDateAsString, validateErrors }  from '../../utils/functions.js';


class Form3 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSubmitted: props.isSubmitted,
			editMode: props.editMode,
			error: props.error,

			title: props.title,
			subjects: props.subjects,
			classes: typeof props.classes === 'object' ? props.classes.join(',') : props.classes,
			goalAndPurpose: props.goalAndPurpose,
			estimatedTime: props.estimatedTime,
			equipment: props.equipment,
			content: props.content,
			grading: props.grading,
			tags: props.tags,
			firstName: '',
			lastName: ''
		}
	}

	componentDidMount = () => this.validate()
	componentDidUpdate = () => this.validate()

	validate() {
		/*let titleValidationFunction = (errors) => {
			if( this.state.title === '' )
				errors.push('Skriv en rubrik för din planering.');
		}*/
		//console.log('Form3 validate', this.state);
		// Check if all user input is valid; if so, set isValid=true in preparation for submitting
		let validTitle = this.state.title !== '';
		let validSubject = this.state.subjects.length > 0 || this.props.version === VERSION.GYMNASIUM;
		let validClasses = this.state.classes.length > 0;
		let validGoalAndPurpose = this.state.goalAndPurpose !== '';
		let validEstimatedTime = this.state.estimatedTime.length > 0;
		let validEquipment = this.state.equipment.length > 0;
		let validContent = this.state.content.length > 10;
		let validGrading = this.state.grading.length > 0;
		let validTags = this.state.tags.length > 0;
		let validateFirstName = this.state.firstName.length > 0;
		let validateLastName = this.state.lastName.length > 0;

		let errors = [];
		if( !validTitle ) errors.push('Skriv en rubrik för din planering.');
		if( !validContent ) errors.push('Du måste skriva själva innehållet i planeringen.');
		if( !validTags ) errors.push('Ange minst en tag, för att man lättare ska kunna söka och hitta din planering.');
		if( !validSubject ) errors.push('Ange minst ett ämne som planeringen är tänkt att användas i.');
		if( !validClasses ) errors.push('Ange minst en klass/kurs som planeringen är gjord för.');
		if( !validGoalAndPurpose ) errors.push('Skriv något om vad syftet och målet är med planeringen.');
		if( !validEstimatedTime ) errors.push('Skriv hur lång tid du uppskattar att planeringen bör ta.');
		if( !validGrading ) errors.push('Skriv något om hur resultatet ska bedömas eller betygsättas.');
		if( !validEquipment ) errors.push('Skriv om man behöver några särskilda resurser för att genomföra planeringen.');
		if( !validateFirstName ) errors.push('Du måste fylla i ditt förnamn.');
		if( !validateLastName ) errors.push('Du måste fylla i ditt efternamn.');
		validateErrors(errors, this.state.error, this);
	}

	update = (event, prop) => this.update2(event.target.value, prop)
	update2 = (value, prop) => {
		let obj = {};
		obj[prop] = value;
		this.setState(obj, () => {
			//this.validate(); componentDidUpdate
			//console.log('Form3 update2 SAVEFORM classes=', this.state.classes)
			this.props.dispatch(ACTIONS.saveForm3(this.state));
		});
	}

	submit = event => {
		console.log('Form3.submit: ', this.state);
		this.props.dispatch(ACTIONS.submitForm3(this.state));

		const fb = Firebase;
		const plan = {
			title: this.props.title,
			subjects: this.props.subjects.join(','),
			classes: this.props.classes,//.join(','),
			goalAndPurpose: this.props.goalAndPurpose,
			estimatedTime: this.props.estimatedTime,
			equipment: this.props.equipment,
			content: this.props.content,
			grading: this.props.grading,
			tags: this.props.tags.join(','),
			dateSaved: getTodayDateAsString(),
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			userName: this.props.userName,
			userId: this.props.userId
		}
		fb.submitLessonPlan(plan, e => {
			//console.log('FIREBASE LP saved', e);
			this.props.dispatch(ACTIONS.addLessonPlan(plan));
		});

		fb.submitForm3(this.props.userId, plan, () => console.log('Form3.submit SUCCESS'))
	}


	render() {

		let mySubjects = null;
		// let subjectsAndYear = <h3>Ämne och årskurs</h3>;
		let classTitle = <h3>Klasser som planeringen är riktad till</h3>;
		let classPlaceholder = 'F, 1, 2 osv.';
		if( this.props.version === VERSION.GYMNASIUM ) {
			// subjectsAndYear = null;
			classTitle = <h3>Kurser som planeringen är gjord för</h3>;
			classPlaceholder = '1a, 2a, 2b osv.';
		} else {
			mySubjects = (
				<div>
					<MySubjects subjects={this.state.subjects} liftSubjects={subjects => this.lift(subjects, 'subjects')} />
				</div>
			);
		}
		let myClasses = (
			<input type="text" placeholder={classPlaceholder}
			 	value={this.state.classes} onChange={e => this.update(e, 'classes')} />
		);

		if( this.props.isSubmitted ) {
			return (
				<div className="whensubmitted">
					<p>
						Din lektionsplanering är sparad. Nu kan du titta på de andra kursdeltagarnas lektionsplaneringar.
					</p>
				</div>
			);
		}
		let errors = this.state.error.map( e => (
			<li key={e}> {e} </li>
		));
		if( errors.length > 0 )
			errors = (<ul className="errors"> {errors} </ul>);
		return (
			<div className="form">
				<div>
					<h3>Rubrik</h3>
					<input type="text"
						className="large"
						placeholder="Rubriken på din planering"
						value={this.state.title}
						onChange={e => this.update(e, 'title')}/>

					{classTitle}
					{mySubjects}
					{myClasses}

					<h3>Mål och syfte med lektionsplanen</h3>
					<textarea rows="3"
						placeholder="Mål och syfte"
						value={this.state.goalAndPurpose}
						onChange={e => this.update(e, 'goalAndPurpose')}></textarea>

					<h3>Planerad tidsåtgång</h3>
					<input type="text"
						placeholder="Uppskatta tiden i minuter"
						value={this.state.estimatedTime}
						onChange={e => this.update(e, 'estimatedTime')} />
					<h3> Tekniska hjälpmedel </h3>
					<input type="text" className="large"
					 	placeholder="Till exempel surfplatta, smartphone osv."
						value={this.state.equipment}
						onChange={e => this.update(e, 'equipment')} />

					<h3>Innehåll</h3>
					Här skriver du vad lektionsplaneringen ska innehålla. Tänk på att andra lärare ska kunna läsa och följa planen. <br />
					<textarea rows="12"
						value={this.state.content}
						onChange={e => this.update(e, 'content')}></textarea>

					<h3>Bedömningskriterier</h3>
					Skriv något om hur resultatet ska bedömas eller betygsättas.
					<textarea rows="4"
						value={this.state.grading}
						onChange={e => this.update(e, 'grading')}></textarea>

					<MyTags tags={this.state.tags}
					 	liftTags={tags => this.update2(tags, 'tags')}/>

					<h3>Namn</h3>
					Fyll i ditt namn så att det blir lättare att hitta varandras lektionsplaneringar
					<input type="text" className="large"
					 	placeholder="Förnamn"
						value={this.state.firstName}
						onChange={e => this.update(e, 'firstName')} />
					<input type="text" className="large"
					 	placeholder="Efternamn"
						value={this.state.lastNameName}
						onChange={e => this.update(e, 'lastName')} />
					{errors}
					<button
						disabled={this.state.error.length > 0}
						onClick={this.submit}>Lämna in lektionsplanering</button>
				</div>
			</div>)
	}
}
const mapStateToProps = ({ formData, user, theme }) => ({
	userName: user.displayName,
	version: theme.version,
	userId: user.userId,
	isSubmitted: formData.form3.isSubmitted,
	editMode: formData.form3.editMode,
	error: formData.form3.error,
	title: formData.form3.title,
	subjects: formData.form3.subjects,
	classes: formData.form3.classes,
	goalAndPurpose: formData.form3.goalAndPurpose,
	estimatedTime: formData.form3.estimatedTime,
	equipment: formData.form3.equipment,
	content: formData.form3.content,
	grading: formData.form3.grading,
	tags: formData.form3.tags,
	firstName: formData.form1.firstName,
	lastName: formData.form1.lastName
})

export default connect(mapStateToProps)(Form3);
