import ACTIONS from '../actions/constants.js';

const initialState = {
	// no LPs initially except development data
	hasFetched: false,
	isFetching: false,
	plans: []
}

const lessonPlanReducer = (state = initialState, action) => {
	switch( action.type ) {
		case ACTIONS.STARTSFETCHINGLESSONPLANS:
			return {
				...state, isFetching: true, plans: []
			}
		case ACTIONS.GOTLESSONPLANS:
			return {
				hasFetched: true,
				isFetching: false,
				plans: action.plans
			}
		case ACTIONS.ADD_LP:
			return {
				...state,
				plans: [...state.plans, action.plan]
			}

		default:
			return state;
	}
}


export { lessonPlanReducer };
