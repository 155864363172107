import React, { Component } from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../actions/themeActions.js';
import App from './App';


class AppRouter extends Component {
	constructor(props) {
		super(props);
		const SKOLA = '/grundskola', GYMN = '/gymnasium';
		// ADMIN = '/admin'
		const pathName = props.location.pathname;
		const current = '/' + props.version;
		const redirectToLogin = () => props.history.push('/');

		if( !props.userId ) {
			// If userId is missing, force redirect to login (switch in index.js)
			redirectToLogin();
		}

		if( current === pathName ) {
			// We are where we should be, don't change anything unless not logged in
			// Except that we have to dispatch a change version action, because the tab headings varies between versions
			if( !this.props.isLoggedIn ) {
				redirectToLogin();
			} else if( current === SKOLA ) {
				props.dispatch(ACTIONS.changeThemeToSchool());
			} else if( current === GYMN ) {
				props.dispatch(ACTIONS.changeThemeToHighSchool());
			} else {
				console.error(`Vi ber om ursäkt! All data som ska användas för din kurs är inte på plats än. Du har inte gjort något fel. Tala om för din kursledare, så jobbar vi så fort vi kan med att rätta till det.`);
				redirectToLogin();
			}
		} else if( current === SKOLA && pathName === GYMN ) {
			props.dispatch(ACTIONS.changeThemeToHighSchool());
		} else if( current === GYMN && pathName === SKOLA ) {
			props.dispatch(ACTIONS.changeThemeToSchool());
		} else {
			console.log('Du har skrivit in en felaktig sökväg i adressfältet och skickas till startsidan.');
			redirectToLogin();
		}
	}
	render() {
		return ( <App /> )
	}
}


/*
const requireAuth = (nextState, replace) => {
    if (!auth.isAdmin()) {
        // Redirect to Home page if not an Admin
        replace({ pathname: '/' })
    }
}
export const AdminRoutes = () => {
  return (
     <Route path="/admin" component={Admin} onEnter={requireAuth} />
  )
}
*/
const mapStateToProps = ({ theme, user }) => ({
	version: theme.version,
	isLoggedIn: user.isLoggedIn,
	userId: user.userId
})
export default connect(mapStateToProps)(AppRouter);
