import React, { Component } from 'react';
import {connect } from 'react-redux';
import tabs from '../../versions/tabs.js';
import { VERSION } from '../../constants.js';

class TabContent extends Component {
	state = {}

	render() {

		let pages = {}

		if( this.props.version === VERSION.GRUNDSKOLA ) {
			pages = {
				'1': [tabs.page1tab1grund, tabs.page1tab2grund, tabs.page1tab3grund],
				'2': [tabs.page2tab1grund, tabs.page2tab2grund, tabs.page2tab3grund],
				'3': [tabs.page3tab1grund, tabs.page3tab2, tabs.page3tab3],
				'4': [tabs.page4tab1, tabs.page4tab2],//, tabs.page4tab3],
				'6': [tabs.page6tab1, tabs.page6tab2, tabs.page6tab3]
			}
		} else if ( this.props.version === VERSION.GYMNASIUM ) {
			pages = {
				'1': [tabs.page1tab1gym, tabs.page1tab2gym, tabs.page1tab3gym],
				'2': [tabs.page2tab1gym, tabs.page2tab2gym, tabs.page2tab3gym],
				'3': [tabs.page3tab1gym, tabs.page3tab2, tabs.page3tab3],
				'4': [tabs.page4tab1, tabs.page4tab2],//, tabs.page4tab3],
				'6': [tabs.page6tab1, tabs.page6tab2, tabs.page6tab3]
			}
		}

		let page = this.props.selectedPage + 1;
		let tabContent;
		if( page < 5 )
			tabContent = pages[page][this.props.selectedTab](this.props.imageFolder, this.props.version);
		else
			tabContent = null;

		let content = (
			<div className={'tabview scrolltabview page' + page}>
				{tabContent}
			</div>
		);
		return content;
	}
}

// const pages = {
// 	'1': [page1tab1, page1tab2, page1tab3],
// 	'2': [page2tab1, page2tab2, page2tab3],
// 	'3': [page3tab1, page3tab2, page3tab3],
// 	'4': [page4tab1, page4tab2],//, page4tab3],
// 	'6': [page6tab1, page6tab2, page6tab3]
// }

const mapStateToProps = ({ navigation, theme }) => ({
	selectedTab: navigation.selectedTab,
	selectedPage: navigation.selectedPage,
	imageFolder: theme.imageFolder,
	version: theme.version
});
export default connect(mapStateToProps)(TabContent);
