import ACTIONS from '../actions/constants.js';

const initialState = {
	hasFetched: false,
	isFetching: false,
	acts: []
}

const activityReducer = (state = initialState, action) => {
	switch( action.type ) {
		case ACTIONS.STARTSFETCHINGACTIVITIES:
			return {
				...state, isFetching: true, acts: []
			}
		case ACTIONS.GOTACTIVITIES:
			return {
				hasFetched: true,
				isFetching: false,
				acts: action.acts
			}

		default:
			return state;
	}
}


export { activityReducer };
