import ACTIONS from './constants.js';


const showVideoOverlay = vid => {
	return ({
		type: ACTIONS.SHOW_VIDEO,
		videoId: vid
	})
}
const hideVideoOverlay = () => ({
	type: ACTIONS.HIDE_VIDEO
})
const playVideo = () => ({
	type: ACTIONS.PLAY_VIDEO
})
// const pauseVideo = () => ({
// 	type: ACTIONS.PAUSE_VIDEO
// })

const actions = {
	showVideoOverlay,
	hideVideoOverlay,
	playVideo
	// pauseVideo
}

export default actions;
